define('ember-google-maps/components/-private-api/addon-factory', ['exports', 'ember-google-maps/templates/components/-private-api/addon-factory'], function (exports, _addonFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _addonFactory.default,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.gMap) {
        this.gMap = {};
      }
    }
  });
});