define('@busy-web/data/index', ['exports', '@busy-web/data/adapters/json', '@busy-web/data/serializers/json', '@busy-web/data/utils/error', '@busy-web/data/utils/query', '@busy-web/data/mixins/json-api-serializer', '@busy-web/data/mixins/batch', '@busy-web/data/mixins/image-adapter', '@busy-web/data/mixins/rpc-adapter', '@busy-web/data/mixins/simple-auth-data-adapter', '@busy-web/data/mixins/rpc-model', '@busy-web/data/mixins/store-finders'], function (exports, _json, _json2, _error, _query, _jsonApiSerializer, _batch, _imageAdapter, _rpcAdapter, _simpleAuthDataAdapter, _rpcModel, _storeFinders) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.QueryUtil = exports.ErrorUtil = exports.StoreMixin = exports.RPCModelMixin = exports.SimpleAuthDataAdapterMixin = exports.RPCAdapterMixin = exports.ImageAdapterMixin = exports.BatchAdapterMixin = exports.JsonApiSerializerMixin = exports.JsonApiSerializer = exports.JsonApiAdapter = undefined;
	exports.JsonApiAdapter = _json.default;
	exports.JsonApiSerializer = _json2.default;
	exports.JsonApiSerializerMixin = _jsonApiSerializer.default;
	exports.BatchAdapterMixin = _batch.default;
	exports.ImageAdapterMixin = _imageAdapter.default;
	exports.RPCAdapterMixin = _rpcAdapter.default;
	exports.SimpleAuthDataAdapterMixin = _simpleAuthDataAdapter.default;
	exports.RPCModelMixin = _rpcModel.default;
	exports.StoreMixin = _storeFinders.default;
	exports.ErrorUtil = _error.default;
	exports.QueryUtil = _query.default;
});