define('@busy-web/utils/assert', ['exports', '@busy-web/utils/uuid', 'moment'], function (exports, _uuid, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	function assertTest(test) {
		if (test) {
			return true;
		}
		return false;
	}

	function throwAssert(message) {
		throw new Error('Assertion failed: ' + message);
	}

	function assertFunc(message, test) {
		if (!assertTest(test)) {
			throwAssert(message);
		}
	}

	var Assert = Ember.Object.extend();

	/**
  * `Util/Assert`
  *
  * @class Assert
  * @namespace Utils
  */
	Assert.reopenClass({
		funcNumArgs: function funcNumArgs(args, argCount) {
			var equal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
			(false && !(Ember.isArray(args)) && Ember.assert('args must be an array in <utils::assert::funcNumArgs>', Ember.isArray(args)));
			(false && !(Ember.typeOf(argCount) === 'number') && Ember.assert('argCount must be a number in <utils::assert::funcNumArgs>', Ember.typeOf(argCount) === 'number'));
			(false && !(Ember.typeOf(equal) === 'boolean') && Ember.assert('equal must be a boolean in <utils::assert::funcNumArgs>', Ember.typeOf(equal) === 'boolean'));


			if (equal) {
				assertFunc('Function arguments must be equal to ' + argCount, args.length === argCount);
			} else {
				assertFunc('Function arguments must be less then or equal to ' + argCount, args.length <= argCount);
			}
			return this;
		},
		isString: function isString(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isString>', !Ember.isNone(value)));


			assertFunc('Type error [' + (typeof value === 'undefined' ? 'undefined' : _typeof(value)) + '] expected a string', typeof value === 'string');
			return this;
		},
		isNumber: function isNumber(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isNumber>', !Ember.isNone(value)));


			assertFunc('Type error [' + (typeof value === 'undefined' ? 'undefined' : _typeof(value)) + '] expected a number', typeof value === 'number');
			return this;
		},
		isInteger: function isInteger(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isInteger>', !Ember.isNone(value)));


			assertFunc('Type error [' + (typeof value === 'undefined' ? 'undefined' : _typeof(value)) + '] expected an integer', typeof value === 'number' && parseInt(value, 10) === value);
			return this;
		},
		isBoolean: function isBoolean(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isBoolean>', !Ember.isNone(value)));


			assertFunc('Type error [' + (typeof value === 'undefined' ? 'undefined' : _typeof(value)) + '] expected a boolean', typeof value === 'boolean');
			return this;
		},
		isArray: function isArray(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isArray>', !Ember.isNone(value)));


			assertFunc('Type error [' + Ember.typeOf(value) + '] expected an array', Ember.isArray(value));
			return this;
		},
		isObject: function isObject(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isObject>', !Ember.isNone(value)));


			assertFunc('Type error [' + Ember.typeOf(value) + '] expected an object', !Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !Ember.isArray(value));
			return this;
		},
		isMoment: function isMoment(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isMoment>', !Ember.isNone(value)));


			assertFunc('Type error [' + value.constructor + '] expected a Moment', !Ember.isNone(value) && _moment.default.isMoment(value));
			return this;
		},
		isUUID: function isUUID(value) {
			(false && !(!Ember.isNone(value)) && Ember.assert('parameter 1 cannot be null or undefined <utils::assert::isUUID>', !Ember.isNone(value)));


			assertFunc('Type error [' + value + '] expected a UUID', typeof value === 'string' && _uuid.default.isValid(value));
			return this;
		},
		isModel: function isModel(value) {
			Ember.deprecate("isModel is deprecated, please use isObject instead", false, { id: "assert-is-model", until: "3.0.0", url: "" });

			return this.isObject(value);
		},
		test: function test(message, _test) {
			(false && !(typeof message === 'string') && Ember.assert("parameter 1 must be a string in <utils::assert::test>", typeof message === 'string'));


			assertFunc(message, _test);
			return this;
		},
		throw: function _throw(message) {
			(false && !(typeof message === 'string') && Ember.assert("parameter 1 must be a string in <utils::assert::throw>", typeof message === 'string'));


			assertFunc(message, false);
		}
	});

	exports.default = Assert;
});