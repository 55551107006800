define('@busy-web/ember-date-time/--private/handlers/date', ['exports', '@busy-web/ember-date-time/--private/handlers/base', '@busy-web/ember-date-time/--private/utils/time', '@busy-web/ember-date-time/--private/utils/map'], function (exports, _base, _time, _map) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		__handler: 'date',

		timestamp: _base.default.property(),
		unix: _base.default.property(),
		minDate: _base.default.property(),
		maxDate: _base.default.property(),
		utc: _base.default.property({ defaultValue: false }),

		// unix, timestamp get set to time and
		// minDate, maxDate get set to min, max
		// after the dates have been normalized.
		time: _base.default.property(),
		min: _base.default.property(),
		max: _base.default.property(),

		isUnix: Ember.computed('unix', function () {
			return !Ember.isNone(Ember.get(this, 'unix'));
		}),

		setup: Ember.on('setupAttrs', function (attrs) {
			this.updateTimes(attrs);
		}),

		update: Ember.on('updateAttrs', function (attrs) {
			if ((0, _map.getMapAttr)(attrs, 'timestamp.value') || (0, _map.getMapAttr)(attrs, 'unix.value') || (0, _map.getMapAttr)(attrs, 'time.value')) {
				this.updateTimes(attrs);
			}
		}),

		updateTimes: function updateTimes(attrs) {
			var isUTC = (0, _map.getMapAttr)(attrs, 'utc.value') || false;
			var isUnix = (0, _map.getMapAttr)(attrs, 'unix.value') ? true : false;

			if ((0, _map.getMapAttr)(attrs, 'unix.value')) {
				var date = (0, _time.normalizeTime)((0, _map.getMapAttr)(attrs, 'unix.value'), isUnix, isUTC);
				Ember.set(this, 'time', date);
			} else if ((0, _map.getMapAttr)(attrs, 'timestamp.value')) {
				var _date = (0, _time.normalizeTime)((0, _map.getMapAttr)(attrs, 'timestamp.value'));
				Ember.set(this, 'time', _date);
			}

			if ((0, _map.getMapAttr)(attrs, 'minDate.value') || (0, _map.getMapAttr)(attrs, 'maxDate.value')) {
				Ember.set(this, 'min', (0, _time.normalizeTime)((0, _map.getMapAttr)(attrs, 'minDate.value')));
				Ember.set(this, 'max', (0, _time.normalizeTime)((0, _map.getMapAttr)(attrs, 'maxDate.value')));
			}
		}
	});
});