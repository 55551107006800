define('in-repo-pin-addon/components/g-map-addons/pin', ['exports', 'in-repo-pin-addon/templates/components/g-map-addons/pin'], function (exports, _pin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _pin.default,

    _type: 'pin',

    _addComponent: function _addComponent() {},
    _updateComponent: function _updateComponent() {}
  });
});