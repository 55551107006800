define("@busy-web/ember-date-time/utils/clock/render", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	/**
  * @module Utils/Clock
  *
  */
	var renderMixin = function renderMixin(Base) {
		return function (_Base) {
			_inherits(_class, _Base);

			function _class() {
				var _ref;

				_classCallCheck(this, _class);

				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				var _this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args)));

				_this.isRendering = false;
				return _this;
			}

			_createClass(_class, [{
				key: "startRender",
				value: function startRender() {
					this.isRendering = true;
				}
			}, {
				key: "endRender",
				value: function endRender() {
					this.isRendering = false;
				}
			}, {
				key: "renderDOM",
				value: function renderDOM() {}
			}, {
				key: "render",
				value: function render() {
					if (!this.isRendering) {
						this.startRender();
						this.renderDOM.apply(this, arguments);
						this.endRender();
					}
				}
			}]);

			return _class;
		}(Base);
	};

	exports.default = renderMixin;
});