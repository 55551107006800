define('@busy-web/ember-date-time/--private/utils/time', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.utcToLocal = utcToLocal;
	exports.utcFromLocal = utcFromLocal;
	exports.fromUnix = fromUnix;
	exports.toUnix = toUnix;
	exports.normalizeTime = normalizeTime;
	exports.denormalizeTime = denormalizeTime;
	function utcToLocal(time) {
		return _moment.default.utc(time).subtract(_moment.default.utc(time).local().utcOffset(), 'minutes').valueOf();
	} /**
    * @module Core/Utils
    *
    */
	function utcFromLocal(time) {
		return (0, _moment.default)(time).add((0, _moment.default)(time).utcOffset(), 'minutes').valueOf();
	}

	function fromUnix(time) {
		return _moment.default.unix(time).valueOf();
	}

	function toUnix(time) {
		return (0, _moment.default)(time).unix();
	}

	function normalizeTime(time, isUnix, isUTC) {
		if (!Ember.isNone(time)) {
			if (isUnix) {
				time = fromUnix(time);
			}
			if (isUTC) {
				time = utcToLocal(time);
			}
		}
		return time;
	}

	function denormalizeTime(time, isUnix, isUTC) {
		if (!Ember.isNone(time)) {
			if (isUTC) {
				time = utcFromLocal(time);
			}
			if (isUnix) {
				time = toUnix(time);
			}
		}
		return time;
	}
});