define('ember-google-maps/components/g-map/marker', ['exports', 'ember-google-maps/components/g-map/map-component', 'ember-google-maps/templates/components/g-map/marker', 'ember-google-maps/utils/helpers'], function (exports, _mapComponent, _marker, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mapComponent.default.extend({
    layout: _marker.default,
    tagName: '',

    _type: 'marker',
    _requiredOptions: ['position'],

    position: _helpers.position,

    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Marker(Ember.get(this, '_options')));
    }
  });
});