define('ember-google-maps/components/g-map/overlay', ['exports', 'ember-google-maps/components/g-map/map-component', 'ember-google-maps/templates/components/g-map/overlay', 'ember-google-maps/utils/helpers'], function (exports, _mapComponent, _overlay, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mapComponent.default.extend({
    layout: _overlay.default,

    _type: 'overlay',

    position: _helpers.position,

    paneName: 'overlayMouseTarget',

    _targetPane: null,
    _eventTarget: Ember.computed.reads('content'),

    innerContainerStyle: Ember.String.htmlSafe('transform: translateX(-50%) translateY(-100%);'),

    _contentId: Ember.computed(function () {
      return 'ember-google-maps-overlay-' + Ember.guidFor(this);
    }),

    _addComponent: function _addComponent() {
      var _this = this;

      var Overlay = new google.maps.OverlayView();

      return new Ember.RSVP.Promise(function (resolve) {
        Overlay.onAdd = function () {
          return Ember.run.join(_this, 'add');
        };
        Overlay.draw = function () {
          return Ember.run.schedule('render', function () {
            if (_this.isDestroyed) {
              return;
            }
            _this._initialDraw();
            resolve();
          });
        };
        Overlay.onRemove = function () {
          return Ember.run.join(_this, 'destroy');
        };

        Ember.set(_this, 'mapComponent', Overlay);
        Ember.run.next(function () {
          _this.mapComponent.setMap(Ember.get(_this, 'map'));
        });
      });
    },
    _initialDraw: function _initialDraw() {
      var _this2 = this;

      this.fetchOverlayContent();
      this.mapComponent.draw = function () {
        return _this2.draw();
      };
      this._updateComponent();
    },
    _updateComponent: function _updateComponent() {
      this.mapComponent.draw();
    },
    add: function add() {
      if (this.isDestroyed) {
        return;
      }
      var panes = this.mapComponent.getPanes();
      Ember.set(this, '_targetPane', Ember.get(panes, this.paneName));
    },
    draw: function draw() {
      var overlayProjection = this.mapComponent.getProjection();
      var position = Ember.get(this, 'position');
      var point = overlayProjection.fromLatLngToDivPixel(position);

      this.content.style.cssText = '\n      position: absolute;\n      left: 0;\n      top: 0;\n      height: 0;\n      transform: translateX(' + point.x + 'px) translateY(' + point.y + 'px);\n    ';
    },
    fetchOverlayContent: function fetchOverlayContent() {
      var element = document.getElementById(Ember.get(this, '_contentId'));
      Ember.set(this, 'content', element);
    },
    getPosition: function getPosition() {
      return Ember.get(this, 'position');
    }
  });
});