define('@busy-web/ember-cli-launch-darkly/initializers/@busy-web/ember-cli-launch-darkly', ['exports', '@busy-web/ember-cli-launch-darkly/services/launch-darkly'], function (exports, _launchDarkly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var serviceLookupName = 'service:launchDarkly';

    application.register(serviceLookupName, _launchDarkly.default);
    application.inject(serviceLookupName, 'application', 'application:main');
  }

  exports.default = {
    initialize: initialize
  };
});