define('@busy-web/ember-date-time/utils/time', ['exports', 'moment', '@busy-web/ember-date-time/utils/constant'], function (exports, _moment, _constant) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.i18n = i18n;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var Time = function (_moment$fn$constructo) {
		_inherits(Time, _moment$fn$constructo);

		function Time() {
			var _ref;

			_classCallCheck(this, Time);

			for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
				args[_key] = arguments[_key];
			}

			return _possibleConstructorReturn(this, (_ref = Time.__proto__ || Object.getPrototypeOf(Time)).call.apply(_ref, [this].concat(args)));
		}

		_createClass(Time, [{
			key: 'timestamp',
			value: function timestamp() {
				return this.valueOf();
			}
		}, {
			key: 'addFormatted',
			value: function addFormatted(value, format) {
				var type = convertType(format);
				if (type) {
					if (type === 'ampm') {
						type = 'h';
						value = value * 12;
					}
					this.add(value, type);
				} else {
					throw new Error('Format not found for ' + type);
				}
				return this;
			}
		}, {
			key: 'subFormatted',
			value: function subFormatted(value, format) {
				var type = convertType(format);
				if (type) {
					if (type === 'ampm') {
						type = 'h';
						value = value * 12;
					}
					this.subtract(value, type);
				} else {
					throw new Error('Format not found for ' + type);
				}
				return this;
			}
		}]);

		return Time;
	}(_moment.default.fn.constructor);

	function _time() {
		for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
			args[_key2] = arguments[_key2];
		}

		var m = _moment.default.apply(this, args);
		return new Time(m);
	}
	_time.unix = function (time) {
		return new Time(_moment.default.unix(time));
	};
	_time.utc = function (time) {
		return new Time(_moment.default.utc(time));
	};
	_time.localeData = function () {
		return _moment.default.localeData();
	};
	_time.locale = function () {
		return _moment.default.locale();
	};
	_time.duration = function (time, type) {
		return _moment.default.duration(time, type);
	};

	// _time.daysApart = (start, end) => Math.floor(moment.duration(end - start, 'ms').asDays());
	_time.daysApart = function (start, end) {
		return (0, _moment.default)(end).diff((0, _moment.default)(start), 'days');
	};
	_time.hoursApart = function (start, end) {
		return Math.floor(_moment.default.duration(end - start, 'ms').asHours());
	};

	_time.utcToLocal = function (time) {
		var m = _moment.default.utc(time).subtract(_moment.default.utc(time).local().utcOffset(), 'minutes');
		return new Time(m);
	};

	_time.utcFromLocal = function (time) {
		var m = (0, _moment.default)(time).add((0, _moment.default)(time).utcOffset(), 'minutes');
		return new Time(m);
	};

	_time.round = function (time) {
		var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

		var date = (0, _moment.default)(time);
		var minute = date.minute();
		var dist = minute % round;
		var low = round - dist;
		if (low > dist) {
			minute -= dist;
		} else {
			minute += low;
		}
		date.minute(minute).seconds(0);
		return date.valueOf();
	};

	_time.standardFormatTypes = function () {
		return {
			YYYY: /Y{1,4}/,
			MM: /M(o|M)?/,
			DD: /D(o|D)?/,
			HH: /(HH?|hh?)/,
			mm: /mm?/,
			ss: /ss?/
		};
	};

	_time.formatStringType = function (fmt) {
		if (/^D(o|D)?$/.test(fmt)) {
			return _constant.DAY_FLAG;
		} else if (/^d(o|d|dd|ddd)?$/.test(fmt)) {
			return _constant.WEEKDAY_FLAG;
		} else if (/^M(o|M|MM|MMM)?$/.test(fmt)) {
			return _constant.MONTH_FLAG;
		} else if (/^Y{1,4}$/.test(fmt)) {
			return _constant.YEAR_FLAG;
		} else if (/^hh?$/.test(fmt)) {
			return _constant.HOUR_FLAG;
		} else if (/^HH?$/.test(fmt)) {
			return 'm-hours';
		} else if (/^mm?$/.test(fmt)) {
			return _constant.MINUTE_FLAG;
		} else if (/^ss?$/.test(fmt)) {
			return 'seconds';
		} else if (/^A|a$/.test(fmt)) {
			return _constant.MERIDIAN_FLAG;
		} else {
			return null;
		}
	};

	_time.typeExp = function (type) {
		if (type === _constant.DAY_FLAG) {
			return (/D(o|D)?/
			);
		} else if (type === _constant.WEEKDAY_FLAG) {
			return (/d(o|d|dd|ddd)?/
			);
		} else if (type === _constant.MONTH_FLAG) {
			return (/M(o|M|MM|MMM)?/
			);
		} else if (type === _constant.YEAR_FLAG) {
			return (/Y{1,4}/
			);
		} else if (type === _constant.HOUR_FLAG) {
			return (/hh?/
			);
		} else if (type === 'm-hours') {
			return (/HH?/
			);
		} else if (type === _constant.MINUTE_FLAG) {
			return (/mm?/
			);
		} else if (type === 'seconds') {
			return (/ss?/
			);
		} else if (type === _constant.MERIDIAN_FLAG) {
			return (/A|a/
			);
		}
	};

	/**
 	* validates a moment date object
 	*
 	* @public
 	* @method isValidDate
 	* @param date {Moment}
 	* @return {boolean}
 	*/
	_time.isValidDate = function (date) {
		return !Ember.isNone(date) && (typeof date === 'undefined' ? 'undefined' : _typeof(date)) === 'object' && _moment.default.isMoment(date) && date.isValid();
	};

	/**
 	* validates a timestamp or unix timestamp
 	*
 	* @public
 	* @method isValidTimestamp
 	* @param timestamp {number}
 	* @return {boolean}
 	*/
	_time.isValidTimestamp = function (timestamp) {
		var isValid = false;
		if (typeof timestamp === 'number' && !isNaN(timestamp)) {
			var date = _time(timestamp);
			isValid = _time.isValidDate(date);
		}
		return isValid;
	};

	_time.isDateBefore = function (date, minDate) {
		var isBefore = false;
		if (!Ember.isNone(minDate)) {
			if (typeof minDate === 'number' && !isNaN(minDate)) {
				minDate = _time(minDate);
			}

			if (typeof date === 'number' && !isNaN(date)) {
				date = _time(date);
			}

			if ((typeof minDate === 'undefined' ? 'undefined' : _typeof(minDate)) === 'object' && _time.isValidDate(minDate)) {
				isBefore = date.isBefore(minDate);
			} else {
				(false && !(false) && Ember.assert('Invalid minDate passed to isDateInBounds'));
			}
		}
		return isBefore;
	};

	_time.isDateAfter = function (date, maxDate) {
		var isAfter = false;
		if (!Ember.isNone(maxDate)) {
			if (typeof maxDate === 'number' && !isNaN(maxDate)) {
				maxDate = _time(maxDate);
			}

			if (typeof date === 'number' && !isNaN(date)) {
				date = _time(date);
			}

			if ((typeof maxDate === 'undefined' ? 'undefined' : _typeof(maxDate)) === 'object' && _time.isValidDate(maxDate)) {
				isAfter = date.isAfter(maxDate);
			} else {
				(false && !(false) && Ember.assert('Invalid maxDate passed to isDateInBounds'));
			}
		}
		return isAfter;
	};

	/**
 	* checks if a timestamp is within the min and max dates
 	*
 	* returns an object with isBefore and isAfter boolean values
 	*
 	* @public
 	* @method isDateInBounds
 	* @param date {moment} moment date object
 	* @param minDate {number|Moment}
 	* @param maxDate {number|Moment}
 	* @return {object|} {isBefore: boolean, isAfter: boolean}
 	*/
	_time.isDateInBounds = function (date, minDate, maxDate) {
		var isBefore = _time.isDateBefore(date, minDate);
		var isAfter = _time.isDateAfter(date, maxDate);

		return { isBefore: isBefore, isAfter: isAfter };
	};

	function convertType(type) {
		var map = {
			'^(Y{1,4}|(gg){1,2}|(GG){1,2})$': 'y',
			'^M(o?|M{0,3})$': 'M',
			'^W(o|W)?$': 'w',
			'^Qo?$': 'Q',
			'^w(o|w)?$': 'w',
			'^D(o|DDo?|DD?D?)?$': 'd',
			'^d(o?|d{0,3})$': 'd',
			'^(HH?|hh?)$': 'h',
			'^mm?$': 'm',
			'^ss?$': 's',
			'^(A|a)$': 'ampm'
		};

		var res = void 0;
		Object.keys(map).forEach(function (key) {
			if (res === undefined) {
				var reg = new RegExp(key);
				if (reg.test(type)) {
					res = map[key];
				}
			}
		});
		return res;
	}

	exports.default = _time;


	var LOCALE_DATA = {
		"en": {
			this_day: 'Today',
			next_day: 'Tomorrow',
			last_day: 'Yesterday',
			this_week: 'This Week',
			next_week: 'Next Week',
			last_week: 'Last Week',
			this_month: 'This Month',
			next_month: 'Next Month',
			last_month: 'Last Month'
		},
		"en-us": {
			this_day: 'Today',
			next_day: 'Tomorrow',
			last_day: 'Yesterday',
			this_week: 'This Week',
			next_week: 'Next Week',
			last_week: 'Last Week',
			this_month: 'This Month',
			next_month: 'Next Month',
			last_month: 'Last Month'
		},
		"es": {
			this_day: 'Hoy',
			next_day: 'Mañana',
			last_day: 'Ayer',
			this_week: 'Esta Semana',
			next_week: 'La Próxima Semana',
			last_week: 'La Semana Pasada',
			this_month: 'Este Mes',
			next_month: 'Próximo Mes',
			last_month: 'El Mes Pasado'
		}
	};

	function i18n(key) {
		return LOCALE_DATA[_time.locale()][key];
	}
});