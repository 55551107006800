define('@busy-web/components/components/bc-select', ['exports', '@busy-web/components/mixins/close-on-escape', '@busy-web/components/mixins/bind-outside-click', '@busy-web/components/templates/components/bc-select'], function (exports, _closeOnEscape, _bindOutsideClick, _bcSelect) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend(_closeOnEscape.default, _bindOutsideClick.default, {
		layout: _bcSelect.default,

		classNames: ['bc-select'],
		classNameBindings: ['isOpen:active', 'isTop:top', 'small'],

		__closeActionName: 'closeMenu',

		/**
   * isOpen tracks the menu open state
   *
   * @private
   * @property isOpen
   * @type boolean
   */
		isOpen: false,
		isTop: false,
		small: false,
		openTop: false,
		highlightSlected: true,

		model: null,

		/**
   * internal use to track the current selected item from the list
   *
   * @private
   * @property selectedItem
   * @type object
   */
		selectedItem: Ember.computed('model.@each._selected', 'model.[]', function () {
			var selected = null;
			if (!Ember.isNone(Ember.get(this, 'model'))) {
				selected = this.getSelected();
			}
			return selected;
		}),

		getSelected: function getSelected() {
			var items = Ember.get(this, 'model');
			var selected = null;
			if ((typeof items === 'undefined' ? 'undefined' : _typeof(items)) === 'object' && typeof items.forEach === 'function') {
				items.forEach(function (item) {
					if (Ember.get(item, '_selected')) {
						selected = item;
					}
				});
			} else {
				Object.keys(items).forEach(function (key) {
					if (Ember.get(items[key], '_selected')) {
						selected = items[key];
					}
				});
			}
			return selected;
		},


		itemLabel: '',
		defaultLabel: Ember.String.loc('Select'),
		defaultFirstOption: false,

		menuTitle: Ember.computed('selectedItem', function () {
			var label = Ember.get(this, 'defaultLabel');
			var selectedItem = Ember.get(this, 'selectedItem');

			(false && !(!Ember.isEmpty(Ember.get(this, 'itemLabel'))) && Ember.assert('"itemLabel" must be set to a property of the model', !Ember.isEmpty(Ember.get(this, 'itemLabel'))));


			if (!Ember.isNone(selectedItem)) {
				label = Ember.get(selectedItem, Ember.get(this, 'itemLabel'));
			} else if (Ember.get(this, 'defaultFirstOption')) {
				selectedItem = Ember.get(this, 'model').objectAt(0);
				label = Ember.get(selectedItem, Ember.get(this, 'itemLabel'));
			}
			return label;
		}),

		checkPosition: function checkPosition() {
			var elem = this.$().offsetParent();

			var menuHeightTop = elem.height() - (elem.height() - (this.$().offset().top - elem.offset().top)) - 20; // height of the container minus the the bottom space from the top of the button.
			var menuHeightBot = elem.height() - (this.$().offset().top + this.$().height() - elem.offset().top) - 20; // height of the container minus the bottom of the select button.
			var maxHeight = parseInt(window.getComputedStyle(this.$('.select-container').get(0))['max-height'], 10);

			if (menuHeightTop > 200) {
				if (menuHeightBot < maxHeight) {
					if (menuHeightTop > menuHeightBot) {
						if (menuHeightTop < maxHeight) {
							menuHeightTop = menuHeightTop > 150 ? menuHeightTop : 150;
							this.$('.select-container').css('max-height', menuHeightTop);
						}
						return true;
					} else {
						menuHeightBot = menuHeightBot > 150 ? menuHeightBot : 150;
						this.$('.select-container').css('max-height', menuHeightBot);
					}
				}
			}
			return false;
		},
		unselectAll: function unselectAll() {
			var items = Ember.get(this, 'model');
			if ((typeof items === 'undefined' ? 'undefined' : _typeof(items)) === 'object' && typeof items.forEach === 'function') {
				items.forEach(function (item) {
					Ember.set(item, '_selected', false);
				});
			} else {
				Object.keys(items).forEach(function (key) {
					Ember.set(items[key], '_selected', false);
				});
			}
		},


		/**
   * handles an item click action
   * and sends the action to any listeners
   *
   * @private
   * @method itemClicked
   * @param item {object} The list item that was clicked
   * @returns {void}
   */
		itemClicked: function itemClicked(item) {
			this.unselectAll();

			Ember.set(item, '_selected', true);
			this.close();

			this.sendAction('onSelect', item);
		},
		onOutsideClick: function onOutsideClick() {
			this.close();
		},
		onEscape: function onEscape() {
			this.close();
			return false;
		},
		open: function open() {
			if (!Ember.get(this, 'isDestroyed')) {
				this.bindEscape();
				this.bindClick();

				Ember.set(this, 'isOpen', true);
				if (Ember.get(this, 'openTop')) {
					Ember.set(this, 'isTop', true);
				} else {
					if (this.checkPosition()) {
						Ember.set(this, 'isTop', true);
					} else {
						Ember.set(this, 'isTop', false);
					}
				}
			}
		},
		close: function close() {
			if (!Ember.get(this, 'isDestroyed')) {
				this.unbindEscape();
				this.unbindClick();
				Ember.set(this, 'isOpen', false);
			}
		},


		actions: {
			openMenu: function openMenu() {
				if (!Ember.get(this, 'isOpen')) {
					this.open();
				} else {
					this.close();
				}
			},
			closeMenu: function closeMenu() {
				this.close();
			},
			clickItemAction: function clickItemAction(item) {
				if (!Ember.get(item, '_unselectable')) {
					this.itemClicked(item);
				}
				return false;
			},
			stopPropagation: function stopPropagation() {
				return false;
			}
		}
	});
});