define('@busy-web/data/mixins/batch', ['exports', '@busy-web/data/mixins/rpc-adapter', '@busy-web/data/utils/query'], function (exports, _rpcAdapter, _query) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var RequestStore = Ember.Object.extend({
		//_maxPageSize: 30,
		queue: null,

		init: function init() {
			this._super();
			Ember.set(this, 'queue', []);
		},


		size: Ember.computed.alias('queue.length'),

		addRequest: function addRequest(request) {
			Ember.get(this, 'queue').pushObject(request);
			return this;
		},
		nextRequest: function nextRequest() {
			return Ember.get(this, 'queue').shiftObject();
		},
		checksum: function checksum(url, type, query) {
			// stringify the data
			var dataStr = window.unescape(window.encodeURIComponent(JSON.stringify(query)));

			// return a btoa hash of the url + data + type
			return btoa(url + '-' + dataStr + '-' + type);
		},
		extractDataFromUrl: function extractDataFromUrl(url, data) {
			var _url$split = url.split('?'),
			    _url$split2 = _slicedToArray(_url$split, 2),
			    baseUrl = _url$split2[0],
			    queryString = _url$split2[1];

			Ember.merge(data, _query.default.parse(queryString));

			// remove protocol from url
			baseUrl = baseUrl.replace(/^http[s]?:\/\//, '');

			// get model name and id

			var _baseUrl$split = baseUrl.split('/'),
			    _baseUrl$split2 = _slicedToArray(_baseUrl$split, 3),
			    model = _baseUrl$split2[1],
			    id = _baseUrl$split2[2];

			if (!Ember.isEmpty(id)) {
				//return [ model, id ].join('/');
				Ember.set(data, 'id', id);
			}
			return model;
		},
		flushPending: function flushPending() {
			var _this = this;

			var pending = Ember.get(this, 'queue').splice(0, 50);
			var requests = {};
			var responses = {};
			var map = new window.Map();

			var count = 1;
			pending.forEach(function (item) {
				// get url data and type from hash
				var params = {};
				var url = item.url,
				    type = item.type,
				    data = item.data;

				data = data || {};
				url = _this.extractDataFromUrl(url, data);
				var name = url + '-' + count;

				// create a checksum hash of the hash data, url, and type
				var checksum = _this.checksum(url, type, data);
				if (!map.get(checksum)) {
					count = count + 1;
					map.set(checksum, name);

					Ember.merge(params, { url: url, data: data, method: type });

					requests[name] = params;
					responses[name] = [item];
				} else {
					name = map.get(checksum);
					responses[name].push(item);
				}
			});

			return { requests: requests, responses: responses };
		}
	});

	/**
  *
  */
	exports.default = Ember.Mixin.create(_rpcAdapter.default, {
		isBatchEnabled: true,

		init: function init() {
			this.__tries = 0;
			this._requestStore = RequestStore.create({});

			this._super();
		},
		_pushQueue: function _pushQueue(hash) {
			this._requestStore.addRequest(hash);
		},
		_requestFor: function _requestFor(params) {
			var request = this._super.apply(this, arguments);
			if (params.disableBatch || params._requestType === 'rpc') {
				request.disableBatch = true;
			}
			return request;
		},
		_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
			var hash = this._super.apply(this, arguments);
			if (request.disableBatch) {
				hash.disableBatch = true;
			}
			return hash;
		},
		_ajaxRequest: function _ajaxRequest(hash) {
			if (this.get('isBatchEnabled') === true && !hash.disableBatch) {
				if (Ember.get(this, '_requestStore.size') === 0) {
					Ember.run.schedule('afterRender', this, this._flushPending);
				}
				this._pushQueue(hash);
			} else {
				this._super(hash);
			}
		},
		_flushPending: function _flushPending() {
			if (Ember.get(this, '_requestStore.size') > 0) {
				if (Ember.get(this, '_requestStore.size') === 1) {
					this._sendCall();
				} else {
					this._sendBatch();
				}
			}
		},
		_sendCall: function _sendCall() {
			var _requestStore$flushPe = this._requestStore.flushPending(),
			    responses = _requestStore$flushPe.responses;

			var key = Object.keys(responses)[0];
			var resp = responses[key][0];

			Ember.$.ajax(resp);
		},
		_sendBatch: function _sendBatch() {
			var _requestStore$flushPe2 = this._requestStore.flushPending(),
			    requests = _requestStore$flushPe2.requests,
			    responses = _requestStore$flushPe2.responses;

			this.rpcRequest(this.store, 'batch', 'batch-rest', { requests: requests }).then(function (batch) {
				var results = Ember.get(batch, 'data.results');
				if (batch.success && results) {
					Object.keys(results).forEach(function (key) {
						var resp = results[key];
						var status = resp.statusCode;
						var statusCode = resp.status;

						delete resp.status;
						delete resp.statusCode;

						var contentType = 'application/json; charset=utf-8';
						if (typeof resp === 'string') {
							contentType = "content-type: text/plain; charset=UTF-8";
						}

						var jqXHR = {
							status: status,
							statusText: 'error',
							responseText: statusCode,
							readyState: 4,
							getAllResponseHeaders: function getAllResponseHeaders() {
								return contentType;
							}
						};

						responses[key].forEach(function (item) {
							if (status >= 200 && status < 300 || status === 304) {
								jqXHR.statusText = "success";
								Ember.get(item, 'success')(resp, status, jqXHR);
							} else {
								Ember.get(item, 'error')(jqXHR, status, statusCode);
							}
						});
					});
				} else {
					return Ember.RSVP.reject(batch);
				}
			});

			this._flushPending();
		}
	});
});