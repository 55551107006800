define('@busy-web/graylog/utils/logger', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	function post(url) {
		var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
		(false && !(!Ember.isEmpty(url)) && Ember.assert("url is required to send a post message", !Ember.isEmpty(url)));


		return new Ember.RSVP.Promise(function (resolve, reject) {
			Ember.$.ajax({
				url: url,
				data: JSON.stringify(data),
				type: 'POST',
				dataType: 'josn',
				contentType: 'application/json; charset=UTF-8',
				processData: false,
				success: function success(res) {
					Ember.run(null, resolve, true);
				},
				error: function error(err) {
					Ember.run(null, reject, err);
				}
			});
		});
	} /**
    * @module utils
    *
    */
	exports.default = Ember.Object.extend().reopenClass({
		send: function send(url) {
			var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			return post(url, data);
		}
	});
});