define('@busy-web/ember-date-time/--private/handlers/clock', ['exports', '@busy-web/ember-date-time/--private/handlers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    __handler: 'clock',

    round: _base.default.property({ defaultValue: 1 }),
    roundSelect: _base.default.property({ defaultValue: 1 })
  });
});