define('ember-google-maps/mixins/process-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function addObservers(obj, keys, callback) {
    keys.forEach(function (key) {
      return obj.addObserver(key, callback);
    });
  }

  function removeObservers(obj, keys, callback) {
    keys.forEach(function (key) {
      return obj.removeObserver(key, callback);
    });
  }

  /**
   * @class ProcessOptions
   * @module ember-google-maps/mixins/process-options
   * @extends Ember.Mixin
   */
  exports.default = Ember.Mixin.create({
    concatenatedProperties: ['_requiredOptions', '_watchedOptions', '_ignoredAttrs'],

    /**
     * Specify which attributes on the component should be ignored and never
     * considered as a Google Maps option or event.
     *
     * @property _ignoredAttrs
     * @private
     * @type {String[]}
     */
    _ignoredAttrs: ['map', '_internalAPI', 'gMap', 'lat', 'lng', 'events'],

    /**
     * Required options that are always included in the options object passed to
     * the map component.
     *
     * @property _requiredOptions
     * @private
     * @type {String[]}
     */
    _requiredOptions: [],

    /**
     * Paths to watch for changes. The paths follow the same syntax as the keys
     * for Ember observers and computed properties.
     *
     * @property _watchedOptions
     * @private
     * @type {String[]}
     */
    _watchedOptions: [],

    /**
     * Combined object of options and events used to set and update the options
     * on the map component.
     *
     * @property options
     * @public
     * @return {Object}
     */
    options: Ember.computed('attrs', 'events', function () {
      var _EmberGetProperties = Ember.getProperties(this, '_ignoredAttrs', '_eventAttrs'),
          _ignoredAttrs = _EmberGetProperties._ignoredAttrs,
          _eventAttrs = _EmberGetProperties._eventAttrs;

      var ignoredAttrs = [].concat(_toConsumableArray(_ignoredAttrs), _toConsumableArray(_eventAttrs));

      var attrs = Object.keys(this.attrs).filter(function (attr) {
        return ignoredAttrs.indexOf(attr) === -1;
      });

      return Ember.getProperties(this, attrs);
    }),

    _options: Ember.computed('map', 'options', function () {
      var options = Ember.get(this, 'options');
      var _requiredOptions = Ember.get(this, '_requiredOptions');
      var required = Ember.getProperties(this, _requiredOptions);

      return Ember.assign(required, options);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (!this._eventAttrs) {
        this._eventAttrs = [];
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      var _watchedOptions = Ember.get(this, '_watchedOptions');
      removeObservers(this, _watchedOptions, function () {
        if (_this._isInitialized) {
          _this._updateComponent();
        }
      });

      this._super.apply(this, arguments);
    },
    _registerOptionObservers: function _registerOptionObservers() {
      var _this2 = this;

      var _watchedOptions = Ember.get(this, '_watchedOptions');
      if (_watchedOptions.length > 0) {
        addObservers(this, _watchedOptions, function () {
          if (_this2._isInitialized) {
            _this2._updateComponent();
          }
        });
      }
    }
  });
});