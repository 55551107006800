define('@busy-web/data/mixins/image-adapter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		/**
   * sets up the parameters for the ajax call
   *
   * @private
   * @method ajaxOptions
   * @param url {string}
   * @param type {object} model type
   * @param options {object} data options
   * @returns {object} ajax call object
   */
		ajaxOptions: function ajaxOptions(url, type, options) {
			options = options || {};

			var data = Ember.merge({}, Ember.get(options, 'data'));
			var isFile = !Ember.isNone(Ember.get(options, 'data._fileObject'));
			var hash = this._super.apply(this, arguments);

			if (isFile) {
				Ember.set(hash, 'data', data);
				this.setupUpload(hash);
			}

			return hash;
		},
		_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
			request = request || {};

			var isFile = !Ember.isNone(Ember.get(request, 'data._fileObject'));
			if (isFile) {
				request.headers.Accept = 'application/json; charset=utf-8';
			}

			var hash = this._super(request) || {};

			if (isFile) {
				this.setupUpload(hash);
			}

			return hash;
		},


		/**
   * set up the ajax call for a file upload
   *
   * @private
   * @method setupUpload
   * @params hash {object}
   * @returns {object}
   */
		setupUpload: function setupUpload(hash) {
			var _this = this;

			// gets the fileObject from the hash.data object
			// that was created in the serializer.serializeIntoHash
			// The fileObject has event listeners for uploadStart,
			// uploadProgress and uploadComplete
			var fileObject = Ember.get(hash, 'data._fileObject');
			fileObject.uploadStart();

			// set the ajax complete function to trigger
			// the fileObject uploadComplete event
			hash.complete = function () {
				fileObject.uploadComplete();
			};

			// remove the _fileObject from the hash.data
			// so it doesn't get sent to the api.
			delete hash.data._fileObject;

			// convert the hash.data to a formData object
			Ember.set(hash, 'data', this.convertDataForUpload(hash.data));

			// set contentType and processData to false
			// for file uploads
			Ember.set(hash, 'contentType', false);
			Ember.set(hash, 'processData', false);

			// dont allow batch call
			Ember.set(hash, 'disableBatch', true);

			// set the xhr function to report
			// upload progress
			Ember.set(hash, 'xhr', function () {
				var xhr = Ember.$.ajaxSettings.xhr();
				Ember.set(xhr, 'upload.onprogress', function (e) {
					Ember.run.later(_this, function () {
						fileObject.uploadProgress(e);
					}, 100);
				});
				return xhr;
			});
		},


		/**
   * converts data object into a formdata object
   *
   * @method convertDataForUpload
   * @params data {object}
   * @returns {object}
   */
		convertDataForUpload: function convertDataForUpload(data) {
			var formData = new FormData();
			Ember.$.each(data, function (key, val) {
				if (data.hasOwnProperty(key)) {
					if (key !== 'file_url' && key !== 'file_thumb_url' && key !== 'image_url' && key !== 'image_thumb_url') {
						formData.append(key, val);
					}
				}
			});
			return formData;
		}
	});
});