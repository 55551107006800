define('@busy-web/ember-date-time/helpers/format-date', ['exports', '@busy-web/ember-date-time/utils/time'], function (exports, _time) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  /**
   * @module helpers
   *
   */
  function formatDate(params) {
    var timestamp = params[0];
    var f = params[1] || 'L';
    return (0, _time.default)(timestamp).format(f);
  }

  exports.default = Ember.Helper.helper(formatDate);
});