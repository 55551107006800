define('@busy-web/components/components/bc-section-half', ['exports', '@busy-web/components/templates/components/bc-section-half'], function (exports, _bcSectionHalf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bcSectionHalf.default,
    tagName: 'section',
    classNames: ['bc-section-half'],
    classNameBindings: ['active']
  });
});