define('@busy-web/utils/index', ['exports', '@busy-web/utils/assert', '@busy-web/utils/browser', '@busy-web/utils/currency', '@busy-web/utils/time', '@busy-web/utils/uuid', '@busy-web/utils/loc', '@busy-web/utils/locale', '@busy-web/utils/local-storage'], function (exports, _assert, _browser, _currency, _time, _uuid, _loc, _locale, _localStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.UUID = exports.Time = exports.Locale = exports.LocalStorage = exports.loc = exports.Currency = exports.Browser = exports.Assert = undefined;
	exports.Assert = _assert.default;
	exports.Browser = _browser.default;
	exports.Currency = _currency.default;
	exports.loc = _loc.default;
	exports.LocalStorage = _localStorage.default;
	exports.Locale = _locale.default;
	exports.Time = _time.default;
	exports.UUID = _uuid.default;
});