define('ember-google-maps/components/g-map/polyline', ['exports', 'ember-google-maps/components/g-map/map-component'], function (exports, _mapComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mapComponent.default.extend({
    _type: 'polyline',
    _requiredOptions: ['path'],
    _watchedOptions: ['path.[]'],

    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Polyline(Ember.get(this, '_options')));
    }
  });
});