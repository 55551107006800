define('@busy-web/utils/uuid', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var UUID = Ember.Object.extend();

	/**
  * `Util/UUID`
  *
  * @class UUID
  * @namespace Utils
  */
	/**
  * @module Utils
  *
  */
	UUID.reopenClass({
		generate: function generate() {
			// gennerate the uuid
			var id = _emberUuid.v4.apply(_emberUuid.v4, arguments);

			// assert the uuid created a valid uuid
			(false && !(this.isValid(id)) && Ember.assert('Generate created an invalid UUID', this.isValid(id)));


			// return the uuid.
			return id;
		},
		isValid: function isValid(id) {
			return (/^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}$/i.test(id)
			);
		}
	});

	exports.default = UUID;
});