define('@busy-web/components/mixins/close-on-escape', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		escape: Ember.inject.service('escape-handler'),

		bindEscape: function bindEscape() {
			this.get('escape').addListener(this, this.get('elementId'));
		},
		unbindEscape: function unbindEscape() {
			this.get('escape').removeListener(this.get('elementId'));
		},
		onEscape: function onEscape() {},
		willDestroyElement: function willDestroyElement() {
			this._super();
			this.unbindEscape();
		}
	});
});