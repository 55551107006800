define('@busy-web/components/components/bc-validate-password', ['exports', '@busy-web/components/components/bc-validate'], function (exports, _bcValidate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bcValidate.default.extend({
    validateExpression: /^(?=.*[0-9])(?=.*[a-zA-Z])(\w|[!@#$%^&*?_~-]).{5,}$/,

    type: 'password',

    invalidError: 'Password must contain a number, a letter, and be more than 6 characters long.',

    placeholder: 'Password'
  });
});