define('ember-google-maps/utils/runloopify-google-maps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var trigger = google.maps.event.trigger;
    google.maps.event.trigger = Ember.run.bind(google.maps.event, trigger);
  };
});