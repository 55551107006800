define('@busy-web/graylog/services/graylog', ['exports', '@busy-web/graylog/utils/logger', '@busy-web/graylog/utils/system-info'], function (exports, _logger, _systemInfo2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/**
  * get browser locale info
  *
  */
	/**
  * @module Service
  *
  */
	function getLocale() {
		var allLanguages = window.navigator.languages;
		if (Ember.isNone(allLanguages)) {
			allLanguages = [window.navigator.language];
		}

		var lang = allLanguages.find(function (language) {
			return typeof language === 'string';
		});

		if (Ember.isNone(lang)) {
			lang = 'en-US';
		}
		return lang;
	}

	/**
  * add properties to data object
  *
  */
	function addProperties(target, data) {
		Object.keys(target).forEach(function (key) {
			var val = Ember.get(target, key);
			if (!/^_/.test(key)) {
				key = '_' + key;
			}
			Ember.set(data, key, val);
		});
	}

	/**
  * setup data object
  *
  */
	function getData() {
		var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
		var config = arguments[1];
		(false && !(!Ember.isEmpty(Ember.get(config, 'version'))) && Ember.assert('version not set in graylog.port config settings', !Ember.isEmpty(Ember.get(config, 'version'))));
		(false && !(!Ember.isEmpty(Ember.get(opts, 'short_message'))) && Ember.assert('short_message is required to send data to graylog', !Ember.isEmpty(Ember.get(opts, 'short_message'))));
		(false && !(!Ember.isEmpty(Ember.get(opts, 'full_message'))) && Ember.assert('full_message is required to send data to graylog', !Ember.isEmpty(Ember.get(opts, 'full_message'))));


		var data = {
			'version': Ember.get(config, 'version'),
			'timestamp': Math.floor(Date.now() / 1000),
			'host': Ember.getWithDefault(opts, 'host', window.location.hostname),
			'short_message': Ember.get(opts, 'short_message'),
			'full_message': Ember.get(opts, 'full_message'),
			'level': Ember.getWithDefault(opts, 'level', Ember.getWithDefault(config, 'level', 1))
		};

		delete opts.short_message;
		delete opts.full_message;
		delete opts.level;
		delete opts.host;

		addProperties(opts, data);

		if (Ember.get(config, 'enable_extras')) {
			Ember.set(data, '_language', getLocale());

			var _systemInfo = (0, _systemInfo2.default)(),
			    browser = _systemInfo.browser,
			    version = _systemInfo.version,
			    platform = _systemInfo.platform;

			Ember.set(data, '_platform', browser);
			Ember.set(data, '_model', platform);
			Ember.set(data, '_os_version', version);

			Ember.set(data, '_manufacturer', window.navigator.vendor);
		}

		if (Ember.get(config, 'defaults')) {
			addProperties(Ember.get(config, 'defaults'), data);
		}
		return data;
	}

	/**
  * build the url based on config settings for graylog
  *
  */
	function getURL(config) {
		(false && !(!Ember.isEmpty(Ember.get(config, 'api_url'))) && Ember.assert('api_url not set in graylog.port config settings', !Ember.isEmpty(Ember.get(config, 'api_url'))));
		(false && !(!Ember.isEmpty(Ember.get(config, 'api_port'))) && Ember.assert('api_port not set in graylog.port config settings', !Ember.isEmpty(Ember.get(config, 'api_port'))));


		var port = Ember.get(config, 'api_port');
		var host = Ember.get(config, 'api_url');

		return host + ':' + port + '/gelf';
	}

	exports.default = Ember.Service.extend({
		__config: null,

		init: function init() {
			this._super();

			var owner = Ember.getOwner(this);
			var config = owner.resolveRegistration('config:environment');
			var conf = config.graylog || {};

			Ember.set(this, '__config', conf);
		},


		/**
   * send events to graylog
   *
   * @public
   * @method sendEvent
   * @params data {object} data to send to graylog
   * @return {RSVP}
   */
		sendEvent: function sendEvent() {
			var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			var config = Ember.get(this, '__config');
			var url = getURL(config);
			var options = getData(data, config);

			return _logger.default.send(url, options);
		}
	});
});