define('@busy-web/ember-date-time/components/ember-date-range-picker', ['exports', '@busy-web/ember-date-time/utils/state', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/utils/key-event', '@busy-web/ember-date-time/utils/format', '@busy-web/ember-date-time/templates/components/ember-date-range-picker', '@busy-web/ember-date-time/utils/constant'], function (exports, _state2, _time2, _keyEvent, _format, _emberDateRangePicker, _constant) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		/**
   * @private
   * @property classNames
   * @type String
   */
		classNames: ['busyweb', 'emberdatetime', 'c-date-range-picker'],
		classNameBindings: ['large', 'right'],
		layout: _emberDateRangePicker.default,

		large: false,
		right: false,

		/**
   * timestamp that is passed in as a milliseconds timestamp
   *
   * @private
   * @property timestamp
   * @type Number
   */
		startTime: null,
		endTime: null,

		/**
   * timestamp that is passed in as a seconds timestamp
   *
   * @public
   * @property unix
   * @type number
   */
		startUnix: null,
		endUnix: null,

		/**
   * can be passed in so a date after the maxDate cannot be selected
   *
   * @private
   * @property maxDate
   * @type Number
   * @optional
   */
		maxDate: null,

		/**
   * can be passed in so a date before the minDate cannot be selected
   *
   * @private
   * @property minDate
   * @type Number
   * @optional
   */
		minDate: null,

		/**
   * set to true if the values passed in should not be converted to local time
   *
   * @public
   * @property utc
   * @type boolean
   */
		utc: false,

		format: 'MMM DD, YYYY',

		_start: null,
		_end: null,
		_min: null,
		_max: null,

		hideTime: true,
		hideDate: false,

		defaultAction: '',

		stateManager: null,
		calendar: null,

		isStart: true,

		isListOpen: false,
		isCustom: false,
		allowCustom: true,
		activeDates: null,
		weekStart: 0,
		defaultDateOnChange: true,
		restrictAfterNow: false,

		inputDataName: Ember.computed('elementId', function () {
			return 'range-picker-' + Ember.get(this, 'elementId');
		}),

		_startDate: Ember.computed('_start', function () {
			return (0, _time2.default)(Ember.get(this, '_start'));
		}),

		_endDate: Ember.computed('_end', function () {
			return (0, _time2.default)(Ember.get(this, '_end'));
		}),

		disableNext: Ember.computed('selected', '_start', '_max', function () {
			if (Ember.get(this, 'restrictAfterNow')) {
				var today = (0, _time2.default)();
				var start = (0, _time2.default)(getStart(this));
				var end = (0, _time2.default)(getEnd(this));
				if (today.valueOf() >= start.valueOf() && today.valueOf() <= end.valueOf()) {
					return true;
				}
			}

			var _getInterval = this.getInterval(1),
			    startTime = _getInterval.startTime;

			return Ember.get(this, '_max') < startTime;
		}),

		disablePrev: Ember.computed('selected', '_end', '_min', function () {
			var _getInterval2 = this.getInterval(-1),
			    end = _getInterval2.end;

			return Ember.get(this, '_min') > end;
		}),

		isInRange: function isInRange(date, start, end) {
			return date.valueOf() >= start.valueOf() && date.valueOf() <= end.valueOf();
		},


		selectedDateRange: Ember.computed('selected', '_start', '_end', 'format', function () {
			var _EmberGet = Ember.get(this, 'selected'),
			    id = _EmberGet.id;

			var start = (0, _time2.default)(getStart(this));
			var end = (0, _time2.default)(getEnd(this));
			var isCurrent = false;
			if (this.isInRange((0, _time2.default)(), start, end)) {
				isCurrent = true;
			}

			if (id === 'monthly') {
				if (isCurrent) {
					return (0, _time2.i18n)('this_month');
				} else if (this.isInRange((0, _time2.default)().add(1, 'months'), start, end)) {
					return (0, _time2.i18n)('next_month');
				} else if (this.isInRange((0, _time2.default)().subtract(1, 'months'), start, end)) {
					return (0, _time2.i18n)('last_month');
				} else if ((0, _time2.default)().year() !== start.year()) {
					return Ember.String.classify(start.format('MMMM')) + ' ' + start.year();
				}
				return Ember.String.classify(start.format('MMMM'));
			} else if (id === 'weekly') {
				if (isCurrent) {
					return (0, _time2.i18n)('this_week');
				} else if (this.isInRange((0, _time2.default)().add(1, 'weeks'), start, end)) {
					return (0, _time2.i18n)('next_week');
				} else if (this.isInRange((0, _time2.default)().subtract(1, 'weeks'), start, end)) {
					return (0, _time2.i18n)('last_week');
				} else if ((0, _time2.default)().year() !== start.year() && start.year() !== end.year()) {
					return start.format('MMM D YYYY') + ' - ' + end.format('MMM D YYYY');
				} else if ((0, _time2.default)().year() !== start.year()) {
					return start.format('MMM D') + ' - ' + end.format('MMM D YYYY');
				}
				return start.format('MMM D') + ' - ' + end.format('MMM D');
			} else if (id === 'daily') {
				if (isCurrent) {
					return (0, _time2.i18n)('this_day');
				} else if (this.isInRange((0, _time2.default)().add(1, 'days'), start, end)) {
					return (0, _time2.i18n)('next_day');
				} else if (this.isInRange((0, _time2.default)().subtract(1, 'days'), start, end)) {
					return (0, _time2.i18n)('last_day');
				} else if ((0, _time2.default)().year() === start.year() && (0, _time2.default)().week() === start.week()) {
					return start.format('dddd');
				} else if ((0, _time2.default)().year() !== start.year()) {
					return start.format('MMM D') + ' - ' + end.format('MMM D YYYY');
				}
				return '' + start.format('MMM D');
			} else if (start.year() !== end.year()) {
				return start.format('ll') + ' - ' + end.format('ll');
			} else {
				return start.format('MMM D') + ' - ' + end.format('MMM D');
			}
		}),

		getAttr: function getAttr(key) {
			var attrs = Ember.get(this, 'attrs');
			if (!Ember.isNone(Ember.get(attrs, key))) {
				return Ember.get(this, key);
			}
			return null;
		},


		setup: Ember.on('init', function () {
			var isUnix = !Ember.isNone(Ember.get(this, 'startUnix')) || !Ember.isNone(Ember.get(this, 'endUnix'));
			Ember.set(this, '_isUnix', isUnix);

			// get locale converted format str
			var format = Ember.get(this, 'format');
			format = (0, _format.longFormatDate)(format);

			(false && !(/^(DD.MM.YYYY|DD.YYYY.MM|MM.DD.YYYY|MM.YYYY.DD|YYYY.MM.DD|YYYY.DD.MM)$/.test(format)) && Ember.assert('Moment format "' + Ember.get(this, 'format') + '" is not supported. All format strings must be a combination of "DD" "MM" "YYYY" with one of the following delimeters [ -./, ] and no spaces', /^(DD.MM.YYYY|DD.YYYY.MM|MM.DD.YYYY|MM.YYYY.DD|YYYY.MM.DD|YYYY.DD.MM)$/.test(format)));


			Ember.set(this, '__dayIndex', format.search(/D(D|o)/));
			Ember.set(this, '__monthIndex', format.search(/M(M|o)/));
			Ember.set(this, '__format', format);

			if (Ember.isNone(Ember.get(this, 'activeDates'))) {
				Ember.set(this, 'activeDates', []);
			}

			if (!Ember.get(this, 'changeFired') && (!Ember.isNone(this.getAttr('startTime')) || !Ember.isNone(this.getAttr('startUnix')))) {
				setStart(this, setUserStart(this, this.getAttr('startTime') || this.getAttr('startUnix')));
			} else if (Ember.isNone(getStart(this))) {
				setStart(this, (0, _time2.default)().timestamp());
			}

			if (!Ember.get(this, 'changeFired') && (!Ember.isNone(this.getAttr('endTime')) || !Ember.isNone(this.getAttr('endUnix')))) {
				setEnd(this, setUserEnd(this, this.getAttr('endTime') || this.getAttr('endUnix')));
			} else if (Ember.isNone(getEnd(this))) {
				setEnd(this, (0, _time2.default)().timestamp());
			}

			if (Ember.get(this, 'changeFired')) {
				Ember.set(this, 'changeFired', false);
			} else {
				this.setState();
			}

			if (!Ember.isNone(this.getAttr('minDate'))) {
				var min = this.getAttr('minDate');
				if (isUnix) {
					min = _time2.default.unix(min).timestamp();
				}
				min = (0, _time2.default)(min).startOf('day').timestamp();

				if (Ember.get(this, '_min') !== min) {
					Ember.set(this, '_min', min);
				}
			}

			if (!Ember.isNone(Ember.get(this, 'maxDate'))) {
				var max = Ember.get(this, 'maxDate');
				if (isUnix) {
					max = _time2.default.unix(max).timestamp();
				}
				max = (0, _time2.default)(max).endOf('day').valueOf();

				if (Ember.get(this, '_max') !== max) {
					Ember.set(this, '_max', max);
				}
			}

			var actionList = Ember.get(this, '__actionList') || [];
			if (Ember.isEmpty(actionList)) {
				var tList = [];
				var sortKey = 400;
				(this.getAttr('actionList') || []).forEach(function (item) {
					if (!item.get && !item.set) {
						item = Ember.Object.create(item);
					}

					var name = Ember.get(item, 'name');

					(false && !(!Ember.isEmpty(name)) && Ember.assert("Action list items must contain a `name` property", !Ember.isEmpty(name)));


					if (Ember.isNone(item, 'id')) {
						Ember.set(item, 'id', Ember.String.underscore(name));
					}

					if (Ember.isNone(Ember.get(item, 'sort'))) {
						Ember.set(item, 'sort', sortKey);
						sortKey += 1;
					}

					Ember.set(item, 'selected', false);
					tList.push(item);
				});

				actionList = tList;

				// action list is the list used in the select menu.
				//
				// id {string} - string id passed around for reference to a list item
				// name {string} - the label to display in the list
				// span {number|function} - the time span in time relational to {type} if function is provided it will be passed the current timestamp
				// type {string} - the units used to calculate the time {span}
				// sort {number} - a weighted number used to sort the list
				// selected {boolean} a true if the item is currently the selected item
				actionList.push(Ember.Object.create({ id: 'daily', name: Ember.String.loc('Daily'), span: 1, type: 'days', sort: 100, selected: false }));
				actionList.push(Ember.Object.create({ id: 'weekly', name: Ember.String.loc('Weekly'), span: 1, type: 'weeks', sort: 200, selected: false }));
				actionList.push(Ember.Object.create({ id: 'monthly', name: Ember.String.loc('Monthly'), span: 1, type: 'months', sort: 300, selected: false }));

				actionList = actionList.sort(function (a, b) {
					return Ember.get(a, 'sort') > Ember.get(b, 'sort') ? 1 : -1;
				});
				Ember.set(this, '__actionList', actionList);
			}

			if (Ember.isNone(Ember.get(this, 'selected'))) {
				this.setSelected();
				this.saveState();
			}

			var elementId = Ember.get(this, 'elementId');
			Ember.$('body').off('keydown.' + elementId);
			Ember.$('body').on('keydown.' + elementId, keyDownEventHandler(this));

			Ember.$('body').off('click.' + elementId);
			Ember.$('body').on('click.' + elementId, clickEventHandler(this));
		}),

		destroy: Ember.on('willDestroyElement', function () {
			Ember.$('body').off('keydown.' + Ember.get(this, 'elementId'));
			Ember.$('body').off('click.' + Ember.get(this, 'elementId'));
		}),

		getDefaultAction: function getDefaultAction() {
			if (Ember.get(this, 'isCustom')) {
				var span = _time2.default.daysApart(getStart(this), getEnd(this)) + 1;
				return Ember.Object.create({ name: Ember.String.loc('Custom'), span: span, type: 'days' });
			} else if (!Ember.isEmpty(Ember.get(this, 'defaultAction'))) {
				return Ember.get(this, '__actionList').findBy('id', Ember.get(this, 'defaultAction'));
			} else {
				var start = getStart(this);
				var end = getEnd(this);
				var startDate = (0, _time2.default)(start);
				var endDate = (0, _time2.default)(end);
				var _span = Math.abs(startDate.diff(endDate, 'days'));
				var diff = Number.MAX_VALUE;

				var selected = void 0;
				Ember.get(this, '__actionList').forEach(function (item) {
					if (typeof item.span !== 'function') {
						var timeSpan = (0, _time2.default)(start).add(item.span, item.type);
						var itemSpan = Math.abs(startDate.diff(timeSpan, 'days'));
						var nDiff = Math.abs(itemSpan - _span);
						if (diff > nDiff) {
							selected = item;
							diff = nDiff;
						}
					}
				});
				return selected;
			}
		},
		setActiveState: function setActiveState(isStart) {
			if (Ember.get(this, 'allowCustom')) {
				Ember.set(this, 'isStart', isStart);
			} else {
				Ember.set(this, 'isStart', true);
			}
		},
		getInterval: function getInterval() {
			var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

			var _EmberGet2 = Ember.get(this, 'selected'),
			    span = _EmberGet2.span,
			    type = _EmberGet2.type;

			var start = void 0,
			    end = void 0;
			if (!Ember.isEmpty(type) && !Ember.isNone(span)) {
				start = (0, _time2.default)(getStart(this)).valueOf();
				end = (0, _time2.default)(getEnd(this)).valueOf();

				var currentSpanInSeconds = (0, _time2.default)(end).unix() - (0, _time2.default)(start).unix();
				var selectedSpanInSeconds = (0, _time2.default)(start).add(span, type).unix() - (0, _time2.default)(start).unix();

				if (typeof span === 'function') {
					start = getUserStart(this);
					end = getUserEnd(this);

					// get range defined by span function
					var range = span.call(Ember.get(this, 'selected'), start, end, direction);
					start = setUserStart(this, range.start);
					end = setUserEnd(this, range.end);
				} else {
					// if the current time span is greater than selected time span
					// then set the new start to the end of the current period.
					// this will keep the date more current.
					if (currentSpanInSeconds > selectedSpanInSeconds) {
						start = (0, _time2.default)(getEnd(this)).startOf('day').valueOf();
						if (!Ember.isNone(Ember.get(this, '_max')) && start > Ember.get(this, '_max')) {
							start = (0, _time2.default)(Ember.get(this, '_max')).subtract(span, type).startOf('day').valueOf();
						}
					}

					if (type === 'weeks') {
						if ((0, _time2.default)(start).day() !== Ember.get(this, 'weekStart')) {
							var __start = (0, _time2.default)(start).day(Ember.get(this, 'weekStart')).valueOf();
							if (start < __start) {
								__start = (0, _time2.default)(__start).subtract(7, 'days').valueOf();
							}
							start = __start;
						}
					} else if (type === 'months') {
						// for months use startof month for proper month alignment
						start = (0, _time2.default)(start).startOf('month').valueOf();
					}

					if (direction === -1) {
						start = (0, _time2.default)(start).subtract(span, type).valueOf();
					} else if (direction === 1) {
						start = (0, _time2.default)(start).add(span, type).valueOf();
					} else {
						start = (0, _time2.default)(start).startOf('day').valueOf();
					}

					start = start.valueOf();
					end = (0, _time2.default)(start).add(span, type).subtract(1, 'days').endOf('day').valueOf();
				}
			}
			return { start: start, end: end };
		},
		changeInterval: function changeInterval() {
			var _this = this;

			var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

			var intervalWait = Ember.get(this, '__intervalWait');
			if (!Ember.isNone(intervalWait)) {
				clearTimeout(intervalWait);
				intervalWait = null;
			}

			var _getInterval3 = this.getInterval(direction),
			    start = _getInterval3.start,
			    end = _getInterval3.end;

			if (!Ember.isNone(start) && !Ember.isNone(end)) {
				setStart(this, start);
				setEnd(this, end);
				intervalWait = setTimeout(function () {
					_this.triggerDateChange();
				}, 500);
			}
			Ember.set(this, '__intervalWait', intervalWait);
		},


		/**
   * sets the state object for
   * the date-picker component to get date information
   *
   * @public
   * @method setState
   */
		setState: function setState() {
			var start = getStart(this);
			var end = getEnd(this);

			var timestamp = start;
			var calendarDate = Ember.get(this, 'calendarDate');
			var minDate = Ember.get(this, '_min');
			var maxDate = Ember.get(this, '_max');
			var format = Ember.get(this, 'format');

			if (!Ember.get(this, 'isStart')) {
				timestamp = end;
			}

			var startRange = (0, _time2.default)(start).startOf('day').valueOf();
			var endRange = (0, _time2.default)(end).startOf('day').valueOf();

			if (Ember.isNone(Ember.get(this, 'stateManager'))) {
				Ember.set(this, 'stateManager', (0, _state2.default)({
					isOpen: true, // isOpen should always be true
					timestamp: timestamp, calendarDate: calendarDate,
					minDate: minDate, maxDate: maxDate,
					format: format,
					range: [startRange, endRange]
				}));
			} else {
				Ember.set(this, 'stateManager.timestamp', timestamp);
				Ember.set(this, 'stateManager.calendarDate', calendarDate);
				Ember.set(this, 'stateManager.minDate', minDate);
				Ember.set(this, 'stateManager.maxDate', maxDate);
				Ember.set(this, 'stateManager.format', format);
				Ember.set(this, 'stateManager.range', [startRange, endRange]);
			}
		},


		/**
   * triggeres a date change event to send off
   * to listeners of `onChange`
   *
   * @public
   * @method triggerDateChange
   */
		triggerDateChange: function triggerDateChange() {
			var start = getUserStart(this);
			var end = getUserEnd(this);

			this.setState();

			Ember.set(this, 'changeFired', true);

			var selectedType = Ember.get(this, 'isCustom') ? 'custom' : Ember.get(Ember.get(this, '__actionList').findBy('selected', true) || { id: 'weekly' }, 'id');
			this.sendAction('onChange', start, end, Ember.get(this, 'isCustom'), selectedType);
		},


		/**
   * sets the focus to on of the inputs based on the boolean passed in.
   *
   * true sets focus to the start time input
   *
   * @public
   * @method focusActive
   * @params isStart {boolean}
   */
		focusActive: function focusActive() {
			var selection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

			var isStart = Ember.get(this, 'isStart');
			Ember.set(this, '__saveFocus', { isStart: isStart, selection: selection });

			var input = isStart ? 'start' : 'end';
			var el = this.$('.state.' + input + ' > input');
			if (el && el.data) {
				el.data('selection', selection);
				el.data('position', 0);

				Ember.run.next(function () {
					return el.focus();
				});
			}
		},


		/**
   * Update the start or end time date where the date will also set the other
   * if it is incalid
   *
   * @public
   * @method updateDates
   * @params type {string} the type of setter day or month
   * @params timestamp {number} milliseconds timestamp
   * @params calendar {number} milliseconds timestamp
   * @params singleSet {boolean} flag to only set start or end time unless a special case exists. This is for keyboards inputs
   */
		updateDates: function updateDates(type, time, calendar) {
			var singleSet = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

			var isStart = Ember.get(this, 'isStart');
			if (!Ember.get(this, 'allowCustom')) {
				isStart = true;
			}

			if (type === _constant.DAY_FLAG) {
				if (!singleSet && !isStart && time < getStart(this)) {
					isStart = true;
				}

				// set the start or end time
				// based off the current active state
				if (isStart) {
					setStart(this, time);
				} else {
					setEnd(this, time);
				}

				if (!singleSet && isStart) {
					// if active state is the start and its
					// not a singleSet mode then set the end as well
					setEnd(this, time);
				} else if (isStart && time > getEnd(this)) {
					// if active state is start and the time
					// is greater than the end then set the end time
					setEnd(this, time);
				} else if (!isStart && time < getStart(this)) {
					// if active state is end and the time
					// is less than the start then set the start time
					setStart(this, time);
				}

				// always set the calendar for start times and all
				// single set times
				if (isStart || singleSet) {
					Ember.set(this, 'calendarDate', time);
				}

				// set the active state
				this.setActiveState(Ember.get(this, 'isStart'));

				// update the dates for the calendar
				this.setState();
			} else {
				Ember.set(this, 'calendarDate', calendar);
			}
			return isStart;
		},


		/**
   * set the select menu to the selected type by id
   *
   * @public
   * @method setSelected
   * @params id {string} the id of the menu type to set as the selected menu item
   * @return {object} the selected item
   */
		setSelected: function setSelected(id) {
			// reset selected list
			Ember.get(this, '__actionList').forEach(function (item) {
				return Ember.set(item, 'selected', false);
			});

			var selected = void 0;
			if (Ember.isNone(id)) {
				selected = this.getDefaultAction();
			} else if (id === 'custom') {
				Ember.set(this, 'isCustom', true);
				var span = _time2.default.daysApart(getStart(this), getEnd(this)) + 1;
				selected = Ember.Object.create({ id: 'custom', name: Ember.String.loc('Custom'), span: span, type: 'days' });
			} else {
				Ember.set(this, 'isCustom', false);
				selected = Ember.get(this, '__actionList').findBy('id', id);
				if (Ember.get(this, 'defaultDateOnChange')) {
					if (Ember.get(this, 'selected.id') !== Ember.get(selected, 'id')) {
						setStart(this, (0, _time2.default)().startOf('day').valueOf());
						setEnd(this, (0, _time2.default)().endOf('day').valueOf());
					}
				}
			}

			Ember.set(selected, 'selected', true);
			Ember.set(this, 'selected', selected);
			return selected;
		},


		/**
   * Save the current state of the select menu and
   * start end dates
   *
   * @public
   * @method saveState
   */
		saveState: function saveState() {
			var id = Ember.get(this, 'selected.id');
			Ember.set(this, '__saveState', {
				isCustom: id === 'custom',
				selectedId: id,
				start: getStart(this),
				end: getEnd(this)
			});
		},


		/**
   * restore the state to the previous state of the select menu
   * and start end dates
   *
   * @public
   * @method restoreState
   */
		restoreState: function restoreState() {
			if (!Ember.isNone(Ember.get(this, '__saveState'))) {
				setStart(this, Ember.get(this, '__saveState.start'));
				setEnd(this, Ember.get(this, '__saveState.end'));
				this.setSelected(Ember.get(this, '__saveState.selectedId'));
			}
		},
		closeMenu: function closeMenu() {
			var focus = this.$('.date-range-picker-dropdown > .focus');
			if (focus.length) {
				focus.removeClass('focus');
			}
			Ember.set(this, 'isListOpen', false);
		},
		openMenu: function openMenu() {
			Ember.set(this, 'isListOpen', true);
			this.focusActive();
		},
		click: function click(event) {
			if (Ember.get(this, 'isListOpen')) {
				var el = Ember.$(event.target);
				if (el.parents('.date-range-picker-menu').length || el.hasClass('date-range-picker-menu')) {
					var focus = Ember.get(this, '__saveFocus');
					this.setActiveState(focus.isStart);
					this.focusActive(focus.selected);
				}
			}
		},


		actions: {
			intervalBack: function intervalBack() {
				if (!Ember.get(this, 'disablePrev')) {
					this.changeInterval(-1);
				}
			},
			intervalForward: function intervalForward() {
				if (!Ember.get(this, 'disableNext')) {
					this.changeInterval(1);
				}
			},
			toggleList: function toggleList() {
				if (!Ember.get(this, 'isListOpen')) {
					this.openMenu();
				} else {
					this.closeMenu();
				}
			},
			setFocus: function setFocus(val, event) {
				var index = event.target.selectionStart;
				if (val === 'start') {
					this.setActiveState(true);
					Ember.set(this, '__saveFocus', { isStart: true, selected: index });
				} else if (val === 'end') {
					this.setActiveState(false);
					Ember.set(this, '__saveFocus', { isStart: false, selected: index });
				}
			},
			selectItem: function selectItem(id) {
				if (!Ember.get(this, 'selected') !== id) {
					this.saveState();
					this.setSelected(id);
				}
				this.closeMenu();
				this.changeInterval();
			},
			selectCustom: function selectCustom() {
				var _this2 = this;

				this.saveState();
				this.setSelected('custom');
				Ember.run.later(function () {
					_this2.focusActive();
				}, 100);
			},
			applyRange: function applyRange() {
				this.saveState();
				if (Ember.get(this, 'allowCustom')) {
					this.setSelected('custom');
				}
				this.closeMenu();
				this.setActiveState(true);
				this.changeInterval();
			},
			cancelRange: function cancelRange() {
				this.restoreState();
				this.closeMenu();
				this.setActiveState(true);
			},
			dateChange: function dateChange(time) {
				this.updateDates(_constant.DAY_FLAG, time, null, true);
			},
			updateTime: function updateTime(state, time) {
				var isStart = this.updateDates(_constant.DAY_FLAG, time);
				this.setActiveState(!isStart);

				// resets the focus after the user clicks a day
				this.focusActive(Ember.get(this, '__dayIndex'));
			},
			tabAction: function tabAction() {
				this.setActiveState(!Ember.get(this, 'isStart'));
				// change focus to next input
				this.focusActive();
			},


			/**
    * update the clicked value for days and months
    * and set the focus back to the input when done
    *
    */
			calendarChange: function calendarChange(type, time, calendar) {
				var isStart = void 0;
				if (type === _constant.DAY_FLAG) {
					isStart = this.updateDates(type, time, calendar);
					this.setActiveState(!isStart);
					this.focusActive(Ember.get(this, '__dayIndex'));
				} else {
					this.updateDates(type, time, calendar);
					this.focusActive(Ember.get(this, '__monthIndex'));
				}
			}
		}
	});


	function getUserStart(target) {
		var time = getStart(target);
		if (Ember.get(target, 'utc')) {
			time = _time2.default.utcFromLocal(time).timestamp();
		}

		if (Ember.get(target, '_isUnix')) {
			time = (0, _time2.default)(time).unix();
		}
		return time;
	}

	function getUserEnd(target) {
		var time = getEnd(target);
		if (Ember.get(target, 'utc')) {
			time = _time2.default.utcFromLocal(time).timestamp();
		}

		if (Ember.get(target, '_isUnix')) {
			time = (0, _time2.default)(time).unix();
		}
		return time;
	}

	function setUserStart(target, time) {
		if (Ember.get(target, '_isUnix')) {
			time = _time2.default.unix(time).timestamp();
		}

		if (Ember.get(target, 'utc')) {
			time = _time2.default.utcToLocal(time).timestamp();
		}
		return time;
	}

	function setUserEnd(target, time) {
		if (Ember.get(target, '_isUnix')) {
			time = _time2.default.unix(time).timestamp();
		}

		if (Ember.get(target, 'utc')) {
			time = _time2.default.utcToLocal(time).timestamp();
		}
		return time;
	}

	function getStart(target) {
		return Ember.get(target, '_start');
	}

	function getEnd(target) {
		return Ember.get(target, '_end');
	}

	function setStart(target, time) {
		time = (0, _time2.default)(time).startOf('day').valueOf();
		if (getStart(target) !== time) {
			Ember.set(target, '_start', time);
		}
	}

	function setEnd(target, time) {
		time = (0, _time2.default)(time).endOf('day').valueOf();
		if (getEnd(target) !== time) {
			Ember.set(target, '_end', time);
		}
	}

	function findAction(target, key) {
		var actions = Ember.get(target, '__actionList').map(function (i) {
			var id = i.id;
			var regx = new RegExp('^' + id.charAt(0).toLowerCase() + '$');
			return { id: id, regx: regx };
		});
		actions.push({ id: 'custom', regx: /^C$/ });
		var res = actions.find(function (i) {
			return i.regx.test(key);
		});
		if (Ember.isNone(res)) {
			res = { id: null, regx: null };
		}
		return res;
	}

	function handleAltKeys(target, keyName, isOpen) {
		if (keyName === '/') {
			target.send('toggleList');
		} else {
			var selectedId = Ember.get(target, 'selected.id');

			var _findAction = findAction(target, keyName),
			    id = _findAction.id;

			if (!Ember.isNone(id)) {
				if (selectedId !== id) {
					if (id === 'custom') {
						if (!isOpen) {
							target.send('selectCustom');
						}
						target.send('toggleList');
					} else {
						target.send('selectItem', id);
					}
				}
			}
		}
	}

	function keyDownEventHandler(target) {
		return function (event) {
			var isOpen = Ember.get(target, 'isListOpen');
			var handler = (0, _keyEvent.default)({ event: event });
			if (event.altKey) {
				if (handler.type === 'letter' || handler.type === 'math') {
					handleAltKeys(target, handler.keyName, isOpen);
				} else if (handler.keyName === 'ArrowLeft') {
					if (!isOpen) {
						target.send('intervalBack');
					}
				} else if (handler.keyName === 'ArrowRight') {
					if (!isOpen) {
						target.send('intervalForward');
					}
				}
			} else {
				if (handler.keyName === 'Tab') {
					if (isOpen) {
						target.send('tabAction', event);
					}
				} else if (handler.keyName === 'Escape') {
					if (isOpen) {
						target.send('toggleList');
					}
				} else if (handler.keyName === 'ArrowDown') {
					if (isOpen) {
						nextListItem(target);
					}
				} else if (handler.keyName === 'ArrowUp') {
					if (isOpen) {
						prevListItem(target);
					}
				} else if (handler.keyName === 'Enter') {
					if (isOpen) {
						var selected = target.$('.date-range-picker-dropdown > .focus');
						if (selected.length) {
							selected.click();
						}
					}
				}
			}
			return true;
		};
	}

	function nextListItem(target) {
		var element = target.$('.date-range-picker-dropdown');
		var active = element.find('.focus');
		if (!active.length) {
			active = element.find('.active');
		}

		var next = active.next('.item');
		if (!next.length) {
			next = element.children().first();
		}
		next.addClass('focus');
		active.removeClass('focus');
	}

	function prevListItem(target) {
		var element = target.$('.date-range-picker-dropdown');
		var active = element.find('.focus');
		if (!active.length) {
			active = element.find('.active');
		}

		var next = active.prev('.item');
		if (!next.length) {
			next = element.children('.item').last();
		}
		next.addClass('focus');
		active.removeClass('focus');
	}

	function clickEventHandler(target) {
		return function (evt) {
			var el = Ember.$(evt.target);
			var isOpen = Ember.get(target, 'isListOpen');
			if (isOpen) {
				if (el.parents('.c-date-range-picker').length) {
					// is in date picker
					if (!el.parents('.date-range-picker-dropdown').length && !el.hasClass('select') && !el.hasClass('date-range-picker-dropdown')) {
						target.send('toggleList');
					}
				} else {
					target.send('toggleList');
				}
			}
		};
	}
});