define('@busy-web/ember-date-time/utils/clock/movement', ['exports', '@busy-web/ember-date-time/utils/clock/math'], function (exports, _math) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.onMoveStart = onMoveStart;
	exports.onMove = onMove;
	exports.onMoveStop = onMoveStop;


	/**
 	* allows for the hours group to start being dragged
 	*/
	/**
 * @module Utils/Clock
 *
 */
	function onMoveStart(textNode, removeClass, appendTo) {
		var cb = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};

		return function () {
			this.data('origTransform', this.transform().local);

			if (!Ember.isNone(textNode)) {
				textNode.remove();
				textNode.appendTo(appendTo);
				textNode.removeClass(removeClass);
			}
			cb();
		};
	}

	/**
 	* moves the dial on the hour clock while transforming group
 	*/
	function onMove(angleStart, cx, cy, x, y) {
		var cb = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : function () {};

		return function (dx, dy) {
			var nx = x + dx;
			var ny = y + dy;

			// get angle of line from center x, y to new nx, ny
			var angleEnd = (0, _math.angleOfLine)(nx, ny, cx, cy);

			// get the rotational direction from the startAngle to the new endAngle
			var direction = (0, _math.getDirection)(angleStart, angleEnd);

			// add SnapSVG transform for movement
			this.attr({ transform: 'r' + direction + ', ' + cx + ', ' + cy });
			cb(angleEnd);
		};
	}

	/**
 	* checks to see where the dragging stops and makes the closest hour active
 	*/
	function onMoveStop(target) {
		var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

		return function (evt) {
			cb(target, evt);
		};
	}
});