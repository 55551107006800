define('@busy-web/ember-date-time/--private/handlers/user-interface', ['exports', '@busy-web/ember-date-time/--private/handlers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		__handler: 'date',

		active: _base.default.property(),
		open: _base.default.property(false),
		top: _base.default.property(false)
	});
});