define('@busy-web/ember-date-time/--private/computed/handler', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = handler;
	/**
  * @module Core/computed
  *
  */
	function handler(handlerClass) {
		var instance = handlerClass.create();
		return Ember.computed(function () {
			if (Ember.isNone(Ember.get(instance, '__manager'))) {
				Ember.set(instance, '__manager', this);
			}
			return instance;
		}).meta({ type: 'handler' });
	}
});