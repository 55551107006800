define('@busy-web/components/components/bc-loading', ['exports', '@busy-web/components/templates/components/bc-loading'], function (exports, _bcLoading) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _bcLoading.default,
		classNames: ['bc-loading'],
		classNameBindings: ['isLoading:active', 'minimal', 'fixed', 'inline'],
		isLoading: false,
		minimal: false,
		fixed: true,
		loadingMessage: ''
	});
});