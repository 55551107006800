define('ember-google-maps/services/google-maps-api', ['exports', 'ember-google-maps/utils/helpers', 'ember-google-maps/utils/runloopify-google-maps'], function (exports, _helpers, _runloopifyGoogleMaps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @method google
     * @readOnly
     * @return {Ember.ObjectProxy}
     */
    google: (0, _helpers.computedPromise)(function () {
      return this._loadMapsAPI();
    }).readOnly(),

    /**
     * @method directionsService
     * @readOnly
     * @return {Ember.ObjectProxy}
     */
    directionsService: (0, _helpers.computedPromise)(function () {
      return Ember.get(this, 'google').then(function () {
        return new google.maps.DirectionsService();
      });
    }).readOnly(),

    /**
     * Return or load the Google Maps API.
     *
     * @method _loadMapsAPI
     * @private
     * @return {RSVP.Promise}
     */
    _loadMapsAPI: function _loadMapsAPI() {
      if (typeof document === 'undefined') {
        return Ember.RSVP.reject();
      }

      var google = window.google;
      if (google && google.maps) {
        return Ember.RSVP.resolve(google);
      }

      // Pre-built url set to environment variable.
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      var src = ENV['ember-google-maps']['src'];

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.initGoogleMap = Ember.run.bind(function () {
          (0, _runloopifyGoogleMaps.default)();
          resolve(window.google);
        });

        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.onerror = function (error) {
          return reject(error);
        };
        // Insert into dom (to avoid cors problems)
        document.body.appendChild(s);

        // Load map
        s.src = src + '&callback=initGoogleMap';
      });
    }
  });
});