define('@busy-web/utils/locale', ['exports', '@busy-web/utils/assert'], function (exports, _assert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /***/

  /**
   * Locales list for spefic regions.
   *
   * @private
   * @property __locales
   * @type Object
   */
  var __locales = {
    'es': {
      'h:mm A': 'h:mm a',
      'MMM D': 'll',
      'MMM DD': 'll',
      'MMM. DD, h:mm A': 'll h:mm a',
      'MMM D YYYY': 'll',
      'MMM D, YYYY': 'll',
      'h:mm A M/D/YY': 'LT l',
      'h:mm A MM/DD/YY': 'LT l',
      'MMM Do h:mm A': 'll h:hh a',
      'MMM Do, YYYY': 'll',
      'MMMM Do YYYY': 'LL',
      'MMMM Do YYYY, h:mm A': 'LL, LT',
      'ddd, MMM Do': 'ddd, ll',
      'ddd, MMM DD': 'ddd, ll',
      'ddd, MMM D, YYYY': 'ddd, ll',
      'ddd, MMM Do, YYYY': 'ddd, ll',
      'ddd. MMM Do, YYYY': 'ddd, ll',
      'ddd. MMM Do h:mm A': 'll h:mm a',
      'MMMM D, YYYY @h:mm A': 'LL @h:mm a',
      'ddd. MMM Do YYYY h:mm A': 'ddd, ll h:mm a'
    }
  };

  /**
   * `Util/Locale`
   *
   * @class Locale
   * @namespace BusyUtils
   * @extends Ember.Object
   */
  /**
   * @module Utils
   *
   */
  var Locale = Ember.Object.extend();
  Locale.reopenClass({
    format: function format(str) {
      var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'en';

      _assert.default.funcNumArgs(arguments, 2);
      _assert.default.isString(str);
      _assert.default.isString(locale);

      var _formats = Ember.get(__locales, locale);
      if (!Ember.isNone(_formats)) {
        var format = Ember.get(_formats, str);
        if (!Ember.isNone(format)) {
          return format;
        }
      }
      return str;
    }
  });

  exports.default = Locale;
});