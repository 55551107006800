define('@busy-web/components/services/escape-handler', ['exports', '@busy-web/components/utils/event'], function (exports, _event) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Service
  *
  */
	var __propagation = Ember.A();

	exports.default = Ember.Service.extend({
		init: function init() {
			this._super();

			(0, _event.bind)(document, 'keyup', 'service-escape-handler', function (evt) {
				if (evt.keyCode === 27) {
					var res = dispatchEvent.call(this, evt);
					if (res === false) {
						evt.stopPropagation();
						return false;
					}
					return true;
				}
			}, { capture: true, rebind: true });
		},
		addListener: function addListener(target, id) {
			var eventClass = __propagation.findBy('id', id);
			(false && !(Ember.isNone(eventClass)) && Ember.assert("event has been added already, you must remove the event first", Ember.isNone(eventClass)));


			eventClass = EventClass.create({ id: id, target: target });
			__propagation.push(eventClass);
		},
		removeListener: function removeListener(id) {
			var eventClass = __propagation.findBy('id', id);
			if (!Ember.isNone(eventClass)) {
				var index = __propagation.indexOf(eventClass);
				__propagation.splice(index, 1);
			}
		}
	});


	var EventClass = Ember.Object.extend({
		id: null,
		target: null,

		__debugName: Ember.computed('target.{elementId,classNames}', function () {
			var elementId = Ember.get(this, 'target.elementId');
			var view = Ember.get(this, 'target.classNames')[0] || '';
			return (elementId + '_' + view).replace(/_$/, '');
		})
	});

	function dispatchEvent(evt, index) {
		if (Ember.isNone(index)) {
			index = Ember.get(__propagation, 'length') - 1;
		}

		if (index < 0) {
			// || evt.isPropagationStopped()) {
			return false;
		}

		var eventClass = __propagation[index];

		// call event handler onEscape
		var res = Ember.get(eventClass, 'target').onEscape(evt);
		if (res === false) {
			return false;
		}

		return dispatchEvent(evt, index - 1);
	}
});