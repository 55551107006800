define('@busy-web/ember-date-time/components/private/date-input', ['exports', '@busy-web/ember-date-time/utils/key-event', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/utils/event', '@busy-web/ember-date-time/utils/format', '@busy-web/ember-date-time/utils/constant'], function (exports, _keyEvent, _time2, _event, _format, _constant) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.TextField.extend({
		classNames: ['busyweb', 'emberdatetime', 'p-date-input'],
		classNameBindings: ['isDateInRange::invalid', 'active'],

		type: 'text',

		timestamp: null,
		min: null,
		max: null,

		format: 'MM/DD/YYYY',

		_date: null,
		//value: '',
		active: false,

		name: null,
		selection: 0,
		position: 0,
		defaultFocus: 0,

		isDateInRange: Ember.computed('value', function () {
			// before render the position has not been set so
			// resume calculations as if position were 0
			var position = 0;
			if (this.$() && this.$().length && !Ember.isEmpty(getData(this.$(), 'format'))) {
				var meta = getMeta(this);
				position = meta.position;
			}

			// when value is null or lastNumIndex is null or lastNumIndex is not 0 then it is in a temp state when invalid is ignored
			if (Ember.isNone(getValue(this)) || Ember.isNone(position) || position !== 0) {
				return true;
			}

			// get the current value as a date object
			var date = getDate(this);

			// date must be valid and within range to be a valid date range
			if (date.isValid() && this.isDateInBounds(date)) {
				return true;
			}
			return false;
		}),

		/**
   * initialize the value before the component
   * is rendered.
   *
   */
		init: function init() {
			this._super.apply(this, arguments);

			var date = void 0;
			if (!Ember.isNone(Ember.get(this, 'timestamp'))) {
				date = (0, _time2.default)(Ember.get(this, 'timestamp'));
			} else {
				date = (0, _time2.default)();
			}

			setValue(this, date.format(Ember.get(this, 'format')));
			Ember.set(this, '_date', date.timestamp());
			Ember.set(this, '_dateRef', date.timestamp());
		},


		/**
   * initialize the DOM object after render has
   * finished.
   *
   */
		didInsertElement: function didInsertElement() {
			var _this = this;

			for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
				args[_key] = arguments[_key];
			}

			this._super.apply(this, _toConsumableArray(args));

			var target = this.$().get(0);

			(0, _event.bind)(target, 'click', 'ember-date-time.date-input.' + this.get('elementId'), function () {
				return _this.clickEvent.apply(_this, arguments);
			}, { capture: true, rebind: true });

			(0, _event.bind)(target, 'focusin', 'ember-date-time.date-input.' + this.get('elementId'), function () {
				return _this.focusInEvent.apply(_this, arguments);
			}, { capture: true, rebind: true });

			(0, _event.bind)(target, 'focusout', 'ember-date-time.date-input.' + this.get('elementId'), function () {
				return _this.focusOutEvent.apply(_this, arguments);
			}, { capture: true, rebind: true });

			var format = Ember.get(this, 'format');
			format = (0, _format.longFormatDate)(format);

			setData(this.$(), 'format', format);
			setData(this.$(), 'selection', 0);
			setData(this.$(), 'position', 0);
			setData(this.$(), 'linked', null);

			Ember.set(this, 'keyboard', getKeyboardStyle());
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			var target = this.$().get(0);

			(0, _event.unbind)(target, 'click', 'ember-date-time.date-input.' + this.get('elementId'));
			(0, _event.unbind)(target, 'focusin', 'ember-date-time.date-input.' + this.get('elementId'));
			(0, _event.unbind)(target, 'focusout', 'ember-date-time.date-input.' + this.get('elementId'));
		},


		timestampChange: Ember.observer('timestamp', function () {
			var date = (0, _time2.default)(Ember.get(this, 'timestamp'));
			setValue(this, date.format(Ember.get(this, 'format')));
			Ember.set(this, '_date', date.timestamp());
			Ember.set(this, '_dateRef', date.timestamp());
		}),

		isValid: function isValid(date) {
			if (date.isValid()) {
				return true;
			}
			return false;
		},
		isDateInBounds: function isDateInBounds(date) {
			var max = Ember.get(this, 'max');
			var min = Ember.get(this, 'min');
			if (Ember.isNone(max) && Ember.isNone(min)) {
				// no min or max date
				return true;
			} else if (date.timestamp() >= min && Ember.isNone(max)) {
				// date is greater than min date and no max date
				return true;
			} else if (Ember.isNone(min) && date.timestamp() <= max) {
				// no min date and date is less than max date
				return true;
			} else if (date.timestamp() >= min && date.timestamp() <= max) {
				// date is greater than min date and date is less than max date
				return true;
			}
			return false;
		},
		submitChange: function submitChange(value) {
			if (this.isValid(value)) {
				var val = value.timestamp();
				Ember.set(this, '_date', val);
				this.sendAction('onchange', val);
				return true;
			}
			return false;
		},
		finalizeDateSection: function finalizeDateSection() {
			var date = getInBoundsDate(getDate(this), Ember.get(this, 'min'), Ember.get(this, 'max'));
			var selectRound = Ember.getWithDefault(this, 'selectRound', 1);
			if (selectRound % date.minute() !== 0) {
				date = (0, _time2.default)(_time2.default.round(date.timestamp(), selectRound));
				setValue(this, date.format(Ember.get(this, 'format')));
			}

			if (date.isValid()) {
				if (Ember.get(this, '_date') !== date.timestamp()) {
					this.submitChange(date);
				}
				setData(this.$(), 'position', 0);
				return true;
			}
			return false;
		},
		fixDate: function fixDate() {
			var _getMeta = getMeta(this),
			    start = _getMeta.start,
			    end = _getMeta.end;

			var _sectionBounds = sectionBounds(this),
			    min = _sectionBounds.min,
			    max = _sectionBounds.max;

			var valueSection = getValue(this).substring(start, end);

			if (parseInt(valueSection, 10) < min) {
				valueSection = ('0000000' + min).substr(-('' + max).length);
			} else if (parseInt(valueSection, 10) > max) {
				valueSection = '' + max;
			}
			var newVal = mergeString(getValue(this), valueSection, start, end);

			setValue(this, newVal);
		},
		setDateSection: function setDateSection(valueSection, position) {
			var _getMeta2 = getMeta(this),
			    sectionFormat = _getMeta2.sectionFormat,
			    start = _getMeta2.start,
			    end = _getMeta2.end;

			var _sectionBounds2 = sectionBounds(this),
			    min = _sectionBounds2.min,
			    max = _sectionBounds2.max;

			// moves the start ahead on position when the valueSection
			// is intended for a format section that has less position
			// than the max numbers positions.


			if (sectionFormat.length < ('' + max).length) {
				if (position > sectionFormat.length) {
					start += 1;
				}
			}

			// merge the date string section into the current date string
			var newVal = mergeString(getValue(this), valueSection, start, end);

			setValue(this, newVal);

			// set new number position and handle cursor selection
			var num = parseInt(valueSection, 10);
			if (position >= ('' + max).length || num > min && num * 10 > max) {
				// create a new date object
				this.fixDate();
				this.finalizeDateSection();
				moveToNextPosition(this);
			} else {
				setData(this.$(), 'position', position);
				handleCursor(this, '');
			}
		},
		handleNumberKeys: function handleNumberKeys(event, handler) {
			var _getMeta3 = getMeta(this),
			    sectionFormat = _getMeta3.sectionFormat,
			    position = _getMeta3.position;

			if (!/^(D(o|D))|(M(o|M))|(Y{1,4})|(hh?)|(HH?)|(mm?)|(ss?)$/.test(sectionFormat)) {
				return handler.preventDefault();
			}

			var valueSection = createSection(this, handler.keyName, position, false);
			position = position + 1;

			this.setDateSection(valueSection, position);
			return handler.preventDefault();
		},
		handleLetterKeys: function handleLetterKeys(event, handler) {
			var _getMeta4 = getMeta(this),
			    sectionFormat = _getMeta4.sectionFormat;

			if (!/^(MMMM?|A|a)$/.test(sectionFormat)) {
				return handler.preventDefault();
			}

			if (/^[aA]$/.test(sectionFormat)) {
				meridianLetters(this, handler.keyName);
			} else {
				monthLetters(this, handler.keyName);
			}

			return handler.preventDefault();
		},
		handleDeleteKey: function handleDeleteKey(event, handler) {
			var _this2 = this;

			var _getMeta5 = getMeta(this),
			    position = _getMeta5.position;

			if (position > 0) {
				var valueSection = createSection(this, 0, position, true);
				position = position - 1;
				this.setDateSection(valueSection, position);
			} else {
				moveToPrevPosition(this).then(function (result) {
					if (result.isNewInput) {
						var e = Ember.$.Event('keydown', { which: event.which });
						result.input.trigger(e);
					} else {
						_this2.handleDeleteKey(event, handler);
					}
				});
			}
			return handler.preventDefault();
		},
		upDownArrows: function upDownArrows(keyName) {
			var _getMeta6 = getMeta(this),
			    sectionFormat = _getMeta6.sectionFormat,
			    start = _getMeta6.start,
			    end = _getMeta6.end;

			var type = sectionFormatType(this);
			var _date = Ember.get(this, '_date');

			var isUp = ['ArrowUp', '=', '+'].indexOf(keyName) !== -1;
			var isDown = ['ArrowDown', '-', '_'].indexOf(keyName) !== -1;

			// for am pm up down arrows increase the time or decrease
			// the time accordingly so that the day does not change.
			// So hitting up arrow 2 times will change from 'am' => 'pm' => 'am'
			// and the actual date has not changed.
			if (type === _constant.MERIDIAN_FLAG) {
				var substr = getValue(this).substring(start, end);
				if (isUp && substr.toLowerCase() === 'pm') {
					isUp = false;
					isDown = true;
				} else if (isDown && substr.toLowerCase() === 'am') {
					isDown = false;
					isUp = true;
				}
			} else if (/^mm?/.test(type)) {
				if (Ember.getWithDefault(this, 'selectRound', 1) > 1) {
					if (isUp) {
						_date = (0, _time2.default)(_date).add(Ember.get(this, 'selectRound') - 1, 'minutes').timestamp();
					} else {
						_date = (0, _time2.default)(_date).subtract(Ember.get(this, 'selectRound') + 1, 'minutes').timestamp();
					}
				}
			}

			var val = void 0;
			if (isUp) {
				val = (0, _time2.default)(_date).addFormatted(1, sectionFormat);
			} else if (isDown) {
				val = (0, _time2.default)(_date).subFormatted(1, sectionFormat);
			}

			var bounds = _time2.default.isDateInBounds(val, Ember.get(this, 'min'), Ember.get(this, 'max'));
			if (bounds.isAfter || bounds.isBefore) {
				if (/^M(M|o)?$/.test(sectionFormat)) {
					if (isUp && !_time2.default.isDateAfter((0, _time2.default)(val).startOf('month'), Ember.get(this, 'max'))) {
						val = val.date((0, _time2.default)(Ember.get(this, 'max')).date());
						bounds = _time2.default.isDateInBounds(val, Ember.get(this, 'min'), Ember.get(this, 'max'));
					} else if (!isUp && !_time2.default.isDateBefore((0, _time2.default)(val).endOf('month'))) {
						val = val.date((0, _time2.default)(Ember.get(this, 'min')).date());
						bounds = _time2.default.isDateInBounds(val, Ember.get(this, 'min'), Ember.get(this, 'max'));
					}
				} else if (/^Y{1,4}$/.test(sectionFormat)) {
					if (isUp && !_time2.default.isDateAfter((0, _time2.default)(val).startOf('year'), Ember.get(this, 'max'))) {
						val = val.date((0, _time2.default)(Ember.get(this, 'max')).date());
						bounds = _time2.default.isDateInBounds(val, Ember.get(this, 'min'), Ember.get(this, 'max'));
					} else if (!isUp && !_time2.default.isDateBefore((0, _time2.default)(val).endOf('year'))) {
						val = val.date((0, _time2.default)(Ember.get(this, 'min')).date());
						bounds = _time2.default.isDateInBounds(val, Ember.get(this, 'min'), Ember.get(this, 'max'));
					}
				}
			}

			if (val) {
				if (!bounds.isBefore && !bounds.isAfter) {
					var newVal = val.format(Ember.get(this, 'format'));
					fixSelection(this, newVal);
					setValue(this, newVal);
					handleCursor(this, '');
				} else {
					flashWarn(this);
				}
			}
		},
		handleArrowKeys: function handleArrowKeys(event, handler) {
			if (/^[*/><^]$/.test(handler.keyName)) {
				return handler.preventDefault();
			}

			if (handler.keyName === 'ArrowLeft') {
				handleCursor(this, 'prev');
			} else if (handler.keyName === 'ArrowRight') {
				handleCursor(this, 'next');
			} else {
				this.upDownArrows(handler.keyName);
			}

			this.finalizeDateSection();
			return handler.preventDefault();
		},
		focusInEvent: function focusInEvent(event) {
			//let data = this.$().data();

			// prevent open picker from selecting the wrong section
			//if (!this.$().is(':active') || get(data, 'forceSelection') === true) {
			this.$().data('forceSelection', false);

			var _getMeta7 = getMeta(this, this.$()),
			    selection = _getMeta7.selection,
			    position = _getMeta7.position;

			handleFocus(this, selection, position, true);
			//}

			var type = sectionFormatType(this);
			this.sendAction('onfocus', event, type);

			var os = getOSType();
			if (os === 'iOS') {
				if (!getData(this.$(), 'overrideFocus')) {
					this.$().blur();
				} else {
					setData(this.$(), 'overrideFocus', false);
				}
			} else if (os === 'Android') {
				this.$().blur();
			}
		},
		focusOutEvent: function focusOutEvent(event) {
			// check if the evt.target is local to this elements main parent
			var isLocal = (0, _event.isEventLocal)(event, Ember.get(this, 'elementId'), '.emberdatetime');
			if (!isLocal) {
				this.set('active', false);
				this.sendAction('onblur', event);
			}
		},
		clickEvent: function clickEvent(event) {
			var _this3 = this;

			event.stopPropagation();
			var index = event.target.selectionStart;

			Ember.set(this, '__lastType', null);
			handleFocus(this, index, 0);

			var type = sectionFormatType(this);
			this.sendAction('onclick', event, index, type);

			// hand mobile focus
			var os = getOSType();
			if (os === 'iOS' || os === 'Android') {
				setData(this.$(), 'overrideFocus', true);
				Ember.run.later(function () {
					return _this3.$().focus();
				}, 10);
			}
		},
		dragStart: function dragStart() {
			// store the current value so it can be fixed after the
			// drag is complete
			Ember.set(this, '__saveDragValue', getValue(this));
		},
		dragEnd: function dragEnd() {
			// if the drag has changed the value then
			// replace it with the old value so the date format
			// is not changed
			if (getValue(this) !== Ember.get(this, '__saveDragValue')) {
				setValue(this, Ember.get(this, '__saveDragValue'));
			}
		},
		keyDown: function keyDown(event) {
			if (isModifierKeyActive(this, event)) {
				return true;
			}

			var handler = (0, _keyEvent.default)({ event: event, disable: ['composition'] });
			if (handler.allowed) {
				if (!handler.throttle) {
					if (handler.type === 'arrow' || handler.type === 'math' || handler.keyName === '_') {
						return this.handleArrowKeys(event, handler);
					} else if (handler.type === 'number') {
						return this.handleNumberKeys(event, handler);
					} else if (handler.type === 'letter') {
						return this.handleLetterKeys(event, handler);
					} else if (handler.keyName === 'Backspace') {
						return this.handleDeleteKey(event, handler);
					} else if (handler.keyName === 'Tab') {
						if (this.get('allowTab')) {
							return this.sendAction('ontabkey', event, handler);
						} else {
							this.sendAction('ontabkey', event, handler);
						}
					} else if (handler.keyName === 'Enter') {
						this.sendAction('onsubmit', event, Ember.get(this, '_date'), handler);
					}
				}
			}
			return handler.preventDefault();
		}
	});


	function flashWarn(target) {
		var el = target.$().parents('.emberdatetime');
		var className = 'flash-warn';
		el.addClass(className);
		setTimeout(function () {
			return el.removeClass(className);
		}, 100);
	}

	function getOSType() {
		if (/(iPhone|iPad|iPod)/.test(window.navigator.userAgent)) {
			return 'iOS';
		} else if (/Android/.test(window.navigator.userAgent)) {
			return 'Android';
		}
	}

	function getDate(target) {
		var format = getData(target.$(), 'format');

		if (Ember.isEmpty(format)) {
			format = Ember.get(target, 'format');
			format = (0, _format.longFormatDate)(format);
		}

		var value = getValue(target);
		value = value.replace(/[ ]*$/, '');

		var _addFormat = addFormat(target, format, value);

		value = _addFormat.value;
		format = _addFormat.format;


		return (0, _time2.default)(value, format);
	}

	function addFormat(target, format, value) {
		var dRef = (0, _time2.default)(Ember.get(target, '_dateRef'));
		var stdTypes = _time2.default.standardFormatTypes();

		Object.keys(stdTypes).forEach(function (key) {
			var typeExp = new RegExp(stdTypes[key]);
			if (!typeExp.test(format)) {
				format = format + ' ' + key;
				value = value + ' ' + dRef.format(key);
			}
		});
		return { value: value, format: format };
	}

	function setValue(target, value) {
		Ember.set(target, 'value', value.replace(/[ ]*$/, '  '));
	}

	function getValue(target) {
		return Ember.get(target, 'value');
	}

	function isModifierKeyActive(target, event) {
		var keyboard = getKeyboardStyle();
		if (keyboard === 'MAC' && event.metaKey) {
			return true;
		} else if (keyboard === 'PC' && event.ctrlKey) {
			return true;
		} else if (event.altKey) {
			event.preventDefault();
			return true;
		}
		return false;
	}

	function getKeyboardStyle() {
		if (/Macintosh/.test(window.navigator.userAgent)) {
			return 'MAC';
		} else {
			return 'PC';
		}
	}

	function getMeta(target, el) {
		el = el || target.$();
		var format = getData(el, 'format');
		var selection = getData(el, 'selection', 0);
		var position = getData(el, 'position', 0);

		var start = void 0,
		    end = void 0,
		    sectionFormat = void 0;
		var value = getValue(target);
		sectionFormat = (0, _format.getFormatSection)(format, value, selection);
		var cursor = (0, _format.getCursorPosition)(format, value, selection);
		if (!Ember.isNone(cursor)) {
			start = cursor.start;
			end = cursor.end;
		}
		return { format: format, selection: selection, position: position, sectionFormat: sectionFormat, start: start, end: end };
	}

	function fixSelection(target, val) {
		var _getMeta8 = getMeta(target),
		    selection = _getMeta8.selection;

		var value = getValue(target).trim();
		var v1 = value.substr(selection - value.length);
		var v2 = val.substr(selection - val.length);
		if (v2.length > v1.length) {
			setData(target.$(), 'selection', selection + 1);
		}
	}

	function sectionBounds(target) {
		var type = sectionFormatType(target);
		var max = void 0,
		    min = 1;
		if (type === _constant.DAY_FLAG) {
			var date = (0, _time2.default)(Ember.get(target, '_date'));
			max = date.daysInMonth();
		} else if (type === _constant.MONTH_FLAG) {
			max = 12;
		} else if (type === _constant.YEAR_FLAG) {
			max = Ember.isNone(Ember.get(target, 'max')) ? (0, _time2.default)().add(100, 'years').endOf('year').year() : (0, _time2.default)(Ember.get(target, 'max')).endOf('year').year();
			min = Ember.isNone(Ember.get(target, 'min')) ? (0, _time2.default)().subtract(100, 'years').startOf('year').year() : (0, _time2.default)(Ember.get(target, 'min')).startOf('year').year();
		} else if (type === _constant.HOUR_FLAG) {
			max = 12;
		} else if (type === 'm-' + _constant.HOUR_FLAG) {
			min = 0;
			max = 24;
		} else if (type === _constant.MINUTE_FLAG) {
			min = 0;
			max = 59;
		} else if (type === _constant.SECONDS_FLAG) {
			min = 0;
			max = 59;
		}
		return { min: min, max: max };
	}

	function sectionFormatType(target) {
		var _getMeta9 = getMeta(target),
		    sectionFormat = _getMeta9.sectionFormat;

		return _time2.default.formatStringType(sectionFormat);
	}

	function handleFocus(target) {
		var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
		var num = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
		var triggerChange = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

		var defaultFocus = Ember.get(target, 'defaultFocus') || 0;
		var value = getValue(target);

		if (index >= value.length) {
			index = defaultFocus;
		} else if (index === value.length - 1) {
			index = index - 1;
		}

		// set cursor index and last num index
		setData(target.$(), 'selection', index);
		setData(target.$(), 'position', num);

		handleCursor(target, '', triggerChange);
		Ember.set(target, 'active', true);
	}

	function handleCursor(target) {
		var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
		var triggerChange = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

		return new Ember.RSVP.Promise(function (resolve) {
			Ember.run.later(function () {
				var _getMeta10 = getMeta(target),
				    format = _getMeta10.format,
				    selection = _getMeta10.selection;

				var value = getValue(target);

				var cursor = void 0;
				if (action === 'next') {
					cursor = (0, _format.getCursorPosition)(format, value, selection, true, false);
				} else if (action === 'prev') {
					cursor = (0, _format.getCursorPosition)(format, value, selection, false, true);
				} else {
					cursor = (0, _format.getCursorPosition)(format, value, selection, false, false);
				}

				if (cursor.end !== value.length - 1) {
					setSelectionRange(target.$(), cursor.start, cursor.end);
					if (selection !== cursor.start) {
						setData(target.$(), 'selection', cursor.start);
					}

					// test for section with escape chars and skip the section
					// if they are found.

					var _getMeta11 = getMeta(target),
					    start = _getMeta11.start,
					    sectionFormat = _getMeta11.sectionFormat;

					if (/^\[([\s\S])*\]$/.test(sectionFormat)) {
						if (action !== '') {
							handleCursor(target, action, triggerChange);
						} else {
							handleCursor(target, start === 0 ? 'next' : 'prev', triggerChange);
						}
					}

					if (triggerChange) {
						var lastType = Ember.get(target, '__lastType');
						var type = sectionFormatType(target);
						if (lastType !== type) {
							Ember.set(target, '__lastType', type);
							target.sendAction('oncursor', type);
						}
					}
				} else {
					handleCursor(target, '', triggerChange);
				}

				Ember.run(null, resolve, null);
			}, 10);
		});
	}

	function linkedInputData(target) {
		var inputData = getData(target.$(), 'linked');
		if (Ember.isNone(inputData)) {
			var link = Ember.get(target, 'name');
			if (!Ember.isEmpty(link)) {
				var inputs = Ember.$('input[name=' + link + ']');
				var elementId = Ember.get(target, 'elementId');
				var self = inputs.filter(function (key, el) {
					return el.id === elementId;
				});
				var index = inputs.index(self);

				inputData = { inputs: inputs, self: self, index: index };
				setData(target.$(), 'linked', inputData);
			}
		}
		return inputData;
	}

	function inputFor(target) {
		var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

		var _linkedInputData = linkedInputData(target),
		    inputs = _linkedInputData.inputs,
		    index = _linkedInputData.index;

		if (type === 'next') {
			index = index + 1;
		} else if (type === 'prev') {
			index = index - 1;
		}

		if (index < 0) {
			index = inputs.length - 1;
		} else if (index >= inputs.length) {
			index = 0;
		}

		var input = inputs[index];
		return { input: input, index: index };
	}

	function moveToNextPosition(target) {
		var _getMeta12 = getMeta(target),
		    format = _getMeta12.format,
		    end = _getMeta12.end;

		setData(target.$(), 'position', 0);
		if (end === format.length) {
			var _inputFor = inputFor(target, 'next'),
			    input = _inputFor.input;

			input = Ember.$(input);
			setData(input, 'selection', 0);
			setData(input, 'position', 0);
			input.focus();
			return Ember.RSVP.resolve({ isNewInput: true, input: input });
		} else {
			return handleCursor(target, 'next').then(function () {
				setData(target.$(), 'position', 0);
				return { isNewInput: false, input: target };
			});
		}
	}

	function moveToPrevPosition(target) {
		var _getMeta13 = getMeta(target),
		    start = _getMeta13.start;

		if (start === 0) {
			var _inputFor2 = inputFor(target, 'prev'),
			    input = _inputFor2.input;

			input = Ember.$(input);

			var _getMeta14 = getMeta(input),
			    sectionFormat = _getMeta14.sectionFormat,
			    format = _getMeta14.format;

			setData(input, 'selection', format.length);
			setData(input, 'position', sectionFormat.length);
			input.focus();
			return Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(function () {
					Ember.run(null, resolve, { isNewInput: true, input: input });
				}, 1);
			});
		} else {
			return handleCursor(target, 'prev').then(function () {
				var _getMeta15 = getMeta(target),
				    sectionFormat = _getMeta15.sectionFormat;

				setData(target.$(), 'position', sectionFormat.length);
				return { isNewInput: false, input: target };
			});
		}
	}

	function meridianLetters(target, keyName) {
		var value = getValue(target);
		var newVal = void 0;
		if (/^[aA]$/.test(keyName)) {
			newVal = value.replace(/[AP]M/, 'AM').replace(/[ap]m/, 'am');
		} else if (/^[pP]$/.test(keyName)) {
			newVal = value.replace(/[AP]M/, 'PM').replace(/[ap]m/, 'pm');
		} else if (/^[mM]$/.test(keyName)) {
			target.finalizeDateSection();
			moveToNextPosition(target);
		}

		if (!Ember.isEmpty(newVal)) {
			setValue(target, newVal);
			handleCursor(target, '');
		}
	}

	function monthLetters(target, keyName) {
		var _getMeta16 = getMeta(target),
		    sectionFormat = _getMeta16.sectionFormat,
		    position = _getMeta16.position,
		    start = _getMeta16.start,
		    end = _getMeta16.end;

		var value = getValue(target);
		var current = value.substr(start, end - start);
		var localeData = _time2.default.localeData();
		var monthData = localeData._monthsShort;
		if (sectionFormat === 'MMMM') {
			monthData = localeData._months;
		}

		var startStr = current.substr(0, position) + keyName;
		var reg = new RegExp('^' + startStr, 'i');

		var results = monthData.filter(function (i) {
			return reg.test(i);
		});
		var first = results[0];
		if (!Ember.isEmpty(first)) {
			var newVal = value.replace(current, first);
			setValue(target, newVal);
			if (results.length > 1) {
				setData(target.$(), 'position', position + 1);
			} else {
				target.finalizeDateSection();
				moveToNextPosition(target);
			}
		}
	}

	function createSection(target, num, position) {
		var isReverse = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

		var value = getValue(target);

		var _getMeta17 = getMeta(target),
		    sectionFormat = _getMeta17.sectionFormat,
		    start = _getMeta17.start,
		    end = _getMeta17.end;

		var substr = value.substring(start, end);
		var ds = void 0;

		// calculate new date from input
		if (isReverse) {
			ds = shiftRight(substr, num);
		} else {
			ds = shiftLeft(substr, num);
			if (position === 0) {
				var template = '000000000'.slice(0, sectionFormat.length);
				ds = shiftLeft(template, num);
			}
		}
		return ds;
	}

	function shiftLeft(str, val) {
		return (str + val).substr(1);
	}

	function shiftRight(str, val) {
		return (val + str).substr(0, str.length);
	}

	function mergeString(str, insert, start, end) {
		return str.substr(0, start) + insert + str.substr(end);
	}

	function setSelectionRange(el, start, end) {
		el.get(0).setSelectionRange(start, end);
	}

	function setData(el, key, value) {
		el.data(key, value);
	}

	function getData(el, key) {
		var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		return el.data(key) || defaultValue;
	}

	function getInBoundsDate(date, min, max) {
		var adjustTime = function adjustTime(d1, d2) {
			if (d1.hours() < d2.hours()) d2.hours(d1.hours());
			if (d1.minutes() < d2.minutes()) d2.minutes(d1.minutes());
			if (d1.seconds() < d2.seconds()) d2.seconds(d1.seconds());
			return d2;
		};

		if (_time2.default.isDateAfter(date, max)) {
			return adjustTime((0, _time2.default)(date), (0, _time2.default)(max));
		} else if (_time2.default.isDateBefore(date, min)) {
			return adjustTime((0, _time2.default)(min), (0, _time2.default)(date));
		}
		return (0, _time2.default)(date);
	}
});