define('ember-google-maps/components/g-map/route', ['exports', 'ember-google-maps/components/g-map/map-component', 'ember-google-maps/templates/components/g-map/route'], function (exports, _mapComponent, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mapComponent.default.extend({
    layout: _route.default,
    tagName: '',

    _type: 'route',
    _requiredOptions: ['directions'],

    _addComponent: function _addComponent() {
      var options = Ember.get(this, '_options');

      if (!options.directions) {
        return Ember.RSVP.reject();
      }

      Ember.set(this, 'mapComponent', new google.maps.DirectionsRenderer(options));
    }
  });
});