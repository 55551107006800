define('@busy-web/utils/time', ['exports', 'moment', '@busy-web/utils/loc', '@busy-web/utils/local-storage', '@busy-web/utils/locale', '@busy-web/utils/assert', '@busy-web/utils/utils/time-zones'], function (exports, _moment, _loc, _localStorage, _locale, _assert, _timeZones) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * @module Utils
  *
  */
	var Time = Ember.Object.extend();

	/**
  * `Util/Time`
  *
  * @class Time
  * @namespace Utils
  * @uses Moment
  * @uses Utils.Loc
  * @uses Utils.LocalStorage
  */
	Time.reopenClass({
		hoursFormat: null,

		timezone: function timezone(timestamp) {
			if (timestamp !== undefined) {
				return (0, _moment.default)(timestamp * 1000).subtract((0, _moment.default)(timestamp * 1000).utcOffset(), 'minutes').utcOffset() * 60;
			} else {
				return (0, _moment.default)().utcOffset() * 60;
			}
		},
		timestamp: function timestamp(_timestamp) {
			return this.date(_timestamp).unix();
		},
		locale: function locale() {
			return _moment.default.locale();
		},
		timeFormat: function timeFormat(timestamp, formatStr) {
			_assert.default.isNumber(timestamp);
			_assert.default.isString(formatStr);

			return this.dateFormat(this.date(timestamp), formatStr);
		},
		dateFormat: function dateFormat(date, formatStr) {
			_assert.default.isMoment(date);
			_assert.default.isString(formatStr);

			var str = _locale.default.format(formatStr, this.locale());
			return date.format(str);
		},
		now: function now() {
			return this.date();
		},
		utcTimestamp: function utcTimestamp() {
			var time = this.timestamp();
			return time - this.timezone(time);
		},
		date: function date(timestamp) {
			if (timestamp !== undefined) {
				return _moment.default.utc(timestamp * 1000);
			} else {
				return _moment.default.utc().add(this.timezone(), 'seconds');
			}
		},
		isDST: function isDST(timestamp) {
			if (timestamp !== undefined) {
				return (0, _moment.default)(timestamp * 1000).subtract((0, _moment.default)(timestamp * 1000).utcOffset(), 'minutes').isDST();
			} else {
				return (0, _moment.default)().isDST();
			}
		},
		nistTimestamp: function nistTimestamp() {
			return window.__NIST.timestamp();
		},
		isTrustedTimeType: function isTrustedTimeType() {
			var _window$__NIST;

			return (_window$__NIST = window.__NIST).isTrustedTimeType.apply(_window$__NIST, arguments);
		},
		convertSeconds: function convertSeconds(seconds) {
			var toFixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

			seconds = parseInt(seconds, 10);
			seconds = seconds < 0 || isNaN(seconds) ? 0 : seconds;

			var remaining = seconds;
			var hours = Math.floor(remaining / 3600);
			remaining %= 3600;

			var minutes = Math.floor(remaining / 60);
			remaining %= 60;

			var secs = Math.ceil(remaining);

			return {
				hours: hours,
				minutes: minutes,
				seconds: secs,
				decimal: Math.round(seconds / 60 / 60 * Math.pow(10, toFixed)) / Math.pow(10, toFixed)
			};
		},
		convertSecondsString: function convertSecondsString(seconds, format) {
			var padHours = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
			var showSeconds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
			var longFormat = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
			var toFixed = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 2;

			var timeStr = '';

			// format can be passed in to override the users ability to change
			// the format.
			if (Ember.isNone(format)) {
				format = _localStorage.default.getWithDefault('format-hours-type', 10);
				format = parseInt(format, 10);
			}

			_assert.default.test('format must be a number in convertSecondsString', typeof format === 'number');

			var time = this.convertSeconds(seconds, toFixed);

			if (format === 20) {
				// set time string for decimal hours
				timeStr = (0, _loc.default)('%@ Hrs', [time.decimal.toFixed(toFixed)]);
			} else {
				if (padHours === true) {
					time.hours = (time.hours < 10 ? '0' : '') + time.hours;
					if (longFormat === false) {
						time.minutes = (time.minutes < 10 ? '0' : '') + time.minutes;
						time.seconds = (time.seconds < 10 ? '0' : '') + time.seconds;
					}
				}

				if (longFormat === true) {
					// set time string for long format hrs mins secs
					if (showSeconds === true) {
						timeStr = (0, _loc.default)('%@ Hrs %@ Mins %@ Secs', [time.hours, time.minutes, time.seconds]);
					} else {
						timeStr = (0, _loc.default)('%@ Hrs %@ Mins', [time.hours, time.minutes]);
					}
				} else {
					if (showSeconds === true) {
						// set time string for hrs:min:secs
						timeStr = time.hours + ':' + time.minutes + ':' + time.seconds;
					} else {
						timeStr = time.hours + ':' + time.minutes;
					}
				}
			}

			return timeStr;
		},
		timezoneString: function timezoneString(offset, isDST) {
			var zone = _timeZones.default[offset];
			if (zone && zone.locale) {
				zone = zone.locale['en-US'];
			}

			if (zone && zone.period) {
				var period = isDST ? 'dst' : 'standard';
				zone = zone.period[period];
			}
			return zone;
		},
		setHoursFormat: function setHoursFormat(type) {
			_assert.default.test('setHoursFormat only accepts a 10 for standard format or 20 for decimal format', type === 10 || type === 20);

			this.set('hoursFormat', type);
			_localStorage.default.setProperty('format-hours-type', type);
		},
		getHoursFormat: function getHoursFormat() {
			var type = _localStorage.default.getProperty('format-hours-type');
			if (Ember.isNone(type)) {
				type = 10;
			}
			this.set('hoursFormat', type);
			return type;
		},
		set: function set(key, property) {
			Ember.set(this, key, property);
			return this;
		},
		get: function get(key) {
			return Ember.get(this, key);
		}
	});

	exports.default = Time;
});