define('ember-google-maps/utils/helpers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var position = Ember.computed('lat', 'lng', function () {
    var _EmberGetProperties = Ember.getProperties(this, 'lat', 'lng'),
        lat = _EmberGetProperties.lat,
        lng = _EmberGetProperties.lng;

    if (lat && lng) {
      return new google.maps.LatLng(lat, lng);
    }
  });

  function computedPromise() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var func = args.pop();
    return Ember.computed.apply(undefined, args.concat([function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    }]));
  }

  exports.computedPromise = computedPromise;
  exports.position = position;
});