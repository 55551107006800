define('@busy-web/data/utils/query', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function addQueryStringPair(params, key, value) {
		if (/\[.+\]/.test(key)) {
			var _key$split = key.split('['),
			    _key$split2 = _slicedToArray(_key$split, 2),
			    normalizeKey = _key$split2[0],
			    subKey = _key$split2[1];

			subKey = subKey.split(']').join('');
			var obj = Ember.get(params, normalizeKey) || {};

			// NOTE:
			// for keys formatted like `key[subkey][]=value`
			// the key will be parsed correctly in that
			// at this part of the code we will have `subkey[]=value`
			// therefore recursively calling addQueryStringPair will parse
			// this in the following `else if` statment
			addQueryStringPair(obj, subKey, value);

			// after recursively calling addQueryStringPair
			// then the new obj can be added to the original params
			Ember.set(params, normalizeKey, obj);
		} else if (/\[\]$/.test(key)) {
			// parse type: `key[]=value`
			var _normalizeKey = key.substring(key.length - 2, 0);
			var arr = Ember.get(params, _normalizeKey) || [];
			arr.push(value);
			addQueryStringPair(params, _normalizeKey, arr);
		} else {
			// normal key pair just add to params
			Ember.set(params, key, typeCast(value));
		}
	}

	function typeCast(value) {
		if (/^[.][\d]+/.test(value)) {
			return typeCast(0 + value);
		}

		if (value === '') {
			return null;
		} else if (value === 'true') {
			return true;
		} else if (value === 'false') {
			return false;
		} else if (/[\d]+[.]?[\d]*/.test(value)) {
			var val = parseInt(value, 10);
			if ('' + val === value) {
				return val;
			} else {
				val = parseFloat(value);
				if ('' + val === value) {
					return val;
				}
			}
		}
		return value;
	}

	exports.default = {
		stringify: function stringify(query) {
			var _this = this;

			var queryStr = '';
			Object.keys(query).forEach(function (key) {
				var value = Ember.get(query, key);
				if (value !== undefined) {
					if (Ember.isArray(value)) {
						value.forEach(function (val) {
							return queryStr += '&' + key + '[]=' + val;
						});
					} else if (!Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
						var subStr = _this.stringify(value);
						queryStr += '&' + subStr.replace(/^([^=]*)/, key + '[$1]').replace(/&([^=]*)/g, '&' + key + '[$1]');
					} else {
						if (value === null) {
							value = '';
						}
						queryStr += '&' + key + '=' + value;
					}
				}
			});
			return queryStr.replace(/^&/, '');
		},
		parse: function parse(query) {
			var data = {};
			if (query && query.length) {
				var params = query.split('&');
				params.forEach(function (item) {
					var _item$split = item.split('='),
					    _item$split2 = _slicedToArray(_item$split, 2),
					    key = _item$split2[0],
					    value = _item$split2[1];

					addQueryStringPair(data, key, value);
				});
			}
			return data;
		}
	};
});