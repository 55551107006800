define('@busy-web/data/serializers/json', ['exports', 'ember-data', 'ember-uuid', '@busy-web/data/mixins/json-api-serializer'], function (exports, _emberData, _emberUuid, _jsonApiSerializer) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPISerializer.extend(_jsonApiSerializer.default, {
		serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
			var type = attribute.type;
			if (this._canSerialize(key)) {
				var value = snapshot.attr(key);
				if (!Ember.isNone(type) && value !== undefined && value !== '_-NULL-_' && value !== '!_-NULL-_' && value !== '_-DISABLE-_') {
					var transform = this.transformFor(type);
					value = transform.serialize(value, attribute.options);
				}

				if (key === 'createdOn' && Ember.isNone(value)) {
					value = parseInt(Date.now() / 1000, 10);
				}

				if (!Ember.isNone(value) && value.hasOwnProperty('file') && (value.file instanceof File || value.file instanceof Blob)) {
					json._fileObject = value;
					value = value.get('file');

					// set the record to null so it wont get submitted again
					snapshot.record.file = null;
				}

				if (value !== undefined) {
					// if provided, use the mapping provided by `attrs` in
					// the serializer
					var payloadKey = this._getMappedKey(key, snapshot.type);
					if (payloadKey === key && this.keyForAttribute) {
						payloadKey = this.keyForAttribute(key, 'serialize');
					}

					json[payloadKey] = value;
				}
			}
		},
		generateIdForRecord: function generateIdForRecord() {
			return _emberUuid.v4.apply(_emberUuid.v4, arguments);
		},
		keyForAttribute: function keyForAttribute(key) {
			// look for underscored api properties
			return Ember.String.underscore(key);
		},
		modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
			return key;
		},
		getDataFromResponse: function getDataFromResponse(payload) {
			// get the data array from the payload
			return Ember.get(payload, 'data');
		},
		getMetaFromResponse: function getMetaFromResponse(payload) {
			// add meta to response
			return {
				next: payload.next,
				prev: payload.prev,
				returnedRows: payload.returned_rows,
				totalRows: payload.total_rows
			};
		},
		warnMessageForUndefinedType: function warnMessageForUndefinedType() {
			return 'Type is undefined';
		},
		serialize: function serialize(snapshot, options) {
			var _this = this;

			var data = {};
			var isNew = Ember.getWithDefault(snapshot, 'record.isNew', false);

			if (options && options.includeId) {
				if (snapshot.id) {
					data[Ember.get(this, 'primaryKey')] = snapshot.id;
				}
			}

			var changeAttrs = Object.keys(Ember.getWithDefault(snapshot, '_changedAttributes', // new attr changes
			Ember.getWithDefault(snapshot, '_internalModel._inFlightAttributes', {}) // support older attr changes
			));

			snapshot.eachAttribute(function (key, attribute) {
				if (isNew || changeAttrs.indexOf(key) !== -1) {
					_this.serializeAttribute(snapshot, data, key, attribute);
				}
			});

			snapshot.eachRelationship(function (key, relationship) {
				if (relationship.kind === 'belongsTo') {
					_this.serializeBelongsTo(snapshot, data, relationship);
				} else if (relationship.kind === 'hasMany') {
					_this.serializeHasMany(snapshot, data, relationship);
				}
			});

			return { data: data };
		},
		serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
			var dataHash = this.serialize(snapshot, options, true);
			var data = dataHash.data;

			delete data.relationships;

			for (var key in data) {
				if (data.hasOwnProperty(key)) {
					if (data[key] !== undefined) {
						hash[key] = data[key];
					}
				}
			}
		}
	});
});