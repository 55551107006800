define('@busy-web/data/mixins/store-finders', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var PromiseArray = _emberData.default.PromiseArray;
	exports.default = Ember.Mixin.create({
		_maxPageSize: 80,

		findAll: function findAll(modelType) {
			var _this = this;

			var _query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			// copy query object
			var query = Ember.merge({}, _query);

			if (Ember.isNone(Ember.get(query, 'limit'))) {
				Ember.set(query, 'page_size', Ember.getWithDefault(query, 'page_size', this._maxPageSize));
				Ember.set(query, 'page', Ember.getWithDefault(query, 'page', 1));
			}

			// make query request
			return this.query(modelType, query).then(function (models) {
				var nextQuery = {};
				if (nextParams(models, nextQuery, query)) {
					return _this.findAll(modelType, nextQuery).then(function (moreModels) {
						if (moreModels && Ember.get(moreModels, 'length') > 0) {
							var mArray = moreModels.toArray().map(function (item) {
								return Ember.get(item, '_internalModel') || item;
							});
							models.pushObjects(mArray);
							var meta = Ember.get(models, 'meta');
							var moreMeta = Ember.get(moreModels, 'meta');
							meta.next = moreMeta.next;
							meta.returnedRows = meta.returnedRows + moreMeta.returnedRows;

							Ember.set(models, 'meta', meta);
						}
						return models;
					});
				} else {
					return models;
				}
			});
		},
		findByIds: function findByIds(modelType, ids) {
			var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

			query.deleted_on = '_-DISABLE-_';
			return this.findWhereIn(modelType, 'id', ids, query);
		},
		findWhereIn: function findWhereIn(modelType, key, values) {
			var query = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
			(false && !(typeof modelType === 'string') && Ember.assert('modelType must be of type string in store.findWhereIn()', typeof modelType === 'string'));
			(false && !(typeof key === 'string') && Ember.assert('key must be of type string in store.findWhereIn()', typeof key === 'string'));
			(false && !(Ember.isArray(values)) && Ember.assert('values must be an array of strings in store.findWhereIn()', Ember.isArray(values)));
			(false && !((typeof query === 'undefined' ? 'undefined' : _typeof(query)) === 'object') && Ember.assert('query must be an object in store.findWhereIn()', (typeof query === 'undefined' ? 'undefined' : _typeof(query)) === 'object'));


			var _values = values.slice(0);
			var queryList = [];
			while (_values.length) {
				var __values = _values.splice(0, 10);
				if (/^!/.test(key)) {
					queryList.push(Ember.merge({ _not_in: _defineProperty({}, key, __values) }, query));
				} else {
					queryList.push(Ember.merge({ _in: _defineProperty({}, key, __values) }, query));
				}
			}

			return this._findRecords(modelType, queryList, null);
		},


		/**
   * Simple rpc request method that does not use the ember-data
   * model layer.
   *
   * @public
   * @method rpcRequest
   * @param type {string} The RPC client to call the method
   * @param method {string} The RPC method on the client
   * @param params {object} The params to send to the method
   * @param baseURL {string} Optional, Override url to the rpc client if different from the normal baseURL.
   * @return {EmberPromise}
   */
		rpcRequest: function rpcRequest(client, method) {
			var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
			var baseURL = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
			(false && !(typeof client === 'string') && Ember.assert('client must be of type string in store.rpcRequest()', typeof client === 'string'));
			(false && !(typeof method === 'string') && Ember.assert('method must be of type string in store.rpcRequest()', typeof method === 'string'));
			(false && !(!Ember.isNone(params) && (typeof params === 'undefined' ? 'undefined' : _typeof(params)) === 'object') && Ember.assert('params must be an object in store.rpcRequest()', !Ember.isNone(params) && (typeof params === 'undefined' ? 'undefined' : _typeof(params)) === 'object'));
			(false && !(typeof baseURL === 'string') && Ember.assert('baseURL must be of type string in store.rpcRequest()', typeof baseURL === 'string'));


			var owner = Ember.getOwner(this);

			// support ember 2.0
			var adapter = Ember.get(this, '_instanceCache.adapter');
			if (Ember.isNone(adapter)) {
				// support ember 3.0
				adapter = Ember.get(this, '_adapterCache.application') || owner.lookup('adapter:application');
				if (Ember.isNone(adapter)) {
					var adapterName = Ember.get(this, 'adapter');
					adapter = Ember.get(this, '_adapterCache.' + adapterName) || owner.lookup('adapter:' + adapterName);
				}
			}

			(false && !(!Ember.isNone(adapter.rpcRequest)) && Ember.assert("In order to use rpcRequest your must include the rpc-adapter mixin in your adapter", !Ember.isNone(adapter.rpcRequest)));

			// call the rpc method and return the promise.

			return adapter.rpcRequest(this, client, method, params, baseURL);
		},


		/**
   *
   * TODO: test this
   * add recordArray.update method for update calls to _findRecords
   *
   */
		_findRecords: function _findRecords(modelName, query, array) {
			var _this2 = this;

			array = array || this.recordArrayManager.createAdapterPopulatedRecordArray(modelName, query);
			Ember.set(array, '_update', function () {
				return _this2._findRecords(Ember.get(array, 'modelName'), Ember.get(array, 'query'), array);
			});

			var adapter = this.adapterFor(modelName);

			(false && !(adapter) && Ember.assert('You tried to load a query but you have no adapter (for ' + modelName + ')', adapter));
			(false && !(typeof adapter.query === 'function') && Ember.assert('You tried to load a query but your adapter does not implement \'query\'', typeof adapter.query === 'function'));

			// adapter.query needs the class

			var modelClass = this.modelFor(modelName);

			// call findRecords and return the promsie
			var promise = void 0;
			if (Ember.isArray(query)) {
				promise = _findRecordQueue(adapter, this, modelClass, query.slice(0)).then(function (payload) {
					return _processResults(_this2, array, payload);
				});
			} else {
				promise = _findRecords2(adapter, this, modelClass, query).then(function (payload) {
					return _processResults(_this2, array, payload);
				});
			}
			return PromiseArray.create({ promise: promise });
		}
	});


	function _findRecordQueue(adapter, store, modelClass, queryObjects) {
		// no queryObjects then return a default jsonapi response
		if (queryObjects.length <= 0) {
			return Ember.RSVP.resolve({
				data: [],
				included: [],
				jsonapi: { version: '1.0' },
				meta: { returnedRows: 0 }
			});
		}

		return new Ember.RSVP.Promise(function (resolve, reject) {
			var promises = [];
			// call _findRecords on each query object
			queryObjects.forEach(function (query) {
				return promises.push(_findRecords2(adapter, store, modelClass, query));
			});

			// parse the results of all calls an merge them into one result
			Ember.RSVP.all(promises).then(function (results) {
				return _mergeRecords(results);
			}).then(function (records) {
				return Ember.get(records, 'errors') ? Ember.run(null, reject, records) : Ember.run(null, resolve, records);
			}).catch(function (err) {
				return Ember.run(null, reject, err);
			});
		});
	}

	/**
  * generic api getter for making callse to the api.
  *
  * NOTE:
  * This call returns a jsonapi formatted object. _processResults
  * must be called to convert the object to a recordArray.
  *
  * @method _findRecords
  * @params adapter {DS.Adapter}
  * @params store {DS.Store}
  * @params modelClass {DS.Model}
  * @params query {Object}
  * @returns {Object}
  */
	function _findRecords2(adapter, store, modelClass, query) {
		var promise = adapter.query(store, modelClass, query);
		var label = 'DS: Handle Adapter#query of ' + modelClass;

		promise = Ember.RSVP.Promise.resolve(promise, label);
		promise = _guard(promise, _bind(_objectIsAlive, store));

		return promise.then(function (adapterPayload) {
			var serializer = serializerForAdapter(store, adapter, modelClass.modelName);
			var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'query');

			var nextQuery = {};
			if (nextParams(payload, nextQuery, query)) {
				return _findRecords2(adapter, store, modelClass, nextQuery).then(function (nextPayload) {
					return _mergeRecords([payload, nextPayload]);
				});
			} else {
				return payload;
			}
		}, null, 'DS: Extract payload of query ' + modelClass.modelName);
	}

	function _processResults(store, recordArray, payload) {
		var internalModels = store._push(payload);

		(false && !(Array.isArray(internalModels)) && Ember.assert('The response to store.query is expected to be an array but it was a single record. Please wrap your response in an array or use `store.queryRecord` to query for a single record.', Array.isArray(internalModels)));


		recordArray._setInternalModels(internalModels, payload);
		return recordArray;
	}

	function _mergeRecords(records) {
		var recordOut = void 0;
		records.forEach(function (record) {
			if (!recordOut) {
				recordOut = record;
			} else {
				if (Ember.get(recordOut, 'meta')) {
					// get returned rows
					Ember.set(recordOut, 'meta.returnedRows', Ember.getWithDefault(recordOut, 'meta.returnedRows', 0) + Ember.getWithDefault(record, 'meta.returnedRows', 0));

					// keep track of next calls if there are any
					Ember.set(recordOut, 'meta.next', Ember.get(record, 'meta.next'));

					if (Ember.get(recordOut, 'meta.totalRows') !== Ember.get(record, 'meta.totalRows')) {
						// get total rows
						Ember.set(recordOut, 'meta.totalRows', Ember.getWithDefault(recordOut, 'meta.totalRows', 0) + Ember.getWithDefault(record, 'meta.totalRows', 0));
					}
				}

				if (Ember.get(recordOut, 'links')) {
					// keep track of next calls if there are any
					Ember.set(recordOut, 'links.next', Ember.get(record, 'links.next'));
				}

				// merged data arrays
				Ember.set(recordOut, 'data', Ember.get(recordOut, 'data').concat(Ember.get(record, 'data')));

				// combine api errors
				if (Ember.get(recordOut, 'errors') && Ember.get(record, 'errors')) {
					Ember.set(recordOut, 'errors', Ember.get(recordOut, 'errors').concat(Ember.get(record, 'errors')));
				} else if (!Ember.get(recordOut, 'errors') && Ember.get(record, 'errors')) {
					Ember.set(recordOut, 'errors', Ember.get(record, 'errors'));
				}
			}
		});
		return recordOut;
	}

	function _bind(fn) {
		for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			args[_key - 1] = arguments[_key];
		}

		return function () {
			return fn.apply(undefined, args);
		};
	}

	function _guard(promise, test) {
		var guarded = promise['finally'](function () {
			if (!test()) {
				guarded._subscribers.length = 0;
			}
		});
		return guarded;
	}

	function _objectIsAlive(object) {
		return !(Ember.get(object, "isDestroyed") || Ember.get(object, "isDestroying"));
	}

	function serializerForAdapter(store, adapter, modelName) {
		var serializer = adapter.serializer;

		if (serializer === undefined) {
			serializer = store.serializerFor(modelName);
		}

		if (serializer === null || serializer === undefined) {
			serializer = {
				extract: function extract(store, type, payload) {
					return payload;
				}
			};
		}

		return serializer;
	}

	function validateDocumentStructure(doc) {
		var errors = [];
		if (!doc || (typeof doc === 'undefined' ? 'undefined' : _typeof(doc)) !== 'object') {
			errors.push('Top level of a JSON API document must be an object');
		} else {
			if (!('data' in doc) && !('errors' in doc) && !('meta' in doc)) {
				errors.push('One or more of the following keys must be present: "data", "errors", "meta".');
			} else {
				if ('data' in doc && 'errors' in doc) {
					errors.push('Top level keys "errors" and "data" cannot both be present in a JSON API document');
				}
			}
			if ('data' in doc) {
				if (!(doc.data === null || Array.isArray(doc.data) || _typeof(doc.data) === 'object')) {
					errors.push('data must be null, an object, or an array');
				}
			}
			if ('meta' in doc) {
				if (_typeof(doc.meta) !== 'object') {
					errors.push('meta must be an object');
				}
			}
			if ('errors' in doc) {
				if (!Array.isArray(doc.errors)) {
					errors.push('errors must be an array');
				}
			}
			if ('links' in doc) {
				if (_typeof(doc.links) !== 'object') {
					errors.push('links must be an object');
				}
			}
			if ('jsonapi' in doc) {
				if (_typeof(doc.jsonapi) !== 'object') {
					errors.push('jsonapi must be an object');
				}
			}
			if ('included' in doc) {
				if (_typeof(doc.included) !== 'object') {
					errors.push('included must be an array');
				}
			}
		}

		return errors;
	}

	function normalizeResponseHelper(serializer, store, modelClass, payload, id, requestType) {
		var normalizedResponse = serializer.normalizeResponse(store, modelClass, payload, id, requestType);
		var validationErrors = [];
		Ember.runInDebug(function () {
			validationErrors = validateDocumentStructure(normalizedResponse);
		});

		(false && !(Ember.isEmpty(validationErrors)) && Ember.assert('normalizeResponse must return a valid JSON API document:\n\t* ' + validationErrors.join('\n\t* '), Ember.isEmpty(validationErrors)));


		return normalizedResponse;
	}

	function nextParams(model, query, lastQuery) {
		var isJsonApi = false;
		var next = Ember.get(model, 'meta.next');
		if (Ember.isNone(next)) {
			isJsonApi = true;
			next = Ember.get(model, 'links.next');
		}

		if (!Ember.isEmpty(next)) {
			if (isJsonApi) {
				var _next$split = next.split('?'),
				    _next$split2 = _slicedToArray(_next$split, 2),
				    params = _next$split2[1];

				params = params.split('&');
				params.forEach(function (item) {
					var _item$split = item.split('='),
					    _item$split2 = _slicedToArray(_item$split, 2),
					    key = _item$split2[0],
					    value = _item$split2[1];

					if (/\[/.test(key)) {
						var _key$split = key.split('['),
						    _key$split2 = _slicedToArray(_key$split, 2),
						    key2 = _key$split2[0],
						    key3 = _key$split2[1];

						key3 = key3.replace(/\]$/, '');
						key = key2;
						if (/\d+/.test(key3)) {
							value = [value];
						} else {
							value = _defineProperty({}, key3, value);
						}
					}
					Ember.set(query, key, value);
				});
			} else {
				lastQuery.page = (lastQuery.page || 1) + 1;
				Ember.merge(query, lastQuery);
			}
			return true;
		}
		return false;
	}

	//function _findWhereIn(store, modelType, queryList, query) {
	//	if (queryList.length === 0) {
	//		return resolve([]);
	//	}
	//
	//	// get next query params
	//	const params = queryList.shift();
	//
	//	// merge query params with params
	//	merge(params, query);
	//
	//	// find all models
	//	return store.findAll(modelType, params).then((model) => {
	//		return _findWhereIn(store, modelType, queryList, query).then((moreModels) => {
	//			if (moreModels && !isEmpty(get(moreModels, 'content'))) {
	//				return model.pushObjects(get(moreModels, 'content'));
	//			}
	//			return model;
	//		});
	//	});
	//}
});