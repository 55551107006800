define('ember-google-maps/components/g-map/waypoint', ['exports', 'ember-google-maps/mixins/process-options'], function (exports, _processOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_processOptions.default, {
    tagName: '',

    _requiredOptions: ['location'],
    _ignoredAttrs: ['_registerWaypoint', '_unregisterWaypoint'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._registerWaypoint(Ember.get(this, '_options'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._unregisterWaypoint(Ember.get(this, '_options'));
    }
  });
});