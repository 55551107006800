define('ember-jquery-date-range-picker/components/jq-date-picker', ['exports', 'ember-jquery-date-range-picker/templates/components/jq-date-picker', 'moment'], function (exports, _jqDatePicker, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['js-date-picker'],
		classNameBindings: ['isDateRange::single'],

		layout: _jqDatePicker.default,

		rawValue: '',

		startDate: null,
		endDate: null,

		minDate: false,
		maxDate: false,

		maxDays: null,
		minDays: null,

		format: "MM/DD/YYYY",

		showTime: true,

		titleBar: '',
		seperator: '-',

		autoClose: true,

		showTopbar: false,
		selectForward: false,
		singleDate: false,

		isOpen: false,

		showButton: false,
		buttonText: '',

		startOfWeek: 'sunday',

		beforeShowDay: null,
		allowOpen: true,

		stickyMonths: false,
		_picker: null,

		inputStyle: Ember.computed('isDateRange', function () {
			var css = 'position:absolute;visibility:hidden;height:100%;left:0;';
			if (this.get('isDateRange')) {
				css += 'width:100%;';
			} else {
				css += 'width: 275px;';
			}
			return Ember.String.htmlSafe(css);
		}),

		isDateRange: Ember.computed('startDate', 'endDate', function () {
			if (!Ember.isNone(this.get('startDate')) && !Ember.isNone(this.get('endDate'))) {
				this.set('showTime', false);
				return true;
			}
			return false;
		}),

		startTimeString: Ember.computed('startDate', function () {
			var time = '';
			if (!Ember.isNone(this.get('startDate'))) {
				time = _moment.default.utc(this.get('startDate') * 1000).format(this.get('format'));
			}
			return time;
		}),

		endTimeString: Ember.computed('endDate', function () {
			var time = '';
			if (!Ember.isNone(this.get('endDate'))) {
				time = _moment.default.utc(this.get('endDate') * 1000).format(this.get('format'));
			}
			return time;
		}),

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);

			var _pickerElement = this.$().find('input');
			this.set('_picker', _pickerElement);

			var opts = {
				format: this.get('format'),
				autoClose: this.get('autoClose'),
				singleDate: !this.get('isDateRange'),
				customTopBar: this.get('titleBar'),
				showTopbar: this.get('showTopbar'),
				selectForward: this.get('selectForward'),
				startOfWeek: this.get('startOfWeek'),
				stickyMonths: this.get('stickyMonths'),
				time: {
					enabled: this.get('showTime')
				}
			};

			if (!Ember.isNone(this.get('minDate'))) {
				opts.startDate = _moment.default.utc(this.get('minDate') * 1000).format(this.get('format'));
			}

			if (!Ember.isNone(this.get('minDays'))) {
				opts.minDays = this.get('minDays');
			}

			if (!Ember.isNone(this.get('maxDate'))) {
				opts.endDate = _moment.default.utc(this.get('maxDate') * 1000).format(this.get('format'));
			}

			if (!Ember.isNone(this.get('maxDays'))) {
				opts.maxDays = this.get('maxDays');
			}

			if (!Ember.isNone(this.get('beforeShowDay'))) {
				(false && !(typeof this.get('beforeShowDay') === 'function') && Ember.assert('beforeShowDay must be a function that returns an array [isSelectable, className, option tooltip]', typeof this.get('beforeShowDay') === 'function'));


				var _this = this;
				opts.beforeShowDay = function () {
					return _this.get('beforeShowDay').apply(_this.get('targetObject'), arguments);
				};
			}

			_pickerElement.dateRangePicker(opts).bind('datepicker-first-date-selected', function (event, obj) {
				if (_this2.get('singleDate') && _this2.get('isDateRange')) {
					_this2.dateChanged(event, obj);
				}
			}).bind('datepicker-change', function (event, obj) {
				if (!_this2.get('singleDate') || !_this2.get('isDateRange')) {
					_this2.dateChanged(event, obj);
				}
			}).bind('datepicker-closed', function () {
				if (!_this2.get('isDestroyed')) {
					_this2.set('isOpen', false);
				}
			}).bind('datepicker-opened', function () {
				if (!_this2.get('isDestroyed')) {
					_this2.set('isOpen', true);
				}
			});

			this.convertTimeToVal(_pickerElement, this.get('startDate'), this.get('endDate'));
		},
		convertTimeToVal: function convertTimeToVal(el, start, end) {
			var startTime = '';
			if (typeof start === 'number') {
				startTime = _moment.default.utc(start * 1000).format(this.get('format'));
			}

			var endTime = '';
			if (typeof end === 'number') {
				endTime = _moment.default.utc(end * 1000).format(this.get('format'));
			} else {
				endTime = startTime;
			}

			el.data('dateRangePicker').setDateRange(startTime, endTime);
		},
		timezone: function timezone(timestamp) {
			if (timestamp !== undefined) {
				return (0, _moment.default)(timestamp * 1000).add((0, _moment.default)(timestamp * 1000).utcOffset(), 'minutes').utcOffset() * 60;
			} else {
				return (0, _moment.default)().add((0, _moment.default)().utcOffset(), 'minutes').utcOffset() * 60;
			}
		},
		dateChanged: function dateChanged(evt, obj) {
			if (!this.get('isDestroyed')) {
				var time1 = void 0,
				    time2 = void 0;
				if (obj.date1 !== undefined) {
					var unixTime = obj.date1.valueOf() / 1000;
					unixTime = unixTime + this.timezone(unixTime);

					time1 = _moment.default.utc(unixTime * 1000).unix();
					this.set('startDate', time1);
				}

				if (obj.date2 !== undefined) {
					var _unixTime = obj.date2.valueOf() / 1000;
					_unixTime = _unixTime + this.timezone(_unixTime);

					time2 = _moment.default.utc(_unixTime * 1000).endOf('day').unix();
					this.set('endDate', time2);
				}

				this.sendAction('onChange', time1, time2); // eslint-disable-line ember/closure-actions
			}
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			if (!Ember.isNone(this.get('_picker'))) {
				this.get('_picker').data('dateRangePicker').destroy();
			}
		},
		toggle: function toggle() {
			if (!Ember.isNone(this.get('_picker'))) {
				if (this.get('isOpen')) {
					this.get('_picker').data('dateRangePicker').close();
				} else {
					this.get('_picker').data('dateRangePicker').open();
				}
			}
		},


		actions: {
			containerOpenClose: function containerOpenClose() {
				if (this.get('allowOpen') || !this.get('showButton')) {
					this.toggle();
				}
			},
			openClose: function openClose() {
				this.toggle();
			}
		}
	});
});