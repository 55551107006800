define('@busy-web/ember-date-time/mixins/key-events', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		_timeout: false,

		throttleKey: function throttleKey(evt) {
			var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;

			if (!this._timeout) {
				this._timeout = true;
				var _this = this;
				this.set('_timeout', window.setTimeout(function () {
					_this._timeout = false;
				}, time));
				return true;
			} else {
				return this.preventDefault(evt);
			}
		},


		/**
   * Takse a event and returns a readable key event for main key events like enter, tab arrow keys
   *
   * @public
   * @method keyCodeTranslator
   * @param evt {object}
   * @return {string}
   */
		translate: function translate(key) {
			var keys = { 9: 'tab', 13: 'enter', 8: 'delete', 37: 'left-arrow', 38: 'up-arrow', 39: 'right-arrow', 40: 'down-arrow' };
			return keys[key] || -1;
		},
		isKey: function isKey(evt, keys) {
			var code = this.getKeyCode(evt);
			var name = this.translate(code);
			var isKey = false;
			if (keys.indexOf(code) !== -1) {
				isKey = true;
			} else if (name !== -1 && keys.indexOf(name) !== -1) {
				isKey = true;
			}
			return isKey;
		},


		/**
   * only allows up and down arrows and tabs to be affected
   *
   * @private
   * @method onlyAllowArrows
   * @param {event} key press event
   */
		isAllowedKey: function isAllowedKey(evt, keys) {
			// only allows arrow keys and tab key
			var isArrow = this.isKey(evt, keys);

			// if allowed return true
			if (isArrow) {
				return true;
			} else {
				return this.preventDefault(evt);
			}
		},
		getKeyCode: function getKeyCode(evt) {
			return evt.keyCode || evt.which;
		},
		onKeyPressed: function onKeyPressed(evt, keys, callback, target) {
			(false && !(typeof callback === 'function') && Ember.assert('callback must be a function', typeof callback === 'function'));

			// set default target for target

			target = Ember.isNone(target) ? this : target;

			if (this.isKey(evt, keys)) {
				callback.call(target);
			}
		},
		preventDefault: function preventDefault(evt) {
			evt.returnValue = false;
			if (evt.preventDefault) {
				evt.preventDefault();
			}
			return false;
		}
	});
});