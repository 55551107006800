define('@busy-web/utils/loc', ['exports', '@busy-web/utils/assert'], function (exports, _assert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = localizer;


  /**
   * `Util/Loc`
   *
   * @class Loc
   * @namespace Utils
   */
  function localizer(str, params) {
    _assert.default.funcNumArgs(arguments, 2);
    _assert.default.isString(str);

    if (!Ember.isNone(params)) {
      _assert.default.isArray(params);
    }

    return Ember.String.loc(str, params);
  } /**
     * @module Utils
     *
     */
});