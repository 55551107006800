define('@busy-web/ember-date-time/--private/computed/property', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = property;
	function property() {
		var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		var defaultValue = options.defaultValue || null;

		var meta = {
			type: 'property',
			defaultValue: defaultValue,
			savedValue: defaultValue
		};

		return Ember.computed({
			get: function get(key) {
				return Ember.getWithDefault(this, '__' + key, defaultValue);
			},
			set: function set(key, value) {
				meta.savedValue = Ember.get(this, '__' + key);
				Ember.set(this, '__' + key, value);
			}
		}).meta(meta);
	} /**
    * @module Core/computed
    *
    */
});