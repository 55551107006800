define('@busy-web/ember-date-time/--private/utils/each-type', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = eachType;
	function eachType(target, type, cb) {
		Object.keys(target.__proto__).forEach(function (name) {
			if (target[name] && target[name]._meta) {
				var meta = target[name]._meta;
				if (meta && meta.type === type) {
					cb.call(target, Ember.get(target, name), name, meta);
				}
			}
		});
	} /**
    * @module Core/Utils
    *
    */
});