define('@busy-web/ember-date-time/utils/clock/svg', ['exports', 'snapsvg', '@busy-web/ember-date-time/utils/clock/string', '@busy-web/ember-date-time/utils/clock/data', '@busy-web/ember-date-time/utils/clock/base'], function (exports, _snapsvg, _string, _data, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var SVG = function (_Base) {
		_inherits(SVG, _Base);

		/**
   * @constructor
   *
   */
		function SVG(el) {
			var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			_classCallCheck(this, SVG);

			(false && !(!Ember.isNone(el)) && Ember.assert("el is required for class SVG", !Ember.isNone(el)));

			var _this = _possibleConstructorReturn(this, (SVG.__proto__ || Object.getPrototypeOf(SVG)).call(this));

			_this.setProps({ el: el, opts: opts, snap: null });
			return _this;
		}

		_createClass(SVG, [{
			key: 'createSnap',
			value: function createSnap() {
				this.__snap = new _snapsvg.Snap(this.__el);
				return this;
			}
		}, {
			key: 'has',
			value: function has(type, num) {
				(false && !(!Ember.isEmpty(num)) && Ember.assert('num is required in svg.at, you pased type {' + (typeof num === 'undefined' ? 'undefined' : _typeof(num)) + '} ' + num, !Ember.isEmpty(num)));

				if (typeof num === 'number') {
					num = (0, _string.numberToString)(num);
				}
				return !Ember.isEmpty(this.snap.select('.--svg-' + type + '-' + num));
			}
		}, {
			key: 'at',
			value: function at(num) {
				(false && !(!Ember.isEmpty(num)) && Ember.assert('num is required in svg.at, you pased type {' + (typeof num === 'undefined' ? 'undefined' : _typeof(num)) + '} ' + num, !Ember.isEmpty(num)));

				if (typeof num === 'number') {
					num = (0, _string.numberToString)(num);
				}

				var text = this.snap.select('.--svg-text-' + num);
				var arm = this.snap.select('.--svg-arm-' + num);
				var plot = this.snap.select('.--svg-plot-' + num);
				var path = this.snap.select('.--svg-path-' + num);

				(false && !(!Ember.isEmpty(arm)) && Ember.assert('"arm" was not found with number: ' + num, !Ember.isEmpty(arm)));
				(false && !(!Ember.isEmpty(plot)) && Ember.assert('"plot" was not found with number: ' + num, !Ember.isEmpty(plot)));
				(false && !(!Ember.isEmpty(path)) && Ember.assert('"path" was not found with number: ' + num, !Ember.isEmpty(path)));


				return { path: path, arm: arm, plot: plot, text: text };
			}
		}, {
			key: 'minMaxHandler',
			value: function minMaxHandler(type, num, min, max, timestamp) {
				var date = (0, _data.getDate)(type, num, timestamp);
				var bounds = (0, _data.isInBounds)(date, min, max);
				if (bounds.isBefore || bounds.isAfter) {
					this.disablePlot(num);
				} else {
					this.enablePlot(num);
				}
			}
		}, {
			key: 'clean',
			value: function clean(start, end) {
				for (var i = start; i <= end; i++) {
					if (i % this.selectRounder === 0) {
						var _at = this.at(i),
						    text = _at.text,
						    arm = _at.arm,
						    plot = _at.plot;

						if (!Ember.isNone(text)) {
							text.removeClass('selected');
						}
						arm.insertBefore(this.face);
						plot.insertBefore(this.face);
					}
				}
			}
		}, {
			key: 'unselectPlot',
			value: function unselectPlot(value) {
				var _at2 = this.at(value),
				    text = _at2.text,
				    arm = _at2.arm,
				    plot = _at2.plot;

				var next = void 0,
				    prev = void 0;
				if (this.has('plot', value - this.selectRounder)) {
					prev = this.at(value - this.selectRounder);
				} else {
					next = this.at(value + this.selectRounder);
				}

				if (!Ember.isNone(text)) {
					text.removeClass('selected');
					if (prev) {
						text.insertAfter(prev.path);
					} else {
						text.insertBefore(next.path);
					}
				}

				if (prev) {
					plot.insertAfter(prev.plot);
					arm.insertAfter(prev.plot);
				} else {
					arm.insertBefore(next.arm);
					plot.insertBefore(next.arm);
				}
			}
		}, {
			key: 'selectPlot',
			value: function selectPlot(value) {
				var _at3 = this.at(value),
				    text = _at3.text,
				    arm = _at3.arm,
				    plot = _at3.plot;

				if (!Ember.isNone(text) && !text.hasClass('selected')) {
					text.addClass('selected');
				}

				arm.appendTo(this.snap);
				plot.appendTo(this.snap);

				if (!Ember.isNone(text) && !text.hasClass('selected')) {
					var fill = this.__opts.fillColor || 'white';
					text.animate({ fill: fill }, 100, _snapsvg.mina.easein).appendTo(this.snap);
				}
			}
		}, {
			key: 'enablePlot',
			value: function enablePlot(value) {
				var _at4 = this.at(value),
				    text = _at4.text,
				    path = _at4.path;

				path.removeClass('disabled');
				if (!Ember.isNone(text)) {
					text.removeClass('disabled');
				}
			}
		}, {
			key: 'disablePlot',
			value: function disablePlot(value) {
				var _at5 = this.at(value),
				    text = _at5.text,
				    path = _at5.path;

				if (!path.hasClass('disabled')) {
					path.addClass('disabled');
				}

				if (!Ember.isNone(text) && !text.hasClass('disabled')) {
					text.addClass('disabled');
				}
			}
		}, {
			key: 'snap',
			get: function get() {
				if (!this.__snap) {
					this.createSnap();
				}
				return this.__snap;
			}
		}, {
			key: 'face',
			get: function get() {
				return this.snap.select('.--svg-face');
			}
		}, {
			key: 'pivot',
			get: function get() {
				return this.snap.select('.--svg-pivot');
			}
		}, {
			key: 'selectRounder',
			get: function get() {
				return this.__opts.selectRounder && this.__opts.selectRounder > 1 ? this.__opts.selectRounder : 1;
			}
		}]);

		return SVG;
	}(_base.default);

	exports.default = SVG;
});