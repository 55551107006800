define('@busy-web/components/utils/sortable-object', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = sortableObject;


	/***/
	var SORT_STATE = ['not-sorted', 'asc', 'desc'];

	/**
  * Sort object manages the sort state for sortable lists
  *
  * @class SortableObject
  * @extends EmberObject
  */
	/**
  * @module Utils
  *
  */
	var SortableObject = Ember.Object.extend({

		/**
   * properties
   */
		id: '',
		modelAttr: '',
		sortState: 0,
		header: '',
		sortable: true,

		/**
   * String generated for showing a table header
   *
   * @public
   * @property headerName
   * @type {string}
   */
		headerName: Ember.computed('header', 'modelAttr', function () {
			if (!Ember.isEmpty(Ember.get(this, 'header'))) {
				return Ember.get(this, 'header');
			}
			return Ember.String.dasherize(Ember.get(this, 'modelAttr')).split('-').map(function (k) {
				return Ember.String.classify(k);
			}).join(' ');
		}),

		/**
   * boolean state property to see if the sortState is active
   * meaning the state is 1 (asc) or 2 (desc) and not 0 (not sorted)
   *
   * @public
   * @property isActive
   * @type {boolean}
   */
		isActive: Ember.computed('sortState', function () {
			return Ember.get(this, 'sortState') !== 0;
		}),

		/**
   * Text representation of the sortState
   *  0 => not-sorted
   *  1 => asc
   *	2 => desc
   *
   * @public
   * @property sortDir
   * @type {string}
   */
		sortDir: Ember.computed('sortState', function () {
			return SORT_STATE[Ember.get(this, 'sortState')];
		}),

		asc: Ember.computed('sortState', function () {
			return Ember.get(this, 'sortState') === 1;
		}),

		desc: Ember.computed('sortState', function () {
			return Ember.get(this, 'sortState') === 2;
		}),

		toggleState: function toggleState() {
			if (Ember.get(this, 'sortable')) {
				Ember.set(this, 'sortState', Ember.get(this, 'sortState') === 1 ? 2 : 1);
			}
		},
		resetState: function resetState() {
			Ember.set(this, 'sortState', 0);
		},
		getState: function getState() {
			return {
				sortBy: Ember.get(this, 'modelAttr'),
				sortDir: SORT_STATE[Ember.get(this, 'sortState')]
			};
		}
	});

	function sortableObject(meta) {
		// get header if it was provided
		var header = Ember.get(meta, 'header');
		if (Ember.isNone(header)) {
			header = '';
		}

		// get modelAttr or create it from machineName or header
		var modelAttr = Ember.get(meta, 'modelAttr');
		if (Ember.isEmpty(modelAttr)) {
			var machineName = Ember.get(meta, 'machineName');
			if (!Ember.isEmpty(machineName)) {
				modelAttr = machineName;
			} else if (!Ember.isEmpty(header)) {
				modelAttr = Ember.String.camelize(header);
			}
		}

		(false && !(!Ember.isEmpty(modelAttr)) && Ember.assert("SortableObject requires a modelAttr, but modelAttr could not be found of inferred", !Ember.isEmpty(modelAttr)));


		// get id or create it from modelAttr
		var id = Ember.get(meta, 'id');
		if (Ember.isEmpty(id)) {
			id = Ember.String.dasherize(modelAttr);
		}

		// get sort state or set default sortState to 0
		var sortState = Ember.get(meta, 'sortState');
		if (Ember.isEmpty(sortState)) {
			sortState = 0;
		} else if (typeof sortState === 'string' && SORT_STATE.indexOf(sortState) !== -1) {
			sortState = SORT_STATE.indexOf(sortState);
		} else if (typeof sortState === 'number' && SORT_STATE[sortState] === undefined) {
			sortState = 0;
		} else {
			sortState = 0;
		}

		var sortable = Ember.get(meta, 'sortable');
		if (sortable !== false) {
			sortable = true;
		}

		return SortableObject.create({ id: id, modelAttr: modelAttr, sortState: sortState, header: header, sortable: sortable });
	}
});