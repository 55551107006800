define('@busy-web/utils/currency', ['exports', '@busy-web/utils/utils/currency-codes', '@busy-web/utils/assert'], function (exports, _currencyCodes, _assert) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * @module Utils
  *
  */
	var Currency = Ember.Object.extend();

	Currency.reopenClass({
		codes: function codes() {
			_assert.default.funcNumArgs(arguments, 0, true);

			return _currencyCodes.default.sortBy('code').mapBy('code');
		},
		code: function code(type) {
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isString(type);

			type = type.toUpperCase();
			return _currencyCodes.default.sortBy('code').findBy('code', type);
		},
		country: function country(type) {
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isString(type);

			type = type.toLowerCase();
			return _currencyCodes.default.filter(function (c) {
				return (c.countries || []).indexOf(type) > -1;
			});
		},
		number: function number(type) {
			_assert.default.funcNumArgs(arguments, 1, true);
			if (typeof type === 'number') {
				type = ('000' + type).substr(-3);
			}

			return _currencyCodes.default.findBy('number', type);
		},
		countries: function countries() {
			_assert.default.funcNumArgs(arguments, 0, true);

			var list = [];
			_currencyCodes.default.mapBy('countries').forEach(function (item) {
				if (!Ember.isEmpty(item)) {
					item.forEach(function (country) {
						if (list.indexOf(country) === -1) {
							list.push(country);
						}
					});
				}
			});
			return list;
		}
	});

	exports.default = Currency;
});