define('@busy-web/data/mixins/rpc-adapter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Mixin.create({
		/**
   * Override for query to redirect rcp queries
   *
   * @private
   * @method query
   */
		query: function query(store, type, _query) {
			if (type.proto()._isRPC) {
				return this.queryRPC(store, type, _query).then(function (data) {
					if (!Ember.isArray(data.data)) {
						data.data = Ember.A([data.data]);
					}
					return data;
				});
			} else {
				return this._super.apply(this, arguments);
			}
		},


		/**
   * Makes a query to an rpc api model
   *
   * @public
   * @method queryRPC
   * @param store {DS.Store}
   * @param type {DS.ModelType}
   * @param query {object}
   * @return {RSVP.Promise}
   */
		queryRPC: function queryRPC(store, type, query) {
			var promise = void 0;
			if (Ember.FEATURES.isEnabled('ds-improved-ajax')) {
				var request = this._requestFor({ store: store, type: type, query: query, requestType: 'query', _requestType: 'rpc' });
				promise = this._makeRequest(request);
			} else {
				var _requestType = 'rpc';
				var url = this.buildURL(type.proto()._clientName, null, null, 'query', query);

				query = this.dataForRequest({ type: type, _requestType: _requestType, query: query });

				if (this.sortQueryParams) {
					query = this.sortQueryParams(query);
				}

				promise = this.ajax(url, 'POST', { _requestType: _requestType, data: query });
			}

			return promise;
		},
		rpcRequest: function rpcRequest(store, modelName, method) {
			var query = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
			var host = arguments[4];

			var type = Ember.Object.extend({
				_methodName: method,
				_clientName: modelName,
				_hostName: host
			}).reopenClass({ modelName: modelName });

			return this.queryRPC(store, type, query);
		},
		ajaxOptions: function ajaxOptions(url, type, options) {
			var isRPC = Ember.get(options || {}, '_requestType') === 'rpc';
			var hash = this._super.apply(this, arguments);

			if (isRPC) {
				hash.data = JSON.stringify(hash.data);
				hash.contentType = 'application/json; charset=utf-8';
				hash.disableBatch = true;
			}

			return hash;
		},
		dataForRequest: function dataForRequest(params) {
			if (params._requestType === 'rpc') {
				var method = params.type.proto()._methodName;
				(false && !(!Ember.isNone(method)) && Ember.assert('The rpc model has no _methodName to call.', !Ember.isNone(method)));


				return {
					method: method,
					params: params.query,
					id: '1',
					jsonrpc: '2.0'
				};
			}
			return this._super(params);
		},
		headersForRequest: function headersForRequest(params) {
			var headers = this._super(params);
			if (params._requestType === 'rpc') {
				headers.Accept = 'application/json; charset=utf-8';
			}
			return headers;
		},
		methodForRequest: function methodForRequest(params) {
			if (params._requestType === 'rpc') {
				return "POST";
			}
			return this._super(params);
		},
		urlForRequest: function urlForRequest(params) {
			var url = this._super(params);
			if (params._requestType === 'rpc') {
				var client = params.type.proto()._clientName;
				if (params.type.modelName !== client) {
					var regx = new RegExp(params.type.modelName);
					url = url.replace(regx, client);
				}

				var host = params.type.proto()._hostName;
				if (!Ember.isEmpty(host) && Ember.get(this, 'host') !== host) {
					var _regx = new RegExp(Ember.get(this, 'host'));
					url = url.replace(_regx, host);
				}
			}
			return url;
		},
		_requestFor: function _requestFor(params) {
			var res = this._super(params);
			if (params._requestType === 'rpc') {
				res._requestType = 'rpc';
			}
			return res;
		},
		_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
			var hash = this._super(request) || {};

			if (request._requestType === 'rpc') {
				hash.contentType = 'application/json; charset=utf-8';
				hash.data = JSON.stringify(request.data);
				hash.dataType = "json";
				hash.disableBatch = true;
			}

			return hash;
		},
		handleResponse: function handleResponse(status, headers, payload, requestData) {
			if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && Ember.get(payload, 'jsonrpc')) {
				var result = Ember.get(payload, 'result');

				if (Ember.isNone(result)) {
					result = this.transformStatusCodeToPayload(status, payload);
				}

				payload = result;
			}

			return this._super(status, headers, payload, requestData);
		},


		// If the server response does not contain a payload, attempt to produce a payload based on the status code.
		transformStatusCodeToPayload: function transformStatusCodeToPayload(status, payload) {
			var statusCode = parseInt(status, 10);
			var payloadError = Ember.get(payload, 'error');
			var payloadWarning = Ember.get(payload, 'warning');
			var payloadSuccess = Ember.get(payload, 'success');

			// if the request has explicitly failed, send it back as is and Ember Data will throw
			if (!Ember.isNone(payloadSuccess) && payloadSuccess === false) {
				return payload;
			}

			var errors = void 0;
			var warnings = void 0;
			var code = [statusCode];

			if (!Ember.isNone(payloadError)) {
				errors = Array.isArray(payloadError) ? payloadError : [payloadError];
			}

			if (!Ember.isNone(warnings)) {
				warnings = Array.isArray(payloadWarning) ? payloadWarning : [payloadWarning];
			}

			var success = statusCode === 200 && Ember.isNone(errors);
			var data = { success: success };
			var debug = { warnings: warnings, errors: errors };

			if (!success) {
				code = errors.map(function (err) {
					return Ember.get(err, 'code');
				});
			}

			var result = {
				code: code,
				data: data,
				debug: debug,
				returned_rows: 1,
				success: true,
				supported: 200,
				total_rows: 1
			};

			return result;
		}
	});
});