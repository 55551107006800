define('ember-google-maps/components/g-map/circle', ['exports', 'ember-google-maps/components/g-map/marker'], function (exports, _marker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marker.default.extend({
    _type: 'circle',
    _requiredOptions: ['center', 'radius'],

    radius: 500,
    center: Ember.computed.reads('position'),

    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Circle(Ember.get(this, '_options')));
    }
  });
});