define('@busy-web/ember-date-time/components/private/date-picker', ['exports', '@busy-web/ember-date-time/utils/state', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/templates/components/private/date-picker', '@busy-web/ember-date-time/utils/constant'], function (exports, _state2, _time2, _datePicker, _constant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    classNames: ['busyweb', 'emberdatetime', 'p-date-picker'],
    layout: _datePicker.default,

    dayKey: _constant.DAY_FLAG,
    monthKey: _constant.MONTH_FLAG,
    yearKey: _constant.YEAR_FLAG,

    stateManager: null,

    /**
     * timestamp that is passed in when using date picker
     *
     * @private
     * @property timestamp
     * @type Number
     */
    timestamp: null,

    /**
     * timestamp that controls the dates for the calendar
     *
     * @private
     * @property calendarDate
     * @type Number
     */
    calendarDate: null,

    /**
     * day of the month shown on the calendar header - based off timestamp
     *
     * @private
     * @property day
     * @type String
     */
    day: null,

    /**
     * month of year shown on the calendar header - based off timestamp
     *
     * @private
     * @property month
     * @type String
     */
    month: null,

    /**
     * year shown on the calendar header - based off timestamp
     *
     * @private
     * @property year
     * @type String
     */
    year: null,

    /**
     * array of all days in the current month of calendarTimestamp
     *
     * @private
     * @property daysArray
     * @type Array
     */
    daysArray: null,

    /**
     * based on daysArray, Adds blank days to the front and back of array according to starting day of month
     *
     * @private
     * @property completeDaysArray
     * @type Array
     */
    completeDaysArray: null,

    /**
     * based on completeDaysArray, Adds current day and minDate - maxDate properties
     *
     * @private
     * @property completeArray
     * @type Array
     */
    completeArray: null,

    /**
     * based on completeArray, groups days into 6 week arrays
     *
     * @private
     * @property groupedArray
     * @type Array
     */
    groupedArray: null,

    /**
     * becomes string 'active' (binded to classes in template) if monthActive is active
     *
     * @private
     * @property monthActive
     * @type String
     */
    months_active: false,

    /**
     * becomes string 'active' (binded to classes in template) if dayActive is active
     *
     * @private
     * @property monthActive
     * @type dayActive
     */
    days_active: false,

    /**
     * becomes string 'active' (binded to classes in template) if yearActive is active
     *
     * @private
     * @property yearActive
     * @type String
     */
    years_active: false,

    isPrev: true,
    isNext: true,

    /**
     * @private
     * @method init
     * @constructor
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.updateTime();
      this.updateActiveSection();
    },


    updateTime: Ember.observer('stateManager.timestamp', 'stateManager.calendarDate', function () {
      var timestamp = Ember.get(this, 'stateManager.timestamp');

      var calendarDate = Ember.get(this, 'stateManager.calendarDate');
      if (!calendarDate) {
        calendarDate = timestamp;
      }

      if (Ember.get(this, 'timestamp') !== timestamp) {
        this.setTimestamp(Ember.get(this, 'stateManager.timestamp'));
      }

      if (Ember.get(this, 'calendarDate') !== calendarDate) {
        this.setCalendarDate(Ember.get(this, 'stateManager.calendarDate') || Ember.get(this, 'stateManager.timestamp'));
      }
    }),

    /**
     * updates to the new active header  (day, month, or year)
     *
     * @private
     * @method updateActiveSection
     */
    updateActiveSection: Ember.observer('stateManager.section', function () {
      var _this = this;

      var section = Ember.get(this, 'stateManager.section');
      if (!Ember.isNone(section)) {
        if (section === _constant.WEEKDAY_FLAG) {
          section === _constant.DAY_FLAG;
        }

        section = Ember.String.camelize(section);
        var statusType = [_constant.DAY_FLAG, _constant.MONTH_FLAG, _constant.YEAR_FLAG];

        // ensure the active status applies to the calendar
        if (statusType.indexOf(section) !== -1) {
          // reset active status
          statusType.forEach(function (name) {
            return Ember.set(_this, name + '_active', false);
          });

          // set new active status
          Ember.set(this, section + '_active', true);
        }
      }
    }),

    /**
     * re configures the calendar when calendarDate is changed, sets the monthYear calendar header
     *
     * @private
     * @method monthYear
     */
    monthYear: Ember.computed('calendarDate', 'stateManager.range', function () {
      var calendarObject = (0, _time2.default)(Ember.get(this, 'calendarDate'));
      this.buildDaysArrayForMonth();
      return calendarObject.format('MMMM YYYY');
    }),

    /**
     * makes moment objects for each day in month, disables them if they exceed max/min date
     *
     * @private
     * @method buildDaysArrayForMonth
     */
    buildDaysArrayForMonth: function buildDaysArrayForMonth() {
      var calendarDate = Ember.get(this, 'calendarDate');
      var minDate = Ember.get(this, 'stateManager.minDate');
      var maxDate = Ember.get(this, 'stateManager.maxDate');

      var _ref = Ember.get(this, 'stateManager.range') || [],
          _ref2 = _slicedToArray(_ref, 2),
          startRange = _ref2[0],
          endRange = _ref2[1];

      var currentCalendar = (0, _time2.default)(calendarDate);
      var currentTime = (0, _time2.default)(Ember.get(this, 'timestamp'));
      var firstDay = (0, _time2.default)(calendarDate).startOf('month');
      var lastDay = (0, _time2.default)(calendarDate).endOf('month').date();

      var start = firstDay.day();
      var currentDay = firstDay;

      if (start === 0) {
        currentDay.subtract(7, 'days');
        start = 7;
      } else {
        currentDay.subtract(start, 'days');
      }

      var daysInCalendar = 28;
      if (start + lastDay >= 35) {
        daysInCalendar = 42;
      } else if (start + lastDay > 28) {
        daysInCalendar = 35;
      }

      var daysArray = Ember.A();
      for (var i = 0; i < daysInCalendar; i++) {
        var dayState = (0, _state2.default)({ timestamp: currentDay.valueOf(), minDate: minDate, maxDate: maxDate, type: 'date' });
        dayState.set('weekNumber', Math.ceil((i + 1) / 7));

        if (dayState.get('date').year() === currentCalendar.year()) {
          dayState.set('isCurrentYear', true);

          if (dayState.get('date').month() === currentCalendar.month()) {
            dayState.set('isCurrentMonth', true);
          }
        }

        if (dayState.get('date').year() === currentTime.year() && dayState.get('date').month() === currentTime.month() && dayState.get('date').date() === currentTime.date()) {
          dayState.set('isCurrentDay', true);
        }

        if (startRange && endRange) {
          var mili = dayState.get('date').valueOf();
          if (startRange <= mili && mili <= endRange) {
            dayState.set('isRange', true);

            if (mili === startRange) {
              dayState.set('isRangeStart', true);
            }

            if (mili === endRange) {
              dayState.set('isRangeEnd', true);
            }
          }
        }

        daysArray.pushObject(dayState);
        currentDay.add(1, 'days');
      }

      this.groupByWeeks(daysArray);
    },

    /**
    * groups days into week objects
    *
    * @private
    * @method groupByWeeks
    */
    groupByWeeks: function groupByWeeks(completeArray) {
      var grouped = Ember.A([]);

      grouped.pushObject(completeArray.filterBy('weekNumber', 1));
      grouped.pushObject(completeArray.filterBy('weekNumber', 2));
      grouped.pushObject(completeArray.filterBy('weekNumber', 3));
      grouped.pushObject(completeArray.filterBy('weekNumber', 4));
      grouped.pushObject(completeArray.filterBy('weekNumber', 5));
      grouped.pushObject(completeArray.filterBy('weekNumber', 6));

      Ember.set(this, 'groupedArray', grouped);
    },


    /**
     * puts days into week objects
     *
     * @private
     * @method inRange
     * @param lower {number} first number in week
     * @param upper {number} last number in week
     * @return {boolean} true if day is in week, otherwise false
     */
    inRange: function inRange(lower, upper) {
      return function (each, index) {
        return index >= lower && index < upper;
      };
    },


    /**
     * receives a moment object and sets it to timestamp
     *
     * @private
     * @method setTimestamp
     * @param timestamp {number} date time in milliseconds
     */
    setTimestamp: function setTimestamp(timestamp) {
      Ember.set(this, 'timestamp', timestamp);

      var time = (0, _time2.default)(timestamp);
      Ember.set(this, 'year', time.format('YYYY'));
      Ember.set(this, 'month', time.format('MMM'));
      Ember.set(this, 'day', time.format('DD'));
      Ember.set(this, 'dayOfWeek', time.format('ddd'));
    },


    /**
     * receives a moment object and sets it to calendarTimestamp
     *
     * @private
     * @method setCalendarDate
     * @param timestamp {number} timestamp in milliseconds
     */
    setCalendarDate: function setCalendarDate(timestamp) {
      this.validateNextPrev(timestamp);
      Ember.set(this, 'calendarDate', timestamp);
    },


    /**
     * validates the next and prev arrows can move in their
     * respective directions
     *
     * @method validateNextPrev
     * @param time {number} timestamp in milliseconds
     * @return {void}
     */
    validateNextPrev: function validateNextPrev(time) {
      var _this2 = this;

      // validates the timestamp is inbounds for the
      // given `name: string` (isPrev | isNext) and `time: number`
      var validateTime = function validateTime(name, npTime) {
        // sets the prop according to `name: string` and `inBounds: boolean`
        var update = function update(inBounds) {
          return Ember.set(_this2, name, inBounds);
        };

        // call update to set the property
        update(name === 'isPrev' ? !_this2.isBeforeMin(npTime) : !_this2.isAfterMax(npTime));
      };

      // validate that isPrev and isNext will be inBounds
      // if the next or prev arrows are clicked
      validateTime('isPrev', getPrevDate(time));
      validateTime('isNext', getNextDate(time));
    },
    isBeforeMin: function isBeforeMin(time) {
      // if the timestamp gets set to a date before the
      // minDate then allow the back arrow to go back as
      // far as the current timestamp
      if (_time2.default.isDateBefore(Ember.get(this, 'timestamp'), time)) {
        return false;
      }
      return _time2.default.isDateBefore(time, Ember.get(this, 'stateManager.minDate'));
    },
    isAfterMax: function isAfterMax(time) {
      // if the timestamp gets set to a date after the
      // maxDate then allow the forward arrow to go forward as
      // far as the current timestamp
      if (_time2.default.isDateAfter(Ember.get(this, 'timestamp'), time)) {
        return false;
      }
      return _time2.default.isDateAfter(time, Ember.get(this, 'stateManager.maxDate'));
    },
    triggerUpdate: function triggerUpdate(flag) {
      (false && !([_constant.YEAR_FLAG, _constant.MONTH_FLAG, _constant.WEEKDAY_FLAG, _constant.DAY_FLAG].indexOf(flag) !== -1) && Ember.assert('flag is required and must be on of [' + _constant.YEAR_FLAG + ', ' + _constant.MONTH_FLAG + ', ' + _constant.WEEKDAY_FLAG + ', ' + _constant.DAY_FLAG + ']', [_constant.YEAR_FLAG, _constant.MONTH_FLAG, _constant.WEEKDAY_FLAG, _constant.DAY_FLAG].indexOf(flag) !== -1));


      Ember.set(this, 'calendarActiveSection', flag);
      this.sendAction('onUpdate', flag, Ember.get(this, 'timestamp'), Ember.get(this, 'calendarDate'));
    },


    actions: {

      /**
       * sets the timestamp to the clicked day
       *
       * @param day {object} moment object of the clicked day
       * @event dayClicked
       */
      dayClicked: function dayClicked(dayState) {
        if (!Ember.get(dayState, 'isDisabled')) {
          var day = Ember.get(dayState, 'date');
          var time = (0, _time2.default)(Ember.get(this, 'timestamp')).year(day.year()).month(day.month()).date(day.date());

          this.setTimestamp(time.timestamp());
          this.setCalendarDate(time.timestamp());

          this.triggerUpdate(_constant.DAY_FLAG);
        }
      },


      /**
       * subtracts 1 month to the calendarDate
       *
       * @event subtractMonth
       */
      subtractMonth: function subtractMonth() {
        // get next date
        var calTime = getPrevDate(Ember.get(this, 'calendarDate'));

        if (!this.isBeforeMin(calTime)) {
          // set calendar date
          this.setCalendarDate(calTime);

          // trigger update
          this.triggerUpdate(_constant.MONTH_FLAG);
        }
      },


      /**
       * adds 1 month to the calendarDate
       *
       * @event addMonth
       */
      addMonth: function addMonth() {
        // get next date
        var calTime = getNextDate(Ember.get(this, 'calendarDate'));

        if (!this.isAfterMax(calTime)) {
          // set calendar date
          this.setCalendarDate(calTime);

          // trigger update
          this.triggerUpdate(_constant.MONTH_FLAG);
        }
      },


      /**
       * Sets the new header selection
       *
       * @event activateHeader
       */
      activateHeader: function activateHeader(section) {
        // trigger update for input to reposition selection
        this.triggerUpdate(section);
      }
    }
  });


  /**
   * Creates a timestamp for the first day of the next months
   *
   * @method getNextDate
   * @param time {number} timestamp in milliseconds
   * @return {number} timestamp in milliseconds
   */
  var getNextDate = function getNextDate(time) {
    return (0, _time2.default)(time).add(1, 'months').startOf('month').timestamp();
  };

  /**
   * Creates a timestamp for the last day of the previous months
   *
   * @method getPrevDate
   * @param time {number} timestamp in milliseconds
   * @return {number} timestamp in milliseconds
   */
  var getPrevDate = function getPrevDate(time) {
    return (0, _time2.default)(time).subtract(1, 'month').endOf('month').timestamp();
  };
});