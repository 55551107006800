define('@busy-web/graylog/utils/system-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = systemInfo;


	var USER_AGENTS = [{ regex: /OPR/, name: 'Opera', version: function version(a) {
			return a.replace(/^((?!OPR)[\s\S])*OPR\/([\s\S]*)$/, '$2');
		} }, { regex: /Edge/, name: "Microsoft Edge", version: function version(a) {
			return a.replace(/^((?!Edge)[\s\S])*Edge\/([\s\S]*)$/, '$2');
		} }, { regex: /CriOS/, name: "Chrome", version: function version(a) {
			return a.replace(/^((?!CriOS)[\s\S])*CriOS\/([^ ]*)[\s\S]*$/, '$2');
		} }, { regex: /FxiOS/, name: "Firefox", version: function version(a) {
			return a.replace(/^((?!FxiOS)[\s\S])*FxiOS\/([^ ]*)[\s\S]*$/, '$2');
		} }, { regex: /Chrome/, name: "Chrome", version: function version(a) {
			return a.replace(/^((?!Chrome)[\s\S])*Chrome\/([^ ]*)[\s\S]*$/, '$2');
		} }, { regex: /Safari/, name: "Safari", version: function version(a) {
			return a.replace(/^((?!Safari)[\s\S])*Safari\/([\s\S]*)$/, '$2');
		} }, { regex: /MSIE/, name: "Internet Explorer", version: function version(a) {
			return a.replace(/^((?!MSIE)[\s\S])*MSIE ([^;]*)[\s\S]*$/, '$2');
		} }, { regex: /Firefox/, name: "Firefox", version: function version(a) {
			return a.replace(/^((?!Firefox)[\s\S])*Firefox\/([\s\S]*)$/, '$2');
		} }]; /**
         * @module utils
         *
         */


	var PLATFORMS = [{ regex: /Android/, name: 'Android' }, { regex: /iPhone|iPad|iPod/, name: 'iOS' }, { regex: /Macintosh/, name: 'Mac OS X' }, { regex: /Windows/, name: 'Windows' }, { regex: /Linux/, name: 'Linux' }];

	function systemInfo() {
		var userAgent = window.navigator.userAgent;

		var browser = void 0;
		var version = void 0;
		USER_AGENTS.forEach(function (br) {
			if (Ember.isEmpty(browser) && br.regex.test(userAgent)) {
				browser = br.name;
				version = br.version(userAgent);
			}
		});

		browser = browser || 'unknown';
		version = version || '0';

		var platform = void 0;
		PLATFORMS.forEach(function (pl) {
			if (Ember.isEmpty(platform) && pl.regex.test(userAgent)) {
				platform = pl.name;
			}
		});

		platform = platform || 'unknown';

		return { browser: browser, version: version, platform: platform };
	}
});