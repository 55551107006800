define('@busy-web/ember-date-time/--private/handlers/base', ['exports', '@busy-web/ember-date-time/--private/utils/each-type', '@busy-web/ember-date-time/--private/computed/property', '@busy-web/ember-date-time/--private/utils/map'], function (exports, _eachType, _property, _map) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend(Ember.Evented, {
		__handler: 'base',

		__setup: function __setup(params) {
			var attrs = parseAttributes.call(this, params);
			this.setupAttrs(attrs);
		},
		setupAttrs: function setupAttrs(attrs) {
			var _this = this;

			this.trigger('setupAttrs', attrs);
			attrs.forEach(function (item) {
				var key = item.key,
				    value = item.value;

				Ember.set(_this, key, value);
			});
		},
		__update: function __update(params) {
			var _this2 = this;

			var attrs = parseAttributes.call(this, params);
			notifyUpdates.call(this, attrs, function () {
				_this2.updateAttrs(attrs);
			});
		},
		updateAttrs: function updateAttrs(attrs) {
			var _this3 = this;

			this.trigger('updateAttrs', attrs);
			attrs.forEach(function (item) {
				var key = item.key,
				    value = item.value;

				Ember.set(_this3, key, value);
			});
		},
		eachProperty: function eachProperty(cb) {
			(0, _eachType.default)(this, 'property', cb);
		},
		beforeUpdate: function beforeUpdate() {},
		afterUpdate: function afterUpdate() {},
		handlerFor: function handlerFor(name) {
			var manager = Ember.get(this, '__manager');
			(false && !(!Ember.isNone(manager)) && Ember.assert("manager does not exist or is not loaded yet", !Ember.isNone(manager)));

			return Ember.get(manager, name + 'Handler');
		}
	}).reopenClass({ property: _property.default });


	function changedProperty(key, value, oldVal) {
		return { key: key, value: value, oldVal: oldVal };
	}

	function parseAttributes(properties) {
		var attrs = (0, _map.createMap)();
		this.eachProperty(function (last, key) {
			var value = Ember.get(properties, key);
			if (value !== undefined) {
				attrs.set(key, changedProperty(key, value, last));
			}
		});
		return attrs;
	}

	function notifyUpdates(changed) {
		var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

		// call before changes
		this.beforeUpdate();

		// run updates
		cb.call(this);

		// call after changes
		var adjusted = this.afterUpdate(changed);
		if (!Ember.isEmpty(adjusted)) {
			changed = adjusted;
		}

		// trigger event update for listeners
		this.trigger('update', Ember.get(this, '__handler'), changed);
	}
});