define('@busy-web/data/mixins/json-api-serializer', ['exports', 'ember-uuid', '@busy-web/data/utils/query'], function (exports, _emberUuid, _query) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	var singleRequest = ['findRecord', 'queryRecord', 'updateRecord', 'createRecord', 'findBelongsTo'];

	/**
  * `Mixins/JSONAPISerializer`
  *
  * Converts a standard api response to a json-api response
  *
  * This is a mixin that can be added to a JSONAPISerializer to
  * convert an api response to a json-api response object before
  * the normalizeResponse has started.
  *
  * @class JSONAPISerializer
  * @namespace BusyData.Mixin
  * @extends Mixin
  */
	exports.default = Ember.Mixin.create({

		/**
   * Override of normalizeResponse in Ember Data serialize
   *
   * @private
   * @method normalizeResponse
   * @param store {Store}
   * @param primaryModelClass {DS.Model}
   * @param payload {object} json data
   * @param requestType {string} ember data request type
   * @return {object}
   */
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			var response = void 0;
			if (!payload.jsonapi) {
				if (requestType === 'deleteRecord') {
					// delete record should return a no content response
					response = {
						status: '204 No Content',
						data: null,
						jsonapi: { version: "1.0" }
					};

					if (payload.code && payload.code.length > 0) {
						response.status = '400 Bad Request';
						response.code = payload.code[0];
					}
				} else {
					response = this.convertResponse(store, primaryModelClass, payload, id, requestType);
				}
			} else {
				response = payload;
			}

			return this._super(store, primaryModelClass, response, id, requestType);
		},


		/**
   * Converts an api response to a json-api formatted response
   *
   * @private
   * @method convertResponse
   * @param store {Store}
   * @param primaryModelClass {DS.Model}
   * @param payload {object} json data
   * @param requestType {string} ember data request type
   * @return {object}
   */
		convertResponse: function convertResponse(store, primaryModelClass, payload, id, requestType) {
			// get the data object or data array from the payload
			var rawData = this.getDataFromResponse(payload, requestType);
			if (singleRequest.indexOf(requestType) !== -1) {
				if (rawData.length > 1) {
					throw new Error(requestType + ' must not return more than 1 record in Model [' + primaryModelClass.modelName + ']');
				}

				rawData = rawData[0] || null;
			}

			// the new json-api formatted object to return
			var json = {
				data: rawData,
				jsonapi: { version: '1.0' }
			};

			// get the meta properties as an object from the payload
			var meta = this.getMetaFromResponse(payload, requestType);

			// add meta data
			Ember.set(json, 'meta', meta);

			if (!Ember.isNone(json.data)) {
				// create a flat json-api object
				this.flattenResponseData(store, primaryModelClass, json);
			}

			// return the resposne
			return json;
		},


		/**
   * Gets the data from the response object. This is
   * meant to be overriden in a sub class to provide the path
   * to the data object in the api response.
   *
   * @public
   * @method getDataFromResponse
   * @param payload {object} api response json object
   * @param requestType {string} ember data request type
   * @return {object|array}
   */
		getDataFromResponse: function getDataFromResponse(payload /*, requestType */) {
			return payload;
		},


		/**
   * Gets the properties from the payload
   * that should go into the meta object.
   *
   * This must be returned as an object.
   *
   * @public
   * @method getMetaFromResponse
   * @param payload {object} api response object
   * @param requestType {string} the type of api request
   * @return {object}
   */
		getMetaFromResponse: function getMetaFromResponse() /*payload, requestType*/{
			return {};
		},


		/**
   * method to take a nested model json structure and convert it
   * to a json api flat json object
   *
   * @private
   * @method flattenResponseData
   * @param store {Store}
   * @param primaryModelClass {DS.Model}
   * @param data {object|array}
   * @return {object}
   */
		flattenResponseData: function flattenResponseData(store, primaryModelClass, json) {
			var _this = this;

			// array to track included models
			var included = [];
			var type = primaryModelClass.modelName;

			// the data object for the json-api response
			var _data = void 0;
			if (Ember.typeOf(json.data) === 'array') {
				// parse the data array objects
				_data = [];
				json.data.forEach(function (item) {
					_data.push(_this.buildJSON(store, primaryModelClass, type, item, included));
				});
			} else {
				// parse the data object
				_data = this.buildJSON(store, primaryModelClass, type, json.data, included);
			}

			// set the included data array
			json.included = included;

			// set the data property
			json.data = _data;
		},


		/**
   * Helper method to recursively parse the api response
   * and convert it to a flat json-api object
   *
   * @private
   * @method buildJSON
   * @param store {Store}
   * @param modelName {string}
   * @param type {string} the model type
   * @param json {object} the json object to parse
   * @param included {array} Included property for the json-api object
   * @return {object}
   */
		buildJSON: function buildJSON(store, primaryModelClass, type, json, included) {
			var primaryKey = Ember.get(this, 'primaryKey');

			// create a data type object
			var model = {
				id: Ember.get(json, primaryKey),
				type: this.nestedModelName(store, primaryModelClass, type),
				attributes: {},
				relationships: this.addRelationships(store, primaryModelClass, json)
			};

			// find all attributes and nested models
			for (var i in json) {
				if (json.hasOwnProperty(i)) {
					var value = Ember.get(json, i);
					// an object is going to be a nested model
					if (!Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
						var obj = void 0;
						// embers typeOf will tell if the object is an array
						if (Ember.typeOf(value) === 'array') {
							// get the nested models
							obj = this.buildNestedArray(store, primaryModelClass, i, value, included);
						} else {
							if (!Ember.get(value, primaryKey)) {
								value.id = _emberUuid.v4.apply(_emberUuid.v4, arguments);
							}
							// get the nested model
							obj = this.buildNested(store, primaryModelClass, i, value, included);
						}

						// add the obj to the relationship if it exists
						if (!Ember.isEmpty(obj)) {
							// add the relationship
							Ember.set(model.relationships, i, { data: obj });
						}
					} else {
						// add the property
						Ember.set(model.attributes, i, value);
					}
				}
			}
			return model;
		},
		addRelationships: function addRelationships(store, primaryModelClass, json) {
			var _this2 = this;

			var data = {};
			primaryModelClass.eachRelationship(function (type, opts) {
				// get the model name + -id and underscore it.
				var name = Ember.String.underscore(opts.type + '-id');
				if (opts.kind === 'hasMany') {
					name = 'id';
				}

				if (opts.options.referenceKey) {
					// set the name to the referenceKey
					name = Ember.String.underscore(opts.options.referenceKey);
				}

				var key = 'id';
				if (name === 'id') {
					// if the referenceKey is id then the key should be the model name + `-id` underscored
					key = Ember.String.underscore(primaryModelClass.modelName + '-id');
				}

				// foreignKey overrides all other key forms if set.
				// the key order ends up as (in order of override):  id, parent_model_name_id, foreign_key
				if (opts.options.foreignKey) {
					key = opts.options.foreignKey;
				}

				// get the id from the json object if it is set
				var id = Ember.get(json, name);

				// create data object
				var relationship = {};

				if (opts.kind === 'belongsTo' && id === '00000000-0000-0000-0000-000000000000') {
					relationship.data = null;
					data[Ember.String.dasherize(opts.key)] = relationship;
				} else {
					// for a belongsTo relationship set the data as an object with `id` and `type`
					if (Ember.isNone(opts.options.query) && opts.kind === 'belongsTo' && key === 'id') {
						relationship.data = null;

						if (!Ember.isNone(id)) {
							// add id for data object
							relationship.data = {
								type: opts.type,
								id: id
							};
						}

						// set the data object for the relationship
						data[Ember.String.dasherize(opts.key)] = relationship;
					} else {
						// for a has many set the data to an empty array
						// create data object
						var link = '';
						if (!Ember.isNone(opts.options.query)) {
							var queryParams = Ember.merge({}, opts.options.query);
							if (_this2.validateQuery(json, queryParams)) {
								link += _query.default.stringify(queryParams);

								if (opts.kind === 'belongsTo') {
									link += '&page_size=1';
								}
							}
						}

						if (!Ember.isNone(id)) {
							// add id for data object
							key = Ember.String.underscore(key);
							link += '&' + key + '=' + id;
						}

						if (!Ember.isEmpty(link)) {
							link = '?' + link.replace(/^&/, '');
							relationship.links = { related: '/' + opts.type + link };
						} else {
							if (opts.kind === 'belongsTo') {
								relationship.data = null;
							} else {
								relationship.data = [];
							}
						}

						data[Ember.String.dasherize(opts.key)] = relationship;
					}
				}
			});

			return data;
		},
		validateQuery: function validateQuery(json, query) {
			var _this3 = this;

			var isvalid = true;
			Object.keys(query).forEach(function (key) {
				var value = Ember.get(query, key);
				if (!Ember.isNone(value) && !Ember.isArray(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
					_this3.validateQuery(json, value);
					Ember.set(query, key, value);
				} else {
					if (/^self/.test(value)) {
						value = _this3.keyForAttribute(value.replace(/^self\./, ''));
						value = Ember.get(json, value);
						if (value !== undefined) {
							Ember.set(query, key, value);
						} else {
							isvalid = false;
						}
					}
				}
			});
			return isvalid;
		},
		getModelReturnType: function getModelReturnType(store, primaryModelClass, attr) {
			var kind = 'belongsTo';
			primaryModelClass.eachRelationship(function (key, opts) {
				if (key === attr) {
					kind = opts.kind;
				}
			});

			if (kind === 'hasMany') {
				return [];
			} else {
				return null;
			}
		},


		/**
   * Helper method to recursively parse the api response
   * and convert it to a flat json-api object
   *
   * @private
   * @method buildNested
   * @param store {Store}
   * @param modelName {string}
   * @param type {string} the model type
   * @param json {object} the json object to parse
   * @param included {array} Included property for the json-api object
   * @return {object}
   */
		buildNested: function buildNested(store, primaryModelClass, type, json, included) {
			// create the actual data model
			var _data = this.buildJSON(store, primaryModelClass, type, json, included);

			// add the data model to the included array
			included.push(_data);

			// create a relationship model representation
			var model = {
				type: this.nestedModelName(store, primaryModelClass, type),
				id: _data.id
			};

			return model;
		},


		/**
   * Helper method to recursively parse the api response
   * and convert it to a flat json-api object
   *
   * @private
   * @method buildNestedArray
   * @param store {Store}
   * @param modelName {string}
   * @param type {string} the model type
   * @param json {array} the json object to parse
   * @param included {array} Included property for the json-api object
   * @return {object}
   */
		buildNestedArray: function buildNestedArray(store, primaryModelClass, type, json, included) {
			var _this4 = this;

			var data = [];

			json.forEach(function (item) {
				// get the relationship data
				var model = _this4.buildNested(store, primaryModelClass, type, item, included);

				// add the relationships to the data return
				data.push(model);
			});

			// retrun the relationships
			return data;
		},
		nestedModelName: function nestedModelName(store, primaryModelClass, payloadKey) {
			if (primaryModelClass.modelName === payloadKey) {
				return payloadKey;
			} else {
				var result = payloadKey;
				primaryModelClass.eachRelationship(function (key, opts) {
					if (payloadKey === key) {
						result = opts.type;
					}
				});
				return result;
			}
		}
	});
});