define('@busy-web/ember-date-time/--private/manager', ['exports', '@busy-web/ember-date-time/--private/utils/each-type', '@busy-web/ember-date-time/--private/computed/handler', '@busy-web/ember-date-time/--private/handlers/date', '@busy-web/ember-date-time/--private/handlers/user-interface', '@busy-web/ember-date-time/--private/handlers/clock', '@busy-web/ember-date-time/--private/handlers/format'], function (exports, _eachType, _handler, _date, _userInterface, _clock, _format) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var NOT_FOUND = '--NOT_FOUND--'; /**
                                   * @module Core
                                   *
                                   */


	function getFromHandler(target, key) {
		var proto = target.__proto__;
		if (proto[key]) {
			return Ember.get(target, key);
		} else {
			return NOT_FOUND;
		}
	}

	exports.default = Ember.Object.extend({
		uiHandler: (0, _handler.default)(_userInterface.default),
		dateHandler: (0, _handler.default)(_date.default),
		clockHandler: (0, _handler.default)(_clock.default),
		formatHandler: (0, _handler.default)(_format.default),

		get: function get(key) {
			var val = getFromHandler(this, key);
			if (val === NOT_FOUND) {
				this.eachHandler(function (handler) {
					if (val === NOT_FOUND) {
						val = getFromHandler(handler, key);
					}
				});

				if (val === NOT_FOUND) {
					return null;
				}
			}
			return val;
		},
		setup: function setup(args) {
			this.eachHandler(function (handler) {
				return handler && handler.__setup(args);
			});
		},
		update: function update(args) {
			this.eachHandler(function (handler) {
				return handler && handler.__update(args);
			});
		},
		eachHandler: function eachHandler(cb) {
			(0, _eachType.default)(this, 'handler', cb);
		}
	});
});