define('@busy-web/components/components/bc-validate', ['exports', '@busy-web/components/templates/components/bc-validate'], function (exports, _bcValidate) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _bcValidate.default,
		classNames: ['bc-validate', 'bc-validate'],
		classNameBindings: ['invalid:invalid', 'isValid:valid'],

		type: 'text',
		placeholder: '',
		label: '',
		hint: '',
		error: '',
		value: '',
		invalidError: 'The input is invalid',
		requiredError: 'This is a required field',

		autocomplete: null,
		tabindex: 0,
		maxlength: "",

		required: false,
		disabled: false,
		isInvalid: false,
		isValid: false,
		isRequired: false,
		autofocus: false,
		showLabel: true,

		validateExpression: /.*/,

		labelString: Ember.computed('placeholder', 'label', 'required', function () {
			var label = this.get('label');
			if (Ember.isNone(label) || Ember.isEmpty(label)) {
				label = this.get('placeholder');
			}

			if (this.get('required')) {
				label = label + '*';
			}
			return label;
		}),

		init: function init() {
			this._super();
			(false && !(Ember.isEmpty(this.get('error'))) && Ember.assert('error is a private variable use invalidError or requiredError for error messages - ' + this.get('error'), Ember.isEmpty(this.get('error'))));
		},


		invalid: Ember.computed('isInvalid', 'isRequired', 'invalidError', 'requiredError', function () {
			var invalid = false;
			if (this.get('isInvalid')) {
				invalid = true;
				this.set('error', this.get('invalidError'));
			} else if (this.get('isRequired')) {
				invalid = true;
				this.set('error', this.get('requiredError'));
			} else {
				this.set('error', '');
			}
			return invalid;
		}),

		validate: function validate(value) {
			if (Ember.isEmpty(value) && this.get('required')) {
				this.set('isRequired', true);
			} else {
				this.set('isRequired', false);
			}

			var exp = this.get('validateExpression');
			exp = typeof exp === 'string' ? new RegExp(exp) : exp;
			return exp.test(value);
		},
		checkIfValid: function checkIfValid(value) {
			var valid = this.validate(value);
			this.set('isValid', valid);
			this.set('isInvalid', !valid);
		},
		reset: function reset() {
			this.set('isValid', false);
			this.set('isInvalid', false);
			this.set('isRequired', false);
		},


		actions: {
			hintAction: function hintAction() {
				this.sendAction('onClick');
			},
			focusOutAction: function focusOutAction(value) {
				this.checkIfValid(value);
				this.sendAction('onBlur', value);
			},
			enterAction: function enterAction(value) {
				this.sendAction('onSubmit', value);
			}
		}
	});
});