define('@busy-web/components/mixins/bind-outside-click', ['exports', '@busy-web/components/utils/event'], function (exports, _event) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		/**
   * binds a click event that will call the provided
   * action anytime a click occurs not within the component
   *
   * NOTE:
   * The action name provided will get called everytime a click occurs so the
   * action should only handle on thing like closing a drop down vs toggling a dropdown.
   * this will prevent accidently opening the dialog when it is closed and a click occurs.
   *
   * @public
   * @method bindClick
   * @return {void}
   */
		bindClick: function bindClick() {
			//assert('actionName must be a string', typeof actionName === 'string');

			// save this for later
			var _this = this;

			// get the components elementId
			var elementId = Ember.get(this, 'elementId');
			if (!Ember.isEmpty(Ember.get(this, '__debugContainerKey'))) {
				elementId += '-' + Ember.get(this, '__debugContainerKey');
			}

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				// bind the click listener
				(0, _event.bind)(document, 'click', elementId, function (evt) {
					// get the element that was clicked on
					var el = Ember.$(evt.target);

					// if the clicked element id does not match the components id and the clicked
					// elements parents dont have an id that matches then call the action provided
					if (el.attr('id') !== elementId && el.parents('#' + elementId).length === 0) {
						// send a call to the actionName
						evt.stopPropagation();
						evt.preventDefault();

						// call handler
						_this.onOutsideClick(evt);
						return false;
					}
				}, { capture: true, rebind: true });
			}
		},


		/**
   * method to unbind a click event that may have been
   * setup by this components
   *
   * @public
   * @method unbindClick
   */
		unbindClick: function unbindClick() {
			// get the components elementId
			var elementId = Ember.get(this, 'elementId');
			if (!Ember.isEmpty(Ember.get(this, '__debugContainerKey'))) {
				elementId += '-' + Ember.get(this, '__debugContainerKey');
			}

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				// unbind any previous click listeners
				(0, _event.unbind)(document, 'click', elementId);
			}
		},
		onOutsideClick: function onOutsideClick() {},


		/**
   * cleanup any click events registerd by this component
   *
   * @private
   * @method destroy
   */
		willDestroyElement: function willDestroyElement() {
			this._super();

			// unbind clicks
			this.unbindClick();
		}
	});
});