define('ember-google-maps/components/g-map/map-component', ['exports', 'ember-google-maps/mixins/process-options', 'ember-google-maps/mixins/register-events', 'ember-google-maps/utils/public-api'], function (exports, _processOptions, _registerEvents, _publicApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MapComponentAPI = {
    map: 'map',
    mapComponent: 'mapComponent',
    isInitialized: 'isInitialized',
    actions: {
      update: '_updateComponent'
    }
  };

  /**
   * @class MapComponent
   * @module ember-google-maps/components/g-map/map-component
   * @namespace GMap
   * @extends Component
   * @uses ProcessOptions
   * @uses RegisterEvents
   */
  var MapComponent = Ember.Component.extend(_processOptions.default, _registerEvents.default, {
    tagName: '',

    _type: null,
    _requiredOptions: ['map'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      (false && !(this._type) && Ember.assert('You must set a _type property on the map component.', this._type));


      this._registrationType = this._pluralType || this._type + 's';

      this.isInitialized = Ember.RSVP.defer();
      this.isInitialized.promise.then(function () {
        return Ember.set(_this, '_isInitialized', true);
      });

      this.publicAPI = new _publicApi.default(this, MapComponentAPI);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._internalAPI._registerComponent(this._registrationType, this.publicAPI);

      this._updateOrAddComponent();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._updateOrAddComponent();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.tryInvoke(this.mapComponent, 'setMap', [null]);

      this.publicAPI.remove(this);

      this._internalAPI._unregisterComponent(this._registrationType, this.publicAPI);
    },
    _updateOrAddComponent: function _updateOrAddComponent() {
      var _this2 = this;

      if (!Ember.get(this, 'map')) {
        return;
      }

      if (this._isInitialized) {
        this._updateComponent();
      } else {
        Ember.RSVP.resolve().then(function () {
          return _this2._addComponent();
        }).then(function () {
          return _this2._didAddComponent();
        });
      }
    },
    _addComponent: function _addComponent() {
      (false && !(false) && Ember.assert('Map components must implement the _addComponent hook.'));
    },
    _didAddComponent: function _didAddComponent() {
      this._registerOptionObservers();
      this.registerEvents();
      this.isInitialized.resolve();
    },
    _updateComponent: function _updateComponent() {
      var options = Ember.get(this, '_options');
      this.mapComponent.setOptions(options);
    }
  });

  exports.default = MapComponent;
});