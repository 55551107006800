define("@busy-web/ember-cli-launch-darkly/utils/launch-darkly-sdk", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = launchDarklySdk;
  /**
   * @module Utils
   *
   */
  function launchDarklySdk(id, data) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return window.LDClient.initialize(id, data, options);
  }
});