define('@busy-web/components/components/bc-input', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({

		tagName: 'input',
		classNames: ['bc-input'],

		attributeBindings: ['autofocus', 'maxlength', 'placeholder', 'type', 'tabindex', 'autocomplete', 'disabled', 'min'],

		autofocus: '',
		type: 'text',
		value: '',
		placeholder: '',
		tabindex: '',
		autocomplete: '',
		maxlength: '',
		disabled: false,
		min: '',

		focusOut: function focusOut() {
			this.sendAction('onBlur', this.getVal());
		},
		click: function click() {
			return false;
		},
		keyUp: function keyUp(evt) {
			if (evt.which === 13) {
				evt.stopPropagation();
				this.sendAction('onSubmit', this.getVal());
				return false;
			}

			this.sendAction('onKeyUp', evt.which, this.getVal());
			return true;
		},
		getVal: function getVal() {
			return this.get('value');
		}
	});
});