define('@busy-web/data/adapters/json', ['exports', 'ember-data', '@busy-web/data/utils/error', '@busy-web/data/utils/query'], function (exports, _emberData, _error, _query) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _emberData.default.JSONAPIAdapter.extend({
		/**
   * sets the authorizer to use.
   *
   * This must be set to an authorizer in the main
   * application. like `authorizer:application` that
   * can extend `@busy-web/data/authorizers/base`
   *
   * @property authorizer
   * @type string
   */
		authorizer: 'authorizer:base',
		hasManyFilterKey: 'filter',
		coalesceFindRequests: true,

		pathForType: function pathForType(type) {
			return Ember.String.dasherize(type);
		},


		version: 1,
		debug: false,

		urlForRequest: function urlForRequest(params) {
			var url = this._super(params);
			var type = this.methodForRequest(params);
			return this._addUrlParams(url, type);
		},
		normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
			var title = 'Api Error: ' + headers.method + ' - ' + headers.url;
			if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object') {
				return _error.default.parseAdapterErrors(title, status, Ember.get(payload, 'code'), Ember.get(payload, 'debug.errors'));
			} else {
				return [{
					title: title,
					status: '' + status,
					detail: '' + payload
				}];
			}
		},
		handleResponse: function handleResponse(status, headers, payload, requestData) {
			headers = (typeof headers === 'undefined' ? 'undefined' : _typeof(headers)) === 'object' && headers ? headers : {};
			Ember.set(headers, 'method', Ember.get(requestData, 'method'));
			Ember.set(headers, 'url', Ember.get(requestData, 'url'));

			return this._super(status, headers, payload, requestData);
		},
		_requestFor: function _requestFor(params) {
			var request = this._super(params);
			request.requestType = params.requestType;
			return request;
		},
		_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
			var hash = this._super({ url: request.url, method: "GET", headers: request.headers, data: request.data }) || {};
			Ember.set(hash, 'type', Ember.get(request, 'method'));
			Ember.set(hash, 'data', Ember.getWithDefault(hash, 'data', {}));

			if (!Ember.isNone(Ember.get(hash, 'data.filter'))) {
				this.changeFilter(Ember.get(hash, 'data'));
			}

			this.addDefaultParams(Ember.get(hash, 'data'), Ember.get(hash, 'type'));

			return hash;
		},
		methodForRequest: function methodForRequest(params) {
			var requestType = params.requestType;


			switch (requestType) {
				case 'createRecord':
					return 'POST';
				case 'updateRecord':
					return 'PATCH';
				case 'deleteRecord':
					return 'DELETE';
			}

			return 'GET';
		},
		_hasCustomizedAjax: function _hasCustomizedAjax() {
			if (Ember.FEATURES.isEnabled('ds-improved-ajax')) {
				return false;
			} else {
				this._super.apply(this, arguments);
			}
		},
		changeFilter: function changeFilter(data) {
			var __filter = Ember.get(data, 'filter');
			if (!Ember.isNone(__filter)) {
				var filter = {};
				Object.keys(__filter).forEach(function (key) {
					Ember.set(filter, key, Ember.get(__filter, key).split(','));
				});
				delete data.filter;
				Ember.set(data, Ember.getWithDefault(this, 'hasManyFilterKey', 'filter'), filter);
			}
		},
		_makeRequest: function _makeRequest(request) {
			var _this = this;

			var tries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

			var _req = Ember.merge({}, request);
			return this._super(_req).catch(function (err) {
				if (!Ember.isEmpty(Ember.get(err, 'errors'))) {
					var error = Ember.get(err, 'errors');
					error = Ember.isArray(error) ? error[0] : error;

					var status = parseInt(Ember.get(error, 'status'), 10);
					if (status === 429 && tries < 5) {
						return _this._waitPromise(300).then(function () {
							return _this._makeRequest(request, tries + 1);
						});
					} else if (status === 500 && tries < 5) {
						var detail = Ember.get(error, 'detail');
						if (/failed to obtain lock with key/.test(detail)) {
							return _this._waitPromise(500).then(function () {
								return _this._makeRequest(request, tries + 1);
							});
						}
					}
				}
				return Ember.RSVP.reject(err);
			});
		},
		_waitPromise: function _waitPromise() {
			var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

			return new Ember.RSVP.Promise(function (resolve) {
				Ember.run.later(function () {
					Ember.run(null, resolve, null);
				}, time);
			});
		},
		ajaxOptions: function ajaxOptions(url, type, options) {
			var _type = type === 'PUT' ? 'PATCH' : type;

			// set url params
			url = this._addUrlParams(url, _type);

			// make sure options is properly formatted
			options = options || {};
			Ember.set(options, 'data', Ember.getWithDefault(options, 'data', {}));

			// check for a data filter
			if (!Ember.isNone(Ember.get(options, 'data.filter'))) {
				this.changeFilter(options.data);
			}

			// add default params
			this.addDefaultParams(options.data, _type);

			// cal super to get the ajax hash
			var hash = this._super(url, type, options);

			// set the new hash type
			Ember.set(hash, 'type', _type);

			// if hash is post then adjust the data for bad apis
			if (Ember.get(hash, 'contentType') !== false && (Ember.get(hash, 'type') === 'POST' || Ember.get(hash, 'type') === 'PATCH')) {
				Ember.set(hash, 'contentType', 'application/x-www-form-urlencoded; charset=UTF-8');
				Ember.set(hash, 'data', JSON.parse(Ember.get(hash, 'data') || {}));
			}

			// return the hash
			return hash;
		},


		/**
   * passes the data object for addition default params
   * to be added
   *
   * @method addDefaultParams
   * @params data {object} add params to object
   * @returns {void}
   */
		addDefaultParams: function addDefaultParams() {},


		/**
   * passes url params object for additional default url params
   * to be added
   *
   * @method addUrlParams
   * @params params {object} add params to object
   * @returns {void}
   */
		addUrlParams: function addUrlParams() {},
		_addUrlParams: function _addUrlParams(url, type) {
			var _url$split = url.split('?'),
			    _url$split2 = _slicedToArray(_url$split, 2),
			    host = _url$split2[0],
			    params = _url$split2[1];

			params = _query.default.parse(params);
			this.addUrlParams(params, type);
			url = host + '?' + _query.default.stringify(params);
			return url;
		}
	});
});