define('@busy-web/components/components/bc-select-menu', ['exports', '@busy-web/components/mixins/close-on-escape', '@busy-web/components/mixins/bind-outside-click', '@busy-web/components/templates/components/bc-select-menu'], function (exports, _closeOnEscape, _bindOutsideClick, _bcSelectMenu) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/

	/**
  * @module Components
  *
  */
	function forEachOption(data, callback) {
		var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		if (data.each) {
			data.each(function (idx, element) {
				var el = Ember.$(element);
				callback.call(target, el, idx);
			});
		}
	}

	/**
  * `Component/BcSelectMenu`
  *
  * @class BcSelectMenu
  * @namespace Components
  * @extends Ember.Component
  */
	exports.default = Ember.Component.extend(_closeOnEscape.default, _bindOutsideClick.default, {
		layout: _bcSelectMenu.default,
		classNames: ['bc-select-menu'],
		classNameBindings: ['right', 'isMenuOpen:open', 'fullwidth', 'large'],

		__closeActionName: 'closeMenu',

		/**
   * Flag for a larger drop down button
   *
   * @public
   * @property large
   * @type {boolean}
   */
		large: false,

		/**
   * Flag for open and close of the drop down
   *
   * @private
   * @property isMenuOpen
   * @type {boolean}
   */
		isMenuOpen: false,

		/**
   * Sets the class `right` so the dialog is
   * formatted for the right side of the screen
   *
   * @public
   * @property right
   * @type {boolean} default: false
   */
		right: false,

		/**
   * sets the class fullwidth to force the
   * select menu to use 100% of its container
   *
   * @public
   * @property fullwidth
   * @type {boolean}
   */
		fullwidth: false,

		/**
   * Forces the drop down to keep the same label
   * after an option is selected
   *
   * @public
   * @property disableChange
   * @type {boolean} default: false
   */
		disableChange: false,

		/**
   * Forces the drop down to remain open after
   * an option is selected
   *
   * @public
   * @property keepOpen
   * @type {boolean} default: false
   */
		keepOpen: false,

		/**
   * Default label for an unselected drop down initial state
   *
   * If this option is not set then the first item in the options list will
   * be used.
   *
   * @public
   * @property label
   * @type {string}
   * @default null
   */
		label: null,

		/**
  	* action event triggered when a list option is clicked
  	*
  	* @public
  	* @property onSelect
  	* @type {event}
  	*/
		onSelect: null,

		/**
   * Array of options after parsed
   *
   * @private
   * @property listItem
   * @type {object[]}
   */
		listItem: null,

		/**
   * The current selected item from the list. This will be null
   * until an item is first selected.
   *
   * @private
   * @property selected
   * @type {object}
   */
		selected: null,

		/**
   * The text to display in the select menu button
   *
   * @private
   * @property selectedText
   * @type {string}
   */
		selectedText: Ember.computed('selected', 'label', 'listItem.[]', function () {
			if (!Ember.isNone(Ember.get(this, 'selected'))) {
				// look for a selected option first
				return Ember.get(this, 'selected.label');
			} else if (!Ember.isEmpty(Ember.get(this, 'label'))) {
				// if no selected option then look for a provided label
				return Ember.get(this, 'label');
			} else if (!Ember.isNone(Ember.get(this, 'listItem'))) {
				// no option or label then set it to the first option label
				return Ember.get(this, 'listItem.firstObject.label');
			} else {
				// otherwise just return an empty string
				return '';
			}
		}),

		/**
   * Initializes the listItem array
   *
   * @private
   * @method setup
   * @returns {void}
   */
		didRender: function didRender() {
			this._super();

			if (this.$()) {
				// get options list
				var data = this.$('.hidden-template').children();
				if (this.hasChanges(data)) {
					this.createOptionsList(data);
				}
			}
		},


		/**
   * Override method that gets called to
   * validate the input and check if this item should
   * be added to the options list
   *
   * @private
   * @method shouldCreateOption
   * @param el {object} jquery element object
   * @returns {boolean}
   */
		shouldCreateOption: function shouldCreateOption(el) {
			// check if the element is set to hidden
			return Ember.isNone(el.attr('hidden'));
		},


		/**
   * Override method for creating a option item
   * object. for classes that need additional functionality
   * this can be overridden to create a more advanced object.
   *
   * @private
   * @method createOption
   * @param el {object} jquery element object
   * @returns {object}
   */
		createOption: function createOption(el) {
			// create the option object
			var opt = Ember.Object.create({
				class: el.attr('class'),
				label: el.text(),
				value: el.val(),
				selected: el.is(':selected'),
				disabled: el.is(':disabled')
			});
			return opt;
		},


		/**
   * Creates a new options item list.
   *
   * This method gets called by didRender setup callback each time
   * the template is rerendered and changes have been detected to
   * the options item list
   *
   * @private
   * @method createOptionsList
   * @params data {object} jquery child array from the `hidden-template` container element
   * @returns {void}
   */
		createOptionsList: function createOptionsList(data) {
			var _this = this;

			// create data array for option data
			var dataArray = Ember.A([]);

			// loop through option data
			forEachOption(data, function (el) {
				// dont add option that are set to hidden
				if (_this.shouldCreateOption(el)) {
					// create the option item
					var opt = _this.createOption(el);

					if (Ember.get(opt, 'selected')) {
						_this.setSelected(opt);
					}

					// add option to list
					dataArray.pushObject(opt);
				}
			});

			// set new list items
			Ember.set(this, 'listItem', dataArray);
		},


		/**
   * Checks the array for any addition changes to items in the array.
   *
   * returns true if a new item is found or any properties on a
   * option item have changed
   *
   * @private
   * @method hasChanges
   * @param data {object} jquery child array from the `hidden-template` container element
   * @returns {boolean}
   */
		hasChanges: function hasChanges(data) {
			var _this2 = this;

			var hasChanges = false;
			forEachOption(data, function (el) {
				// if no changes detected yet
				if (!hasChanges) {
					// the list is empty so all items have changed
					if (Ember.isEmpty(Ember.get(_this2, 'listItem'))) {
						hasChanges = true;
					} else {
						// create option obj from element
						var option = _this2.createOption(el);

						// dont include default labels in the changes
						if (Ember.get(option, 'class') !== 'default-label') {
							// get old option item
							var oldOpt = Ember.get(_this2, 'listItem').findBy('value', Ember.get(option, 'value'));

							// item not found in list items
							if (Ember.isNone(oldOpt)) {
								hasChanges = true;
							} else {
								// check all keys in old opt for changes
								Object.keys(option).forEach(function (key) {
									// item property does not mathc old property
									if (Ember.get(oldOpt, key) !== Ember.get(option, key)) {
										hasChanges = true;
									}
								});
							}
						}
					}
				}
			});
			return hasChanges;
		},


		/**
   * Sets the selected option and unsets all other options
   * in the listItem option array
   *
   * @public
   * @method setSelected
   * @params option {object} selcted option object
   * @return {void}
   */
		setSelected: function setSelected(option) {
			if (!Ember.get(this, 'disableChange')) {
				Ember.set(this, 'selected', option);
			}
		},
		onOutsideClick: function onOutsideClick() {
			this.send('closeMenu');
		},
		onEscape: function onEscape() {
			this.send('closeMenu');
			return false;
		},


		actions: {
			/**
    * Toggles the menu open or close
    *
    * Action method
    *
    * @private
    * @method toggleMenu
    * @returns {void}
    */
			toggleMenu: function toggleMenu() {
				if (!Ember.get(this, 'isMenuOpen')) {
					this.bindEscape();
					this.bindClick();
					Ember.set(this, 'isMenuOpen', true);
				} else {
					this.send('closeMenu');
				}
			},


			/**
    * Closes the menu
    *
    * Action method
    *
    * @private
    * @method closeMenu
    * @returns {void}
    */
			closeMenu: function closeMenu() {
				this.unbindEscape();
				this.unbindClick();
				Ember.set(this, 'isMenuOpen', false);
			},


			/**
    * Sends the onSelect event action
    *
    * Action Method
    *
    * @private
    * @method selectAction
    * @params option {object} An option html object
    * @returns {void}
    */
			selectAction: function selectAction(option) {
				// do nothing if disabled is set to
				// true for the item selected
				if (!Ember.get(option, 'disabled')) {
					// close menu unless keep open
					// is set to true
					if (!Ember.get(this, 'keepOpen')) {
						this.send('closeMenu');
					}

					// send the value of the option to the onSelect callback
					this.sendAction('onSelect', Ember.get(option, 'value'));
				}
			},
			stopPropagation: function stopPropagation() {
				return false;
			}
		}
	});
});