define('@busy-web/utils/local-storage', ['exports', '@busy-web/utils/assert'], function (exports, _assert) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var _localStorage = window.localStorage; /**
                                           * @module Utils
                                           *
                                           */

	var LocalStorage = Ember.Object.extend();

	/**
  * `Util/LocalStorage`
  *
  * @class LocalStorage
  * @namespace Utils
  * @extends Ember.Object
  */
	LocalStorage.reopenClass({
		setProperty: function setProperty(key, value) {
			// assert the function recieved 2 args and
			// the first arg is a string.
			_assert.default.funcNumArgs(arguments, 2, true);
			_assert.default.isString(key);

			// set the value to storage.
			_localStorage.setItem(key, value);

			// return this so functions can be chained.
			return this;
		},
		getProperty: function getProperty(key) {
			// assert the function recieved 1 arg and
			// the arg is a string.
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isString(key);

			// get the value from storage.
			return _localStorage.getItem(key);
		},
		getWithDefault: function getWithDefault(key, defaultValue) {
			// assert the function recieved 2 args and
			// the first arg is a string.
			_assert.default.funcNumArgs(arguments, 2, true);
			_assert.default.isString(key);

			// get the value from storage.
			var storeValue = this.getProperty(key);

			// if returned value is null or undefined then return the defaultValue provided.
			return !Ember.isNone(storeValue) ? storeValue : defaultValue;
		},
		hasValue: function hasValue(key) {
			// assert the function recieved 1 arg and
			// the arg is a string.
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isString(key);

			// return true if value is not null or undefined.
			return !Ember.isNone(this.getProperty(key));
		},
		remove: function remove(key) {
			// assert the function recieved 1 arg and
			// the arg is a string.
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isString(key);

			// remove the item from storage.
			_localStorage.removeItem(key);

			return this;
		}
	});

	exports.default = LocalStorage;
});