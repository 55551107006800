define('@busy-web/utils/browser', ['exports', '@busy-web/utils/assert'], function (exports, _assert) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Utils
  *
  */
	var browserInfo = Ember.Object.extend();

	/**
  * Helper method to determine the broser version.
  *
  * @private
  * @method version
  * @param browser {string} the broser type.
  * @return {string}
  */
	function _version(browser) {
		// browser and user agent are the same
		// so just return an empty string for the version.
		if (browser === window.navigator.userAgent) {
			return '';
		}

		// firefox and opera have alternate version strings in some cases.
		if (window.navigator.userAgent.indexOf("Version") !== -1) {
			browser = 'Version';
		}

		// get the index of the version string.
		var index = window.navigator.userAgent.indexOf(browser) + browser.length + 1;

		// get the version from the user agent.
		var version = window.navigator.userAgent.substring(index);

		// trim the version string at semicolon/space if present
		if (version.indexOf(';') !== -1) {
			version = version.substring(0, version.indexOf(';'));
		}

		if (version.indexOf(' ') !== -1) {
			version = version.substring(0, version.indexOf(' '));
		}

		return version;
	}

	/**
  * `Util/Browser`
  *
  * @class Browser
  * @namespace Utils
  * @extends Ember.Object
  */
	browserInfo.reopenClass({
		type: function type() {
			_assert.default.funcNumArgs(arguments, 0, true);

			var name = window.navigator.userAgent;
			if (this.isBrowserType("Opera")) {
				// Opera
				name = "Opera";
			} else if (this.isBrowserType("MSIE")) {
				// Internet Explorer
				name = "Internet Explorer";
			} else if (this.isBrowserType('Chrome')) {
				// Chrome
				name = "Chrome";
			} else if (this.isBrowserType("Safari")) {
				// Safari
				name = "Safari";
			} else if (this.isBrowserType("Firefox")) {
				// Firefox
				name = "Firefox";
			} else {
				// some other browser
				var nameOffset = window.navigator.userAgent.lastIndexOf(' ') + 1;
				var versOffset = window.navigator.userAgent.lastIndexOf('/');

				if (nameOffset < versOffset) {
					// In most other browsers, "name/version" is at the end of userAgent
					name = window.navigator.userAgent.substring(nameOffset, versOffset);
				}
			}
			return name;
		},
		version: function version() {
			_assert.default.funcNumArgs(arguments, 0, true);

			var name = this.type();
			return _version(name);
		},
		osType: function osType() {
			_assert.default.funcNumArgs(arguments, 0, true);

			var osType = "Unknown OS";
			if (window.navigator.appVersion.indexOf("Win") !== -1) {
				// windows os
				osType = 'Windows';
			} else if (window.navigator.appVersion.indexOf("Mac") !== -1) {
				// mac os could be a mobile client.
				osType = 'MacOS';

				// check if this is mac os or ios.
				if (window.navigator.appVersion.indexOf("Mobile") !== -1) {
					osType = 'iOS';
				}
			} else if (window.navigator.appVersion.indexOf("Android") !== -1) {
				// android os
				osType = 'Android';
			} else if (window.navigator.appVersion.indexOf("Linux") !== -1) {
				// linux os
				osType = 'Linux';
			} else if (window.navigator.appVersion.indexOf("X11") !== -1) {
				// unix os
				osType = 'UNIX';
			}
			return osType;
		},
		osVersion: function osVersion() {
			_assert.default.funcNumArgs(arguments, 0, true);

			var start = window.navigator.appVersion.indexOf('(') + 1;
			var end = window.navigator.appVersion.indexOf(')');

			if (start && end) {
				var version = window.navigator.appVersion.substring(start, end);

				return version.replace(/[^\d]*([\d]?[\d]?(\.|_)?[\d]?[\d]?(\.|_)?[\d]?[\d])[\s\S]*/, '$1').replace(/_/g, '.');
			} else {
				return window.navigator.appVersion;
			}
		},
		detect: function detect() {
			_assert.default.funcNumArgs(arguments, 0, true);

			// get the browser name.
			var type = this.type();
			var version = _version(type);
			var majorVersion = '' + parseInt(version, 10);

			// get operating system info.
			var osType = this.osType();
			var osVersion = this.osVersion();

			if (isNaN(majorVersion)) {
				version = '' + parseFloat(window.navigator.appVersion);
				majorVersion = '' + parseInt(window.navigator.appVersion, 10);
			}

			return { type: type, version: version, majorVersion: majorVersion, osType: osType, osVersion: osVersion };
		},
		isBrowserType: function isBrowserType(browser) {
			return window.navigator.userAgent.indexOf(browser) !== -1;
		}
	});

	exports.default = browserInfo;
});