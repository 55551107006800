define('@busy-web/ember-date-time/components/private/time-picker', ['exports', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/utils/clock/data', '@busy-web/ember-date-time/utils/clock', '@busy-web/ember-date-time/utils/clock/string', '@busy-web/ember-date-time/templates/components/private/time-picker', '@busy-web/ember-date-time/utils/constant'], function (exports, _time2, _data, _clock, _string, _timePicker, _constant) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busyweb', 'emberdatetime', 'p-time-picker'],
		layout: _timePicker.default,

		stateManager: null,

		/**
   * timestamp that is passed in when using date picker
   *
   * @public
   * @property timestamp
   * @type Number
   */
		timestamp: null,

		/**
   * format for displaying dates
   *
   * @public
   * @property format
   * @type string
   */
		format: 'MMM DD, YYYY',

		/**
   * can be passed in so a date before the minDate cannot be selected
   *
   * @public
   * @property minDate
   * @type Number
   * @optional
   */
		minDate: null,

		/**
   * can be passed in so a date after the maxDate cannot be selected
   *
   * @public
   * @property maxDate
   * @type Number
   * @optional
   */
		maxDate: null,

		round: 5,
		selectRound: 1,

		section: _constant.HOUR_FLAG,

		invalidTime: false,

		initialize: Ember.on('init', function () {
			this.timeChange();
			this.sectionChage();

			// TODO:
			// pass format from parent element
			//set(this, 'format', get(this, 'stateManager.format'));

			var min = Ember.get(this, 'stateManager.minDate');
			var max = Ember.get(this, 'stateManager.maxDate');

			Ember.set(this, 'minDate', min);
			Ember.set(this, 'maxDate', max);

			var selectRound = Ember.getWithDefault(this, 'stateManager.selectRound', 1);
			var round = Ember.getWithDefault(this, 'stateManager.round', 5);
			if (round < selectRound) {
				round = selectRound;
			}

			Ember.set(this, 'round', round);
			Ember.set(this, 'selectRound', selectRound);

			// create hours clock meta object
			(0, _clock.createMetaFor)(this, _constant.HOUR_FLAG, 1, 12, min, max, 1);

			// create minutes clock meta object
			(0, _clock.createMetaFor)(this, _constant.MINUTE_FLAG, 0, 59, min, max, round, selectRound);
		}),

		renderPicker: Ember.on('didInsertElement', function () {
			this.resetTimeElements();
		}),

		timeChange: Ember.observer('stateManager.timestamp', function () {
			if (Ember.get(this, 'timestamp') !== Ember.get(this, 'stateManager.timestamp')) {
				this.saveTimestamp(Ember.get(this, 'stateManager.timestamp'));
				this.setActiveTime();
			}
		}),

		sectionChage: Ember.observer('stateManager.section', function () {
			var section = Ember.get(this, 'stateManager.section');
			this.changeSection(section);
		}),

		/**
   * current hour of timestamp displayed in clock header
   *
   * @private
   * @property hours
   * @type String
   */
		hours: Ember.computed('timestamp', function () {
			return (0, _string.numberToString)(this.getCurrentTimeByType(_constant.HOUR_FLAG));
		}).readOnly(),

		/**
   * current minute of timestamp displayed in clock header
   *
   * @private
   * @property minutes
   * @type String
   */
		minutes: Ember.computed('timestamp', function () {
			return (0, _string.numberToString)(this.getCurrentTimeByType(_constant.MINUTE_FLAG));
		}).readOnly(),

		/**
   * current date of timestamp displayed in clock footer
   *
   * @private
   * @property currentDate
   * @type String
   */
		currentDate: Ember.computed('timestamp', 'format', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format(Ember.get(this, 'format'));
		}).readOnly(),

		meridian: Ember.computed('timestamp', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format('A');
		}).readOnly(),

		isHourPicker: Ember.computed('section', function () {
			return Ember.get(this, 'section') === _constant.HOUR_FLAG;
		}),

		isAM: Ember.computed('timestamp', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format('A') === 'AM';
		}),

		isPM: Ember.computed('timestamp', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format('A') === 'PM';
		}),

		getCurrentTimeByType: function getCurrentTimeByType(type) {
			return (0, _data.getHourMinute)(type, Ember.get(this, 'timestamp'), Ember.getWithDefault(this, 'selectRounder', 1));
		},
		changeSection: function changeSection(section) {
			if (section === _constant.MERIDIAN_FLAG) {
				section = _constant.HOUR_FLAG;
			}

			if (section !== Ember.get(this, 'section') && (section === _constant.HOUR_FLAG || section === _constant.MINUTE_FLAG)) {
				Ember.set(this, 'lastActive', null);
				Ember.set(this, 'section', section);

				if (Ember.get(this, '_state') === 'inDOM') {
					this.resetTimeElements();
				}
			}
		},
		resetTimeElements: function resetTimeElements() {
			this.renderClock();
			this.cleanClock();

			var time = this.getCurrentTimeByType(Ember.get(this, 'section'));
			if (Ember.get(this, 'lastActive') !== time) {
				this.setTimestamp(time);
			}

			this.bindClick();
		},


		/**
   * sets up the active time classes and removes the lst active times
   *
   * @private
   * @method setActiveTime
   */
		setActiveTime: function setActiveTime() {
			if (Ember.get(this, '_state') === 'inDOM') {
				var value = this.getCurrentTimeByType(Ember.get(this, 'section'));
				if (!Ember.isNone(value)) {
					var clock = (0, _clock.metaFor)(this, Ember.get(this, 'section'));
					if (!Ember.isNone(Ember.get(this, 'lastActive'))) {
						clock.unselectPlot(Ember.get(this, 'lastActive'));
					}
					clock.selectPlot(value);
					Ember.set(this, 'lastActive', value);
					this.dragHandler(value);
				}
			}
		},


		/**
   * converts moment object to a unix timestamp, and sets that to the global timestamp
   *
   * @private
   * @method saveTimestamp
   * @param date {object} moment object that will be the new timestamp
   */
		saveTimestamp: function saveTimestamp(date) {
			if (!Ember.get(this, 'isDestroyed') && Ember.get(this, 'timestamp') !== date.valueOf()) {
				var bounds = (0, _data.isInBounds)(date, Ember.get(this, 'minDate'), Ember.get(this, 'maxDate'));
				if (bounds.isBefore || bounds.isAfter) {
					Ember.set(this, 'invalidTime', true);
					//this.setAvailableTimestamp(bounds);
				} else {
					Ember.set(this, 'invalidTime', false);
				}

				// save the current date as the timestamp
				Ember.set(this, 'timestamp', date.valueOf());
				//}
			}
		},


		//setAvailableTimestamp(bounds) {
		//	if (bounds.isBefore) {
		//		this.saveTimestamp(_time(get(this, 'minDate')));
		//	} else if (bounds.isAfter) {
		//		this.saveTimestamp(_time(get(this, 'maxDate')));
		//	} else {
		//		assert(`error trying to setAvailableTimestamp with bounds isBefore: ${bounds.isBefore} and isAfter: ${bounds.isAfter}`, false);
		//	}
		//},

		/**
   * sets the timestamp to be the passed minute
   *
   * @private
   * @method setTimestamp
   * @param minute {number} minute to be set to timestamp
   */
		setTimestamp: function setTimestamp(value) {
			var time = (0, _data.getDate)(Ember.get(this, 'section'), value, Ember.get(this, 'timestamp'));
			this.saveTimestamp(time);
			this.setActiveTime();
		},
		renderClock: function renderClock() {
			if (Ember.get(this, '_state') === 'inDOM') {
				var clock = (0, _clock.metaFor)(this, Ember.get(this, 'section'));
				clock.render(Ember.get(this, 'timestamp'));
			}
		},


		/**
   * remove initial circles and lines for clock
   *
   * @private
   * @method cleanClock
   */
		cleanClock: function cleanClock() {
			if (Ember.get(this, '_state') === 'inDOM') {
				var clock = (0, _clock.metaFor)(this, Ember.get(this, 'section'));
				clock.cleanup();
			}
		},


		/**
   * handles all the function events for dragging on the hours clock
   * dragHandler must contain start, move and stop functions within it
   *
   * @private
   * @method dragHandler
   * @param hour {string} hour thats being dragged
   * @event drag
   */
		dragHandler: function dragHandler(value) {
			var _this = this;

			if (Ember.get(this, '_state') === 'inDOM') {
				var clock = (0, _clock.metaFor)(this, Ember.get(this, 'section'));
				clock.handleDrag(value, function (time) {
					// set the time according to the new angle
					_this.setTimestamp(time);

					// notify listeners an update has occured
					_this.sendAction('onUpdate', Ember.get(_this, 'section'), Ember.get(_this, 'timestamp'));
				});
			}
		},
		bindClick: function bindClick() {
			var _this2 = this;

			if (Ember.get(this, '_state') === 'inDOM') {
				// unregister hour clicks
				var hourClock = (0, _clock.metaFor)(this, _constant.HOUR_FLAG);
				hourClock.unclick();

				// unregister minute clicks
				var minClock = (0, _clock.metaFor)(this, _constant.MINUTE_FLAG);
				minClock.unclick();

				// bind new click handler
				var clock = (0, _clock.metaFor)(this, Ember.get(this, 'section'));
				clock.click(function (time) {
					// set the time according to the new angle
					_this2.setTimestamp(time);

					// notify listeners an update has occured
					_this2.sendAction('onUpdate', Ember.get(_this2, 'section'), Ember.get(_this2, 'timestamp'));
				});
			}
		},


		actions: {
			/**
    * handles clicking AM && PM, wont allow if it goes under min date
    *
    * @event meridianChange
    */
			meridianChange: function meridianChange(type) {
				var time = void 0;
				if (type === 'AM' && Ember.get(this, _constant.MERIDIAN_FLAG) === 'PM') {
					time = (0, _time2.default)(Ember.get(this, 'timestamp')).subtract(12, 'hours');
				} else if (type === 'PM' && Ember.get(this, _constant.MERIDIAN_FLAG) === 'AM') {
					time = (0, _time2.default)(Ember.get(this, 'timestamp')).add(12, 'hours');
				}

				if (!Ember.isNone(time)) {
					// save new time
					this.saveTimestamp(time);

					// notify event listeners that an update has occurred
					this.sendAction('onUpdate', Ember.get(this, 'section'), Ember.get(this, 'timestamp'));
				}
			},


			/**
    * handles clicking the hour in the header
    *
    * @event hourHeaderClicked
    */
			hourHeaderClicked: function hourHeaderClicked() {
				this.changeSection(_constant.HOUR_FLAG);

				// notify event listeners that an update has occurred
				this.sendAction('onUpdate', _constant.HOUR_FLAG, Ember.get(this, 'timestamp'));
			},


			/**
    * handles clicking the minute in the header
    *
    * @event minuteHeaderClicked
    */
			minuteHeaderClicked: function minuteHeaderClicked() {
				this.changeSection(_constant.MINUTE_FLAG);

				// notify event listeners that an update has occurred
				this.sendAction('onUpdate', _constant.MINUTE_FLAG, Ember.get(this, 'timestamp'));
			}
		}
	});
});