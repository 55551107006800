define('@busy-web/components/components/bc-title', ['exports', '@busy-web/components/templates/components/bc-title'], function (exports, _bcTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bcTitle.default,
    classNames: ['bc-title'],
    classNameBindings: ['hasTitle'],

    actions: {
      titleClick: function titleClick() {
        this.sendAction('onTitleClick');
      }
    }
  });
});