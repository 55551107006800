define('@busy-web/components/components/checkbox-input', ['exports', '@busy-web/components/templates/components/checkbox-input'], function (exports, _checkboxInput) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({
		layout: _checkboxInput.default,
		classNameBindings: ['checked'],
		attributeBindings: ['checked', 'group', 'disabled'],

		type: 'checkbox',
		group: null,
		name: null,
		disabled: false,
		checked: false,
		_value: false,

		click: function click() {
			this.sendAction('onChange', !this.get('_value'));
		}
	});
});