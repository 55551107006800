define('@busy-web/data/initializers/@busy-web/data', ['exports', 'ember-data', '@busy-web/data/mixins/rpc-model'], function (exports, _emberData, _rpcModel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: '@busy-web/data',

		initialize: function initialize() /*registry*/{
			_emberData.default.RPCModel = _emberData.default.Model.extend(_rpcModel.default, {});

			var belongsTo = _emberData.default.belongsTo;

			_emberData.default.belongsTo = function (modelName) {
				var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

				options.modelName = modelName;
				return belongsTo(modelName, options);
			};

			var hasMany = _emberData.default.hasMany;

			_emberData.default.hasMany = function (modelName) {
				var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

				options.modelName = modelName;
				return hasMany(modelName, options);
			};

			_emberData.default.Model.reopen({
				getRecord: function getRecord() {
					// fix for the model._internalModel issue.
					return this._internalModel.getRecord();
				},
				saveBatch: function saveBatch(auto) {
					this._batch = true;
					this._autoBatch = auto === true ? true : false;
					return this.save();
				},
				reloadAll: function reloadAll() {
					var _this = this;

					return this.reload().then(function (model) {
						_this.reloadRelationships();
						return model;
					});
				},
				reloadRelationships: function reloadRelationships() {
					var _this2 = this;

					this.eachRelationship(function (name) {
						var model = _this2.get(name);
						if (model.reload) {
							model.reload();
						} else {
							model.get('content');
							if (model.reload) {
								model.reload();
							}
						}
					});
				}
			});

			_emberData.default.Model.reopenClass({
				eachRelationship: function eachRelationship(callback, binding) {
					Ember.get(this, 'relationshipsByName').forEach(function (relationship, name) {
						if (relationship.options.modelName !== relationship.type) {
							relationship.type = relationship.options.modelName;
						}
						callback.call(binding, name, relationship);
					});
				},
				typeForRelationship: function typeForRelationship(name, store) {
					var relationship = Ember.get(this, 'relationshipsByName').get(name);
					if (relationship.options.modelName !== relationship.type) {
						relationship.type = relationship.options.modelName;
					}
					return relationship && store.modelFor(relationship.type);
				}
			});
		}
	};
});