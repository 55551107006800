define('@busy-web/components/components/md-button', ['exports', '@busy-web/components/templates/components/md-button'], function (exports, _mdButton) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kSpecial = ['close', 'view_column'];

	/**
  * `Component/MdButton`
  *
  * Create material design buttons
  *
  * @class MdButton
  * @namespace Components
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		tagName: 'button',
		layout: _mdButton.default,

		classNames: ['md-button'],
		classNameBindings: ['small', 'right', 'special', 'disabled', 'hover::no-hover'],
		attributeBindings: ['title'],

		/**
   * adds special css class name to container for icons that
   * need extra style added to the css.
   *
   * @property special
   * @type {string}
   */
		special: Ember.computed('name', function () {
			if (!Ember.isEmpty(Ember.get(this, 'name')) && kSpecial.indexOf(Ember.get(this, 'name')) !== -1) {
				return Ember.String.dasherize(Ember.get(this, 'name'));
			}
			return false;
		}),

		/**
   * attribute to define the type of icon to be used
   *
   * @property name
   * @type {string}
   */
		name: '',

		/**
   * optional title attribute for hover text information
   *
   * @property title
   * @type {string}
   */
		title: '',

		/**
   * Adds `small` to the css class names
   *
   * @property small
   * @type {boolean}
   */
		small: false,

		/**
   * adds `right` to the css class names
   *
   * @property right
   * @type {boolean}
   */
		right: false,

		disabled: false,
		hover: true,
		bubbles: true,

		click: function click(evt) {
			if (!Ember.get(this, 'disabled')) {
				if (!Ember.get(this, 'bubbles')) {
					evt.stopPropagation();
				}

				if (typeof Ember.get(this, 'onClick') === 'string') {
					this.sendAction('onClick');
				} else if (typeof Ember.get(this, 'onClick') === 'function') {
					Ember.get(this, 'onClick')();
				}

				return Ember.get(this, 'bubbles');
			}
		}
	});
});