define("@busy-web/ember-date-time/--private/utils/object", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.eachInObject = eachInObject;
  /**
   * @module Core/Utils
   *
   */

  function eachInObject(target, obj, cb) {
    Object.keys(obj).forEach(function (key) {
      cb.call(target, obj[key], key, obj);
    });
  }
});