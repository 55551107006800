define('@busy-web/ember-date-time/utils/clock/data', ['exports', '@busy-web/ember-date-time/utils/clock/string', '@busy-web/ember-date-time/utils/constant', '@busy-web/ember-date-time/utils/time'], function (exports, _string, _constant, _time2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.createPoints = createPoints;
	exports.getAttrs = getAttrs;
	exports.getDate = getDate;
	exports.getHourMinute = getHourMinute;
	exports.isInBounds = isInBounds;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var dataArray = function dataArray(Base) {
		return function (_Base) {
			_inherits(_class, _Base);

			function _class() {
				var _ref;

				_classCallCheck(this, _class);

				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				var _this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args)));

				var data = args[0];
				(false && !(Ember.isArray(data)) && Ember.assert("data must be an array", Ember.isArray(data)));

				_this.__data = data;
				return _this;
			}

			_createClass(_class, [{
				key: 'objectAt',
				value: function objectAt(key) {
					return this.__data[key];
				}
			}, {
				key: 'filterEach',
				value: function filterEach(prop, cb) {
					return this.__data.forEach(function (d, i) {
						if (d[prop]) {
							cb(d, i);
						}
					});
				}
			}, {
				key: 'each',
				value: function each(cb) {
					return this.__data.forEach(function (d, i) {
						return cb(d, i);
					});
				}
			}]);

			return _class;
		}(Base);
	};

	exports.default = dataArray;
	function createPoints(start, end, rounder, selectRounder) {
		if (selectRounder <= 0) {
			selectRounder = 1;
		}

		if (selectRounder > rounder) {
			rounder = selectRounder;
		}

		var points = [];
		var renderSize = 0;
		for (var num = start; num <= end; num++) {
			var isVisible = false;
			if (num % rounder === 0) {
				isVisible = true;
			}

			var isRender = false;
			if (num % selectRounder === 0) {
				isRender = true;
				renderSize += 1;
			}

			var value = (0, _string.numberToString)(num);
			points.push({ num: num, value: value, isVisible: isVisible, isRender: isRender });
		}
		return { points: points, renderSize: renderSize };
	}

	function getAttrs(el, attrs) {
		var __attrs = {};
		attrs.forEach(function (key) {
			__attrs[key] = el.attr(key);
		});
		return __attrs;
	}

	function getDate(type, value, timestamp) {
		var time = (0, _time2.default)(timestamp);
		if (type === _constant.HOUR_FLAG) {
			if (value === 12) {
				value = 0;
			}

			if (time.format('A') === 'PM') {
				value = value + 12;
			}

			time.hour(value);
		} else if (type === _constant.MINUTE_FLAG) {
			time.minute(value);
		} else {
			(false && !(false) && Ember.assert('Invalid type [' + type + '] passed to getDate, valid types are ' + _constant.HOUR_FLAG + ' and ' + _constant.MINUTE_FLAG, false));
		}
		return time;
	}

	function getHourMinute(type, timestamp, selectRounder) {
		if (type === _constant.HOUR_FLAG) {
			var hour = parseInt((0, _time2.default)(timestamp).format('H'), 10);
			if (hour <= 0) {
				hour = 12;
			} // enforce hour is not set to 0
			if (hour > 12) {
				hour = hour - 12;
			} // enforce hour is 12 hour format
			return hour;
		} else {
			var num = (0, _time2.default)(timestamp).minute();
			var dist = num % selectRounder;
			var low = selectRounder - dist;
			if (low > dist) {
				num -= dist;
			} else {
				num += low;
			}
			return num;
		}
	}

	function isInBounds(date, min, max) {
		return _time2.default.isDateInBounds(date, min, max);
	}
});