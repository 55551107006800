define('@busy-web/components/components/bc-tab', ['exports', '@busy-web/components/templates/components/bc-tab'], function (exports, _bcTab) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(Ember.Evented, {
		layout: _bcTab.default,
		classNames: ['--bc-tab'],
		classNameBindings: ['classId', 'active:active'], // 'open:open'],

		classId: Ember.computed('tabName', function () {
			return Ember.String.dasherize(Ember.get(this, 'tabName'));
		}),

		active: false,

		tabName: null,
		tabIndex: 0,

		showBadge: false,
		badgeContent: null,
		badgeColor: null,

		isViewable: true,
		loadOnOpen: true,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();

			this.$().data({
				id: Ember.get(this, 'classId'),
				active: Ember.get(this, 'active'),
				tabName: Ember.get(this, 'tabName'),
				tabIndex: Ember.get(this, 'tabIndex'),
				isViewable: Ember.get(this, 'isViewable'),
				showBadge: Ember.get(this, 'showBadge'),
				badgeContent: Ember.get(this, 'badgeContent'),
				badgeColor: Ember.get(this, 'badgeColor'),
				showTab: function showTab() {
					return _this.showTab.apply(_this, arguments);
				},
				hideTab: function hideTab() {
					return _this.hideTab.apply(_this, arguments);
				},
				on: this.on
			});
		},


		viewableState: Ember.observer('isViewable', function () {
			if (this.$().length && !Ember.get(this, 'isDestroyed')) {
				this.$().data('isViewable', Ember.get(this, 'isViewable'));
				this.trigger('change');
			}
		}),

		/**
   * @public
   * @method showTab
   */
		showTab: function showTab() {
			Ember.set(this, 'active', true);
		},
		hideTab: function hideTab() {
			Ember.set(this, 'active', false);
		}
	});
});