define('@busy-web/data/mixins/rpc-model', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		/**
   * flag to tell this is an rpc model
   *
   * @public
   * @property _isRPC
   * @type {boolean}
   */
		_isRPC: true,

		/**
   * The RPC client to call the method on
   *
   * @public
   * @property _clientName
   * @type {string}
   */
		_clientName: null,

		/**
   * The RPC clients method to call
   *
   * @public
   * @property _methodName
   * @type {string}
   */
		_methodName: null
	});
});