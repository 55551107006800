define('@busy-web/ember-date-time/utils/state', ['exports', '@busy-web/ember-date-time/utils/time'], function (exports, _time2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _state;


	/**
  * `EmberDateTime/Utils/State`
  *
  * @class State
  */
	/**
  * @module Utils
  *
  */
	var StateManager = Ember.Object.extend(Ember.Evented, {
		/**
   * Must be a timestamp in milliseconds
   *
   * @public
   * @property timestamp
   * @type number
   */
		timestamp: null,
		_backupTimestamp: null,

		type: null,

		minDate: null,
		maxDate: null,

		section: '',

		isOpen: false,
		isTop: false,

		showTime: true,
		showDate: true,

		/**
   * Must be a moment date object
   *
   * @public
   * @property date
   * @type Moment
   */
		date: null,

		dateSetter: Ember.observer('timestamp', function () {
			var date = (0, _time2.default)(this.get('timestamp'));
			this.set('date', date);
		}),

		/**
   * The date objects day of the month
   *
   * @public
   * @property dayOfMonth
   * @type number
   */
		dayOfMonth: Ember.computed('timestamp', function () {
			return this.get('date').date();
		}),

		/**
   * Boolean value set to true if the date is before the minDate
   *
   * @public
   * @property isBefore
   * @type boolean
   */
		isBefore: Ember.computed('minDate', 'timestamp', function () {
			var isBefore = false;
			if (!Ember.isNone(this.get('timestamp'))) {
				var date = this.get('date');
				if (this.get('type') === 'date') {
					date = (0, _time2.default)(date.valueOf()).endOf('day');
				}
				isBefore = _time2.default.isDateBefore(date, this.get('minDate'));
			}
			return isBefore;
		}),

		/**
   * Boolean value set to true if the date is after the maxDate
   *
   * @public
   * @property isAfter
   * @type boolean
   */
		isAfter: Ember.computed('maxDate', 'timestamp', function () {
			var isAfter = false;
			if (!Ember.isNone(this.get('timestamp'))) {
				var date = this.get('date');
				if (this.get('type') === 'date') {
					date = (0, _time2.default)(date.valueOf()).startOf('day');
				}
				isAfter = _time2.default.isDateAfter(date, this.get('maxDate'));
			}
			return isAfter;
		}),

		add: function add(value, type) {
			var date = this.get('date');
			date.add(value, type);

			if (!_time2.default.isDateAfter(date, this.get('maxDate'))) {
				this.set('timestamp', date.valueOf());
			} else {
				date.subtract(value, type);
			}
		},
		subtract: function subtract(value, type) {
			var date = this.get('date');
			date.subtract(value, type);

			if (!_time2.default.isDateBefore(date, this.get('minDate'))) {
				this.set('timestamp', date.valueOf());
			} else {
				date.add(value, type);
			}
		},


		/**
   * Boolean value set to true if this is the current date sent in
   * by the user
   *
   * @public
   * @property isCurrentDay
   * @type boolean
   */
		isCurrentDay: false,

		isCurrentMonth: false,

		isCurrentYear: false,

		/**
   * the week this date falls under in the month
   *
   * @public
   * @property weekNumber
   * @type number
   */
		weekNumber: null,

		/**
   * Computed property returns true if this date should be disabled
   *
   * @public
   * @property isDisabled
   * @type boolean
   */
		isDisabled: Ember.computed('isBefore', 'isAfter', function () {
			return this.get('isBefore') || this.get('isAfter');
		}),

		resetTime: function resetTime() {
			var backup = this.get('_backupTimestamp');
			if (!Ember.isNone(backup)) {
				this.set('timestamp', backup);
				this.set('_backupTimestamp', backup);
			}
			return this;
		},
		set: function set(key, value) {
			if (key === 'timestamp') {
				this.set('_backupTimestamp', this.get('timestamp'));
			}
			return this._super(key, value);
		}
	});

	function _state(options) {
		var state = StateManager.create();

		// changed to assert in and removed if statements that are not needed.
		// minDate and maxDate should be null or a timestamp
		if (options.minDate) {
			(false && !(_time2.default.isValidTimestamp(options.minDate)) && Ember.assert("minDate must be a valid timestamp", _time2.default.isValidTimestamp(options.minDate)));
		}

		if (options.maxDate) {
			(false && !(_time2.default.isValidTimestamp(options.maxDate)) && Ember.assert("maxDate must be a valid timestamp", _time2.default.isValidTimestamp(options.maxDate)));
		}

		// timestamp must be set to a timestamp
		(false && !(_time2.default.isValidTimestamp(options.timestamp)) && Ember.assert("timestamp must be a valid number in milliseconds representing a date", _time2.default.isValidTimestamp(options.timestamp)));


		state.setProperties(options);
		return state;
	}
});