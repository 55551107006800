define('@busy-web/data/mixins/simple-auth-data-adapter', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _dataAdapterMixin) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Mixin.create(_dataAdapterMixin.default, {
				/**
    * Overrides ember simple auth to support ds-improved-ajax calls
    *
    * Defines a `beforeSend` hook (see http://api.jquery.com/jQuery.ajax/) that
    * injects a request header containing the authorization data as constructed
    * by the {{#crossLink "DataAdapterMixin/authorizer:property"}}{{/crossLink}}
    * (see {{#crossLink "SessionService/authorize:method"}}{{/crossLink}}). The
    * specific header name and contents depend on the actual authorizer that is
    * used.
    *
    * Until [emberjs/rfcs#171](https://github.com/emberjs/rfcs/pull/171)
    * gets resolved and [ds-improved-ajax](https://github.com/emberjs/data/pull/3099)
    * [feature flag](https://github.com/emberjs/data/blob/master/FEATURES.md#feature-flags)
    * is enabled, this method will be called for **every** ember-data version.
    * `headersForRequest` *should* replace it after the resolution of the RFC.
    *
    * @method headersForRequest
    * @protected
    */
				_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
						var _this = this;

						var hash = this._super(request);
						var beforeSend = hash.beforeSend;


						hash.beforeSend = function (xhr) {
								if (_this.get('authorizer')) {
										var authorizer = _this.get('authorizer');
										_this.get('session').authorize(authorizer, function (headerName, headerValue) {
												xhr.setRequestHeader(headerName, headerValue);
										});
								} else {
										_this.authorize(xhr);
								}

								if (beforeSend) {
										beforeSend(xhr);
								}
						};

						return hash;
				},
				authorize: function authorize() {
						(false && !(false) && Ember.assert('The `authorize` method should be overridden in your application adapter. It should accept a single argument, the request object.'));
				},
				headersForRequest: function headersForRequest() {
						var headers = this.get('headers') || {};
						headers['Accept'] = 'application/vnd.api+json';
						return headers;
				}
		});
});