define('@busy-web/ember-date-time/--private/handlers/format', ['exports', '@busy-web/ember-date-time/--private/handlers/base', 'moment', '@busy-web/ember-date-time/--private/utils/map'], function (exports, _base, _moment, _map) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		__handler: 'formatter',

		format: _base.default.property({ defaultValue: 'L' }),
		normalizedFormat: _base.default.property(),

		setup: Ember.on('setupAttrs', function (attrs) {
			if ((0, _map.getMapAttr)(attrs, 'format.value')) {
				var localeData = _moment.default.localeData();
				var format = (0, _map.getMapAttr)(attrs, 'format.value');
				var fsp = format.split(' ');
				var nfsp = fsp.map(function (i) {
					return localeData.longDateFormat(i);
				});
				var nf = nfsp.join(' ');
				Ember.set(this, 'normalizedFormat', nf);
			}
		})
	});
});