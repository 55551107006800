define('@busy-web/data/utils/error', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var BusyError = Ember.Object.extend();

	BusyError.reopenClass({
		normalizeAdapterError: function normalizeAdapterError() {
			var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "ERROR";
			var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
			var code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
			var detail = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

			status = parseInt(status, 10);

			if (code) {
				if (!status) {
					status = 400;
				}
				code = parseInt(code, 10);
			}

			return { status: status, title: title, code: code, detail: detail };
		},
		parseAdapterErrors: function parseAdapterErrors(type, status, codes, details) {
			var _this = this;

			codes = Ember.isNone(codes) ? [] : codes;
			details = Ember.isNone(details) ? [] : details;

			var errs = [];
			if (!Ember.isEmpty(codes)) {
				codes.forEach(function (code, idx) {
					errs.push(_this.normalizeAdapterError(type, status, code, details[idx]));
				});
			} else if (!Ember.isEmpty(details)) {
				codes.forEach(function (code, idx) {
					errs.push(_this.normalizeAdapterError(type, status, code, details[idx]));
				});
			} else {
				errs.push(this.normalizeAdapterError(type, status));
			}
			return errs;
		}
	});

	exports.default = BusyError;
});