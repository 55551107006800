define('@busy-web/components/components/bc-radio-button', ['exports', '@busy-web/components/templates/components/bc-radio-button'], function (exports, _bcRadioButton) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _bcRadioButton.default,
		classNames: ['bc-radio-button', 'bc-radio-button'],

		tagName: null,
		type: 'radio',
		value: null,
		label: null,
		name: null,
		disabled: false,
		checked: false,

		change: function change() {
			this.sendAction('onSelect', this.get('value'));
		},
		click: function click(evt) {
			evt.stopPropagation();
			return true;
		}
	});
});