define('@busy-web/ember-date-time/components/private/date-time-menu', ['exports', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/utils/event', '@busy-web/ember-date-time/utils/constant', '@busy-web/ember-date-time/templates/components/private/date-time-menu'], function (exports, _time2, _event, _constant, _dateTimeMenu) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		/**
   * @private
   * @property classNames
   * @type String
   * @default combined-picker
   */
		classNames: ['busyweb', 'emberdatetime', 'p-date-time-menu'],
		layout: _dateTimeMenu.default,

		stateManager: null,

		/**
   * timestamp that is passed in when using combined-picker
   *
   * @private
   * @property timestamp
   * @type Number
   */
		timestamp: null,

		/**
   * can be passed in so a date before the minDate cannot be selected
   *
   * @private
   * @property minDate
   * @type Number
   * @optional
   */
		minDate: null,

		/**
   * can be passed in so a date after the maxDate cannot be selected
   *
   * @private
   * @property maxDate
   * @type Number
   * @optional
   */
		maxDate: null,

		/**
   * can be passed in as true or false, true sets timepicker to handle unix timestamp * 1000, false sets it to handle unix timestamp
   *
   * @private
   * @property isMilliseconds
   * @type boolean
   * @optional
   */
		isMilliseconds: null,

		/**
   * String as the current date of the timestamp
   *
   * @private
   * @property currentDate
   * @type String
   */
		currentDate: Ember.computed('timestamp', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format('MMM DD, YYYY');
		}).readOnly(),

		/**
   * String as the current time of the timestamp
   *
   * @private
   * @property currentTime
   * @type String
   */
		currentTime: Ember.computed('timestamp', function () {
			return (0, _time2.default)(Ember.get(this, 'timestamp')).format('hh:mm A');
		}).readOnly(),

		/**
   * if they cancel the change this is the timestamp the picker will revert back to
   *
   * @private
   * @property backupTimestamp
   * @type Number
   */
		backupTimestamp: null,

		/**
   * the last active section that was open in the pickers
   *
   * @private
   * @property lastActiveSection
   * @type String
   */
		lastActiveState: null,

		/**
   * sets currentTime and currentDate, sets a timestamp to now if a timestamp wasnt passed in
   * @private
   * @method init
   * @constructor
   */
		init: function init() {
			this._super.apply(this, arguments);

			this.setupTime();
		},


		setupTime: Ember.observer('stateManager.timestamp', function () {
			if (!this.get('isDestroyed')) {
				Ember.set(this, 'minDate', Ember.get(this, 'stateManager.minDate'));
				Ember.set(this, 'maxDate', Ember.get(this, 'stateManager.maxDate'));
				Ember.set(this, 'timestamp', Ember.get(this, 'stateManager.timestamp'));
				Ember.set(this, 'backupTimestamp', Ember.get(this, 'timestamp'));
			}
		}),

		/**
   * binds a click event that will call the provided
   * action anytime a click occurs not within the component
   *
   * NOTE:
   * The action name provided will get called everytime a click occurs so the
   * action should only handle on thing like closing a drop down vs toggling a dropdown.
   * this will prevent accidently opening the dialog when it is closed and a click occurs.
   *
   * @public
   * @method bindClick
   * @return {void}
   */
		bindClick: function bindClick() {
			// save this for later
			var _this = this;

			// get the components elementId
			var elementId = Ember.get(this, 'elementId');

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				// bind the click listener
				(0, _event.bind)(document, 'click', elementId, function (evt) {
					// check if the evt.target is local to this elements main parent
					var isLocal = (0, _event.isEventLocal)(evt, elementId, '.emberdatetime');

					// if the clicked element id does not match the components id and the clicked
					// elements parents dont have an id that matches then call the action provided
					//if (el.attr('id') !== elementId && el.parents(`#${elementId}`).length === 0) {
					if (!isLocal) {
						// send a call to the actionName
						evt.stopPropagation();
						evt.preventDefault();

						// call handler
						_this.unbindClick();
						_this.send('close');
						return false;
					}
				}, { capture: true, rebind: true });
			}
		},
		bindKeyup: function bindKeyup() {
			// save this for later
			var _this = this;

			// get the components elementId
			var elementId = Ember.get(this, 'elementId');

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				(0, _event.bind)(document, 'keyup', elementId, function (evt) {
					var key = evt.which;
					if (key === 27) {
						_this.closeHandler('cancel');
					} else if (key === 13) {
						_this.closeHandler('close');
					}
				}, { capture: true, rebind: true });
			}
		},


		/**
   * method to unbind a click event that may have been
   * setup by this components
   *
   * @public
   * @method unbindClick
   */
		unbindClick: function unbindClick() {
			// get the components elementId
			var elementId = Ember.get(this, 'elementId');

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				// unbind any previous click listeners
				(0, _event.unbind)(document, 'click', elementId);
			}
		},
		unbindKeyup: function unbindKeyup() {
			// get the components elementId
			var elementId = Ember.get(this, 'elementId');

			// make sure an elementId exists on the class
			// using this mixin
			if (!Ember.isEmpty(elementId)) {
				// unbind any previous click listeners
				(0, _event.unbind)(document, 'keyup', elementId);
			}
		},


		/**
   * sets up the click handler to close the dialogs if anything outside is clicked
   * @private
   * @method didInsertElement
   */
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.bindHandler();
		},


		/**
   * removes the click handler to close the dialogs if anything outside is clicked
   * @private
   * @method removeClick
   */
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			this.closeHandler();
		},


		/**
   * closes dialog if tabbed on last input
   *
   * @private
   * @method observeCloseOnTab
   */
		observeCloseOnTab: Ember.observer('stateManager.isOpen', function () {
			if (Ember.get(this, 'stateManager.isOpen')) {
				this.bindHandler();
			} else {
				this.closeHandler();
			}
		}),

		isClock: Ember.computed('stateManager.section', function () {
			var section = Ember.get(this, 'stateManager.section');
			return section === _constant.HOUR_FLAG || section === _constant.MINUTE_FLAG || section === _constant.MERIDIAN_FLAG;
		}),

		isCalendar: Ember.computed('stateManager.section', function () {
			var section = Ember.get(this, 'stateManager.section');
			return section === _constant.YEAR_FLAG || section === _constant.MONTH_FLAG || section === _constant.DAY_FLAG || section === _constant.WEEKDAY_FLAG;
		}),

		bindHandler: function bindHandler() {
			this.bindClick();
			this.bindKeyup();
		},
		closeHandler: function closeHandler(type) {
			this.unbindClick();
			this.unbindKeyup();
			if (!Ember.isEmpty(type)) {
				this.send(type);
			}
		},


		actions: {
			update: function update(focus, time, calendar) {
				this.sendAction('onUpdate', focus, time, calendar);
			},
			change: function change(focus, time, calendar) {
				this.sendAction('onChange', focus, time, calendar);
			},


			/**
      * changes dialog from clock to calendar and vice versa
      *
      * @event togglePicker
      */
			togglePicker: function togglePicker() {
				var section = _constant.HOUR_FLAG;
				if (Ember.get(this, 'isClock')) {
					section = _constant.DAY_FLAG;
				}
				this.sendAction('onUpdate', section, Ember.get(this, 'timestamp'));
			},


			/**
    * closes all dialogs
    *
    * @event togglePicker
    */
			close: function close() {
				if (!Ember.get(this, 'isDestroyed')) {
					Ember.set(this, 'backupTimestamp', Ember.get(this, 'timestamp'));
				}
				this.sendAction('onClose');
			},


			/**
      * closes all dialogs and resets the timestamp
      *
      * @event togglePicker
      */
			cancel: function cancel() {
				if (!Ember.get(this, 'isDestroyed')) {
					Ember.set(this, 'timestamp', Ember.get(this, 'backupTimestamp'));
				}
				this.sendAction('onClose');
			},
			onHeaderSelect: function onHeaderSelect(type) {
				//if (!get(this, 'isDestroyed')) {
				//	set(this, 'stateManager.section', type);
				//}
				this.sendAction('onUpdate', type, Ember.get(this, 'timestamp'));
			}
		}
	});
});