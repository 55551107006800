define('@busy-web/ember-date-time/--private/utils/map', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.createMap = createMap;
	exports.getMapAttr = getMapAttr;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function createMap() {
		return new window.Map();
	} /**
    * @module Core/Utils
    *
    */
	function getMapAttr(map, key) {
		var _key$split = key.split('.'),
		    _key$split2 = _slicedToArray(_key$split, 2),
		    k1 = _key$split2[0],
		    k2 = _key$split2[1];

		var attr = map.get(k1);
		if (k2 && attr) {
			attr = Ember.get(attr, k2);
		}
		return attr;
	}
});