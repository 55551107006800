define('@busy-web/components/components/bc-sortable-list', ['exports', '@busy-web/components/utils/sortable-object', '@busy-web/components/templates/components/bc-sortable-list'], function (exports, _sortableObject, _bcSortableList) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		layout: _bcSortableList.default,
		classNames: ['bc-sortable-list'],

		model: null,
		meta: null,
		defaultSort: false,
		childModelPath: 'children',

		__meta: null,
		__data: null,

		init: function init() {
			this._super();

			// setup meta data
			setupMeta(this);

			// setup report data
			setupReportData(this);
		},


		/**
   * Handles sorting the data according to the new SortableObject state
   *
   * @public
   * @method sort
   * @param data {ModelContainer[]} ModelContainer array to sort
   * @param sortable {SortableObject} SortableObject state to sort by
   * @return {ModelContainer[]}
   */
		sort: function sort(data, sortable) {
			if (!Ember.isNone(sortable) && sortable.get('isActive')) {
				var _sortable$getState = sortable.getState(),
				    sortBy = _sortable$getState.sortBy,
				    sortDir = _sortable$getState.sortDir;

				data = _sort(data, sortBy, sortDir);
			}
			return data;
		},


		/**
   * toggles the new sort state and resets all the other sort states
   *
   * @public
   * @method handleMetaSort
   * @param currentSortable {SortableObject} the new sort object to toggle
   * @return {void}
   */
		handleMetaSort: function handleMetaSort(currentSortable) {
			// change sort dir for meta sort item
			currentSortable.toggleState();

			// reset other sortable objects
			Ember.get(this, '__meta').forEach(function (sortable) {
				if (Ember.get(sortable, 'id') !== Ember.get(currentSortable, 'id')) {
					sortable.resetState();
				}
			});
		},


		actions: {
			sortAction: function sortAction(sortable) {
				// reset other sort states
				this.handleMetaSort(sortable);

				// save new sorted data
				Ember.set(this, '__data', this.sort(Ember.get(this, '__data').slice(0), sortable));

				// send onSort action
				this.sendAction('onSort', sortable.getState());
			},
			rowClickAction: function rowClickAction(item) {
				this.sendAction('rowAction', item);
			}
		}
	});


	function _sort(data, sortBy, sortDir) {
		(false && !(!Ember.isEmpty(data) && Array.isArray(data)) && Ember.assert('sort requires an array of objects as the first param', !Ember.isEmpty(data) && Array.isArray(data)));
		(false && !(!Ember.isEmpty(sortBy) && typeof sortBy === 'string') && Ember.assert('sort requires a sortBy string as the second param', !Ember.isEmpty(sortBy) && typeof sortBy === 'string'));
		(false && !(!Ember.isEmpty(sortDir) && (sortDir === 'asc' || sortDir === 'desc')) && Ember.assert('sort requires a sortDir string [asc, desc] as the third param', !Ember.isEmpty(sortDir) && (sortDir === 'asc' || sortDir === 'desc')));


		// sort data
		return sortData(data, sortBy, sortDir);
	}

	/**
  * normalize the input for better sorting results
  *
  * @private
  * @method normalize
  * @param value {mixed}
  * @return {mixed}
  */
	function normalize(value) {
		if (Ember.isNone(value)) {
			return '';
		}

		if (typeof value === 'string') {
			return value.trim().toLowerCase();
		} else {
			return value.toString();
		}
	}

	/**
  * sort method that will sort empty strings to the bottom
  * instead of the top of an 'asc' list
  *
  * @private
  * @method sortData
  * @param data {object[]} array of objects
  * @param prop {string} the key to the data in the object array
  * @param dir {string} sort direction `asc` or `desc`
  * @return {object[]}
  */
	function sortData(data, sortBy) {
		var dir = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'asc';
		(false && !(dir === 'asc' || dir === 'desc') && Ember.assert("dir must be either `asc` or `desc`", dir === 'asc' || dir === 'desc'));


		//const sortBy = `${prop}.value`;
		var gt = dir === 'asc' ? -1 : 1;
		var lt = dir === 'asc' ? 1 : -1;

		return data.sort(function (a, b) {
			var aVal = normalize(a.get(sortBy));
			var bVal = normalize(b.get(sortBy));

			if (Ember.isEmpty(aVal) && Ember.isEmpty(bVal)) {
				return 0;
			} else if (Ember.isEmpty(aVal)) {
				return lt;
			} else if (Ember.isEmpty(bVal)) {
				return gt;
			}

			return aVal < bVal ? gt : bVal < aVal ? lt : 0;
		});
	}

	/**
  * ModelContainer class for display multiple ModelProperty classes in
  * a row of data
  *
  * @class ModelContainer
  */
	var ModelContainer = Ember.Object.extend({
		model: null,
		modelProps: null,
		children: null,

		get: function get(key) {
			var idx = key.indexOf('.');
			var kFirst = key;
			var kRest = '';
			if (idx !== -1) {
				kFirst = key.slice(0, idx);
				kRest = key.slice(idx + 1);
			}

			var result = Ember.get(this, kFirst);
			if (Ember.isNone(result)) {
				result = Ember.get(this, 'modelProps').findBy('attrName', kFirst);
				if (Ember.isEmpty(kRest)) {
					result = Ember.get(result, 'value');
				}
			}

			if (!Ember.isEmpty(kRest)) {
				result = Ember.get(result, kRest);
			}
			return result;
		}
	});

	/**
  * ModelProperty class helper for
  * showing and sorting the property for a specific header meta
  *
  * @class ModelProperty
  */
	var ModelProperty = Ember.Object.extend({
		container: null,

		id: null,
		attrName: null,
		isImage: false,
		formatCurrency: false,
		formatTime: false,

		/**
   * @public
   * @property value
   * @type {string}
   */
		value: Ember.computed('attrName', function () {
			var attr = this.get('attrName');
			(false && !(!Ember.isEmpty(attr) && typeof attr === 'string') && Ember.assert('attrName was not found on ModelProperty class', !Ember.isEmpty(attr) && typeof attr === 'string'));

			return this.get('container.model.' + attr);
		}),

		toString: function toString() {
			return this.get('value') || '';
		}
	});

	/**
  * pares the model data and create ModelContainers with ModelProperty
  * classes
  *
  * @private
  * @method createModelContainer
  * @param target {class} calling class `this` instance
  * @param model {object[]} array of model objects
  * @param metaData {object[]} array of model meta objects
  * @return {object[]}
  */
	function createModelContainer(target, model, metaData) {
		(false && !(!Ember.isNone(model) && (typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object') && Ember.assert('model is required for createModelContainer', !Ember.isNone(model) && (typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object'));
		(false && !(!Ember.isEmpty(metaData) && Array.isArray(metaData)) && Ember.assert('metaData is required for createModelContainer', !Ember.isEmpty(metaData) && Array.isArray(metaData)));


		// create container
		var owner = Ember.getOwner(model);
		var container = ModelContainer.create(owner.ownerInjection(), { model: model });

		// create modelProps
		var modelProps = metaData.map(function (meta) {
			var id = Ember.get(meta, 'id');
			var attrName = Ember.get(meta, 'modelAttr');
			var opts = { container: container, id: id, attrName: attrName };
			if (Ember.get(meta, 'isImage')) {
				opts.isImage = true;
			}
			if (Ember.get(meta, 'formatCurrency')) {
				opts.formatCurrency = true;
			}
			if (Ember.get(meta, 'formatTime')) {
				opts.formatTime = true;
			}

			return ModelProperty.create(opts);
		});

		// set modelProps on container
		Ember.set(container, 'modelProps', modelProps);

		// create child containers
		if (!Ember.isEmpty(Ember.get(model, Ember.get(target, 'childModelPath')))) {
			Ember.set(container, 'children', Ember.get(model, Ember.get(target, 'childModelPath')).map(function (child) {
				return createModelContainer(child, metaData);
			}));
		} else {
			Ember.set(container, 'children', []);
		}

		return container;
	}

	/**
  * setup report data and handle loading the initial sort state
  *
  * this should only be called from init
  *
  * @private
  * @method setupReportData
  * @param target {class} calling class `this` instance
  * @return {void}
  */
	function setupReportData(target) {
		var meta = Ember.get(target, '__meta');
		var data = Ember.get(target, 'model').map(function (item) {
			return createModelContainer(target, item, meta);
		});

		// get sort state if it was set on init
		var sortable = Ember.get(target, '__meta').find(function (i) {
			return i.get('isActive');
		});

		// option default first property sort
		if (Ember.get(target, 'defaultSort') && Ember.isNone(sortable)) {
			sortable = Ember.get(target, '__meta.firstObject');
			sortable.toggleState();
		}

		// try calling sort then save the data
		Ember.set(target, '__data', target.sort(data, sortable));
	}

	/**
  * initial call to setup the meta data
  * for the models.
  *
  * this will look for a meta object on the target class first
  * then move on to a meta object on the model for the target class
  *
  * in the future this should try to gennerate the meta from the model itself
  * if no meta is provided.
  *
  * @private
  * @method setupMeta
  * @param target {class} calling classs `this` instance
  * @return {void}
  */
	function setupMeta(target) {
		var meta = Ember.get(target, 'meta');
		if (Ember.isNone(meta)) {
			meta = Ember.get(target, 'model.meta');
			// TODO:
			// add mode meta generator
			/*if (isNone(meta)) {
   	meta = generateMeta(target);
   }*/
		}

		(false && !(!Ember.isEmpty(meta) && Array.isArray(meta)) && Ember.assert('meta could not be found for bc-sortable-list', !Ember.isEmpty(meta) && Array.isArray(meta)));


		// create copy of meta
		meta = meta.slice(0);

		// map meta to create sortable objects
		var newMeta = meta.map(function (item) {
			return (0, _sortableObject.default)(item);
		});

		// save the new meta array
		Ember.set(target, '__meta', newMeta);
	}

	//function generateMeta(target) {
	//	let model = get(target, 'model.firstObject');
	//	let meta = [];
	//
	//	let keys = Object.keys(model);
	//	console.log('model keys', keys);
	//}
});