define('@busy-web/ember-cli-launch-darkly/services/launch-darkly', ['exports', '@busy-web/ember-cli-launch-darkly/utils/launch-darkly-sdk'], function (exports, _launchDarklySdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    _client: null,
    isInitialized: false,

    initialize: function initialize(id, user, options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this._client = (0, _launchDarklySdk.default)(id, user, options);
        _this._client.on('ready', function () {
          if (!_this.get('isDestroyed')) {
            _this.set('isInitialized', true);
            Ember.run(null, resolve, true);
          }
        });
      });
    },


    /**
     * Send a `track` event to Launch Darkly
     *
     * @public
     * @async
     * @method track
     */
    track: function track(key, data) {
      if (!this.get('isInitialized')) {
        (false && Ember.warn("<service:launch-darkly::track> was called before it was initialized"));
      }

      return this._client.track(key, data);
    },


    /**
     * Proxy the Launch Darkly client to call the allFlags() method
     *
     * @public
     * @async
     * @method allFlags
     */
    allFlags: function allFlags() {
      if (!this.get('isInitialized')) {
        (false && Ember.warn("<service:launch-darkly::allFlags> was called before it was initialized"));
      }
      return this._client.allFlags();
    },


    /**
     * Proxy the Launch Darkly client to call the variation() method
     *
     * @public
     * @async
     * @method getAllFlags
     * @param {String} key the identifying key for the feature, in the format `time.freemium`
     * @param {Boolean} defaultValue the default value to use
     */
    variation: function variation(key) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!this.get('isInitialized')) {
        (false && Ember.warn("<service:launch-darkly::variation> was called before it was initialized"));
      }
      var val = this._client.variation(key, defaultValue);
      return val;
    },


    /**
     * Proxy the Launch Darkly identify method but instead of a callback function this method
     * returns a promise when launch darkly returns
     *
     * @public
     * @method identify
     * @param user {object} user object
     * @param hash {object} hash for the user
     * @return {Promise} a promsie object for onDone
     */
    identify: function identify(user, hash) {
      var _this2 = this;

      if (!this.get('isInitialized')) {
        (false && Ember.warn("<service:launch-darkly::track> was called before it was initialized"));
      }
      return new Ember.RSVP.Promise(function (resolve) {
        _this2._client.identify(user, hash, function () {
          Ember.run(null, resolve, true);
        });
      });
    },


    /**
     * on event handler
     *
     * @public
     * @method on
     * @param name {string}
     * @param handler {function}
     */
    on: function on(name, handler) {
      this._client.on(name, function (data) {
        this.trigger(name, data);
      }, this);

      this._super(name, handler);
    },


    /**
     * off event handler
     *
     * @public
     * @method off
     * @param name {string}
     * @param handler {function}
     */
    off: function off(name, handler) {
      this._client.off(name);
      this._super(name, handler);
    }
  });
});