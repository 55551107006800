define('@busy-web/ember-date-time/utils/clock', ['exports', '@busy-web/ember-date-time/utils/clock/base', '@busy-web/ember-date-time/utils/constant', '@busy-web/ember-date-time/utils/clock/data', '@busy-web/ember-date-time/utils/clock/render', '@busy-web/ember-date-time/utils/clock/svg', '@busy-web/ember-date-time/utils/clock/string', '@busy-web/ember-date-time/utils/clock/movement', '@busy-web/ember-date-time/utils/clock/math'], function (exports, _base, _constant, _data, _render, _svg, _string, _movement, _math) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.createMetaFor = createMetaFor;
	exports.metaFor = metaFor;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var Clock = function (_dataArray) {
		_inherits(Clock, _dataArray);

		/**
   * @public
   * @constructor
   * @params component {class} instance of the component class (this) reference
   * @params key {string} the key to reference this clocks meta
   * @params start {number} the number the clock should start with 0, 1...
   * @params end {number} the number the clock should end with 12, 59...
   * @params rounder {number} a number to round the clock digits by. 0 to render all numbers. 5 would render 5, 10, 15...
   */
		function Clock(component, key) {
			var start = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
			var end = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
			var min = arguments[4];
			var max = arguments[5];
			var rounder = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 0;
			var selectRounder = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 0;

			_classCallCheck(this, Clock);

			(false && !(!Ember.isNone(component)) && Ember.assert("component is required to create a Clock meta object", !Ember.isNone(component)));

			var _createPoints = (0, _data.createPoints)(start, end, rounder, selectRounder),
			    points = _createPoints.points,
			    renderSize = _createPoints.renderSize;

			var _this = _possibleConstructorReturn(this, (Clock.__proto__ || Object.getPrototypeOf(Clock)).call(this, points));

			_this.setProps({
				component: component, key: key,
				name: (0, _string.metaName)(key),
				elname: (0, _string.elementName)(key),
				start: start, end: end, min: min, max: max,
				rounder: rounder, selectRounder: selectRounder,
				renderSize: renderSize,
				lastTime: null,
				lastGroup: null
			});
			return _this;
		}

		_createClass(Clock, [{
			key: 'renderDOM',
			value: function renderDOM(timestamp) {
				var _this2 = this;

				if (!Ember.isNone(this.container)) {
					this.__lastTime = timestamp;
					var height = this.container.height();
					var width = this.container.width();
					this.setBounds(0, 0, height, width);

					this.filterEach('isRender', function (p) {
						_this2.svg.minMaxHandler(_this2.type, p.num, _this2.__min, _this2.__max, timestamp);
					});
				}
			}
		}, {
			key: 'click',
			value: function click(cb) {
				var _this3 = this;

				this.__clickHandler = function (evt) {
					return _this3.clickHandler(evt, cb);
				};
				this.svg.snap.click(this.__clickHandler);
			}
		}, {
			key: 'unclick',
			value: function unclick() {
				this.svg.snap.unclick(this.__clickHandler);
			}
		}, {
			key: 'clickHandler',
			value: function clickHandler(evt) {
				var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

				evt = evt || window.event;
				// use the svg element as our target element
				var target = this.container.find('svg').get(0);

				// get the main clock center x and y
				var faceAttrs = (0, _data.getAttrs)(this.svg.face, ['cx', 'cy']);
				var cx = parseInt(faceAttrs.cx, 10);
				var cy = parseInt(faceAttrs.cy, 10);

				// calculate offset because any browser other than chrome
				// is not caluculating it correctly
				var rect = target.getBoundingClientRect();
				var offsetX = evt.clientX - rect.left;
				var offsetY = evt.clientY - rect.top;

				// use offsetX and offsetY to get local point to clock
				var angle = (0, _math.angleOfLine)(offsetX, offsetY, cx, cy);
				var point = this.getPointFromDegree(angle);

				// call the callback with the number selected
				cb(point.num);
			}
		}, {
			key: 'cleanup',
			value: function cleanup() {
				if (!Ember.isNone(this.container)) {
					this.svg.clean(this.__start, this.__end);
				}
			}
		}, {
			key: 'selectPlot',
			value: function selectPlot(num) {
				if (!Ember.isNone(this.container)) {
					this.svg.selectPlot(num);
				}
			}
		}, {
			key: 'unselectPlot',
			value: function unselectPlot(num) {
				if (!Ember.isNone(this.container)) {
					this.svg.unselectPlot(num);
				}
			}
		}, {
			key: 'setBounds',
			value: function setBounds(minX, minY, width, height) {
				var _this4 = this;

				// hide box until calculations have been made
				// safari fix
				this.svg.__el.style.display = "none";
				this.svg.snap.attr({ viewBox: minX + ' ' + minY + ' ' + width + ' ' + height });

				var faceCoords = (0, _math.getBoundsCenter)(width, height);

				this.svg.face.attr({ cx: faceCoords.cx, cy: faceCoords.cy, r: faceCoords.r });
				this.svg.pivot.attr({ cx: faceCoords.cx, cy: faceCoords.cy, r: faceCoords.r * 0.0283 });

				var plotR = this.type === _constant.HOUR_FLAG ? faceCoords.r * 0.14 : faceCoords.r * 0.12; // radius for the plot circles that hold the numbers
				var padding = width * 0.0306; // padding to keep plot circles in the clock face
				var armLen = faceCoords.r - plotR - padding; // length for the arms on the clock
				var pathLen = armLen - plotR * 2;

				this.filterEach('isRender', function (point) {
					var _svg$at = _this4.svg.at(point.num),
					    arm = _svg$at.arm,
					    plot = _svg$at.plot,
					    path = _svg$at.path,
					    text = _svg$at.text;

					var degree = (0, _math.getSliceDegree)(_this4.points.length, point.num);

					var armCoords = (0, _math.getLineFromDegree)(degree, armLen, faceCoords.cx, faceCoords.cy); // xy coords for the plot circle center
					arm.attr(armCoords);
					plot.attr({ cx: armCoords.x2, cy: armCoords.y2, r: plotR });

					// calculate text position if there is a text
					// at this number
					if (!Ember.isNone(text)) {
						// show box long enough to get text box size
						_this4.svg.__el.style.display = "";
						var bounds = text.node.getBBox();
						_this4.svg.__el.style.display = "none";

						var tx = armCoords.x2 - bounds.width / 2;
						var ty = armCoords.y2 + bounds.height / 3;
						var tAttr = 'translate(' + tx + ', ' + ty + ')';
						text.attr('transform', tAttr);
					}

					// calculate section position for click areas on minutes
					if (!Ember.isNone(path)) {
						var pt = (0, _math.createSVGPath)(_this4.length, degree, faceCoords.cx, faceCoords.cy, pathLen, faceCoords.r);
						// build the path string
						path.attr(pt);
					}
				});
				// show box
				this.svg.__el.style.display = "";
			}
		}, {
			key: 'handleDrag',
			value: function handleDrag(num, cb) {
				var _this5 = this;

				var faceAttrs = (0, _data.getAttrs)(this.svg.face, ['cx', 'cy']);
				var angle = (0, _math.getSliceDegree)(this.points.length, num);

				var _svg$at2 = this.svg.at(num),
				    plot = _svg$at2.plot,
				    arm = _svg$at2.arm,
				    text = _svg$at2.text;

				var plotAttrs = (0, _data.getAttrs)(plot, ['cx', 'cy']);

				if (!Ember.isNone(this.__lastGroup)) {
					this.__lastGroup.undrag();
				}

				var group = void 0;
				if (!Ember.isNone(text)) {
					group = this.svg.snap.g(arm, plot, text);
				} else {
					group = this.svg.snap.g(arm, plot);
				}

				var point = void 0;
				group.drag((0, _movement.onMove)(angle, parseFloat(faceAttrs.cx), parseFloat(faceAttrs.cy), parseFloat(plotAttrs.cx), parseFloat(plotAttrs.cy), function (ang) {
					point = _this5.getPointFromDegree(ang);
				}), (0, _movement.onMoveStart)(text, 'selected', this.svg.snap), (0, _movement.onMoveStop)(this, function (el, evt) {
					if (!point) {
						// use offsetX and offsetY to get local point to clock
						var _angle = (0, _math.angleOfLine)(evt.offsetX, evt.offsetY, faceAttrs.cx, faceAttrs.cy);
						point = _this5.getPointFromDegree(_angle);
					}
					cb(point.num);
				}));

				this.__lastGroup = group;
			}
		}, {
			key: 'getPointFromDegree',
			value: function getPointFromDegree(degree) {
				var len = this.__end;
				if (this.__start === 0) {
					len = len + 1;
				}

				// get round num for rounding by 1, 5, 10, 15, 30
				var round = this.__selectRounder || 1;

				// get slice num for clock
				var slice = (0, _math.getSliceFromDegree)(len, degree);

				// round the slice based on the selected round number
				slice = Math.round(slice / round) * round;

				// make sure max and min numbers are correct
				if (this.__end < slice) {
					slice = this.__start;
				} else if (this.__start > slice) {
					slice = this.__end;
				}

				// subtract the offset for the array index
				if (this.__start !== 0) {
					slice = slice - this.__start;
				}

				return this.objectAt(slice);
			}
		}, {
			key: 'type',
			get: function get() {
				return this.__key;
			}
		}, {
			key: 'points',
			get: function get() {
				return this.__data;
			}
		}, {
			key: 'elementName',
			get: function get() {
				return this.__elname;
			}
		}, {
			key: 'length',
			get: function get() {
				return this.__renderSize;
			}
		}, {
			key: 'container',
			get: function get() {
				if (Ember.isNone(this.__container) || !this.__container.length) {
					if (!Ember.isNone(this.__component.$()) && this.__component.$().length) {
						this.__container = this.__component.$(this.__elname);
					}
				}
				return this.__container;
			}
		}, {
			key: 'svg',
			get: function get() {
				if (!this.__snap) {
					var el = this.container.find('svg');
					this.__snap = new _svg.default(el.get(0), { rounder: this.__rounder, selectRounder: this.__selectRounder, min: this.__min, max: this.__max });
				}
				return this.__snap;
			}
		}]);

		return Clock;
	}((0, _data.default)((0, _render.default)(_base.default)));

	function createMetaFor(component, key, start, end, min, max, rounder, selectRounder) {
		var clock = new Clock(component, key, start, end, min, max, rounder, selectRounder);
		Ember.set(component, (0, _string.metaName)(key), clock);
	}

	function metaFor(component, key) {
		return Ember.get(component, (0, _string.metaName)(key));
	}
});