define('@busy-web/components/components/bc-tabs', ['exports', '@busy-web/components/templates/components/bc-tabs', '@busy-web/components/utils/parse'], function (exports, _bcTabs, _parse) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module components
  *
  */
	var TAB_CONTENT = '.--bc-tabs-content';

	function getQueryParams() {
		// get url search params
		var search = window.location.search.slice(1);

		// parse params to an object
		var params = {};
		if (!Ember.isEmpty(search)) {
			params = (0, _parse.default)(search);
		}

		// return params
		return params;
	}

	function getTabFromQueryParams() {
		var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

		// get params if none are passed in
		if (params === null) {
			params = getQueryParams();
		}

		// get tab if the tab is not an emptry string or array and
		// the tab is not null or undefined
		// otherwise return '' (empty string)
		if (!Ember.isEmpty(Ember.get(params, 'bc_tab'))) {
			return Ember.String.dasherize(Ember.get(params, 'bc_tab'));
		}
		return null;
	}

	/**
  * `Component/BCTabs`
  *
  * @class BCTabs Component
  * @extends Ember.Component
  */
	exports.default = Ember.Component.extend({
		layout: _bcTabs.default,

		classNames: ['--bc-tabs'],

		router: Ember.inject.service(),
		useRouter: true,

		/**
   * variable for tracking tabNames, is an array
   *
   * @property model
   * @type object[]
   */
		model: null,

		defaultTab: '',
		currentTab: '',
		hashName: '',

		firstRender: false,

		init: function init() {
			this._super();
			this.handleHash();
		},
		didRender: function didRender() {
			this._super();

			if (!Ember.get(this, 'firstRender')) {
				Ember.set(this, 'firstRender', true);

				var model = this.buildTabData();
				if (!Ember.isEmpty(model)) {
					if (Ember.isEmpty(Ember.get(this, 'defaultTab'))) {
						var ftab = model.filterBy('isViewable', true).get('firstObject');
						Ember.set(this, 'defaultTab', Ember.get(ftab, 'id'));
					}

					var activeTab = void 0;
					if (!Ember.isEmpty(Ember.get(this, 'hashName'))) {
						activeTab = model.findBy('id', Ember.get(this, 'hashName'));
					}

					if (Ember.isNone(activeTab) && !Ember.isEmpty(Ember.get(this, 'defaultTab'))) {
						activeTab = model.findBy('id', Ember.get(this, 'defaultTab'));
						if (Ember.isNone(activeTab)) {
							activeTab = model.filterBy('isViewable', true).get('firstObject');
							Ember.set(this, 'defaultTab', Ember.get(activeTab, 'id'));
						}
					}

					this.openTab(activeTab);
				}
			}
		},
		buildTabData: function buildTabData() {
			var _this = this;

			(false && !(this.$().length > 0) && Ember.assert('buildTabData must be called after render', this.$().length > 0));


			var model = [];
			this.$(TAB_CONTENT).children().each(function (index, el) {
				var elData = Ember.$(el).data();
				var data = Ember.Object.create({
					el: el, id: elData.id,
					active: false,
					tabName: elData.tabName,
					tabIndex: elData.tabIndex,
					isViewable: elData.isViewable,
					showBadge: elData.showBadge,
					badgeContent: elData.badgeContent,
					badgeColor: elData.badgeColor,
					showTab: function showTab() {
						Ember.set(this, 'active', true);
						elData.showTab();
					},
					hideTab: function hideTab() {
						Ember.set(this, 'active', false);
						elData.hideTab();
					},

					on: elData.on
				});

				// register for child events
				data.on('change', function () {
					_this.buildTabData();
				});

				model.push(data);
			});

			if (Ember.get(model, 'length') > 0) {
				// sort models by tabIndex
				model = model.sortBy('tabIndex');
			}

			Ember.set(this, 'model', model);
			return model;
		},
		handleHash: function handleHash() {
			var hash = window.location.hash;
			if (!Ember.isEmpty(hash) && hash.search(/^#tab-/) !== -1) {
				var name = Ember.String.dasherize(hash.replace(/^#tab-/, '').trim());
				Ember.set(this, 'hashName', name);
			} else {
				Ember.set(this, 'hashName', getTabFromQueryParams());
			}
		},
		openTab: function openTab(tab) {
			if (this.$().length > 0) {
				// hide all other tabs
				Ember.get(this, 'model').forEach(function (item) {
					return item.hideTab();
				});

				// show the new tab
				tab.showTab();
				var tabname = Ember.get(tab, 'id');
				var params = getQueryParams();
				var curTab = getTabFromQueryParams(params);

				if (Ember.isEmpty(tabname) || tabname === Ember.get(this, 'defaultTab')) {
					tabname = null;
				}

				if (curTab !== tabname) {
					Ember.set(this, 'hashName', tabname);

					if (Ember.get(this, 'useRouter') && !Ember.isNone(Ember.get(this, 'router'))) {
						Ember.set(params, 'bc_tab', tabname);
						Ember.get(this, 'router').replaceWith(Ember.get(this, 'router.currentRouteName'), { queryParams: params });
					} else {
						if (!Ember.isNone(tabname)) {
							window.history.replaceState('', document.title, window.location.pathname + '#tab-' + tabname);
						} else {
							window.history.replaceState('', document.title, window.location.pathname);
						}
					}
				}

				Ember.set(this, 'currentTab', tab);
			}
		},
		triggerTabChange: function triggerTabChange() {
			this.handleHash();
			var id = Ember.get(this, 'hashName') || Ember.get(this, 'defaultTab');
			var tab = Ember.get(this, 'model').findBy('id', id);
			if (tab && tab.id !== Ember.get(this, 'currentTab.id')) {
				this.openTab(tab);
			}
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// setup router didTransition
			var router = Ember.get(this, 'router._router');
			if (router && router.on) {
				router.on('didTransition', this, this.triggerTabChange);
			}
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			var router = Ember.get(this, 'router._router');
			if (router && router.off) {
				router.off('didTransition', this, this.triggerTabChange);
			}
		},


		actions: {
			changeTab: function changeTab(tab) {
				this.openTab(tab);
			}
		}
	});
});