define('@busy-web/ember-date-time/utils/clock/base', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var Base = function () {
		function Base() {
			_classCallCheck(this, Base);
		}

		_createClass(Base, [{
			key: 'setProps',
			value: function setProps(opts) {
				var _this = this;

				Object.keys(opts).forEach(function (key) {
					Ember.set(_this, '__' + key, Ember.get(opts, key));
				});
			}
		}]);

		return Base;
	}();

	exports.default = Base;
});