define('ember-google-maps/components/g-map/canvas', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ember-google-map'],
    attributeBindings: ['style'],

    _isCustomCanvas: true,

    init: function init() {
      this._super.apply(this, arguments);
      this._internalAPI._registerCanvas(this, this._isCustomCanvas);
    }
  });
});