define('@busy-web/components/helpers/throw-alert', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.throwAlert = throwAlert;
	/**
  * @module Helpers
  *
  */
	function throwAlert(params /*, hash*/) {
		return function (model) {
			var str = '';
			for (var i in params) {
				if (/%/.test(params[i])) {
					var val = Ember.get(model, params[i].replace(/%/, ''));
					if (val) {
						str += ' ' + val;
					} else {
						str += ' ' + params[i];
					}
				} else {
					str += ' ' + params[i];
				}
			}
			window.alert(str);
		};
	}

	exports.default = Ember.Helper.helper(throwAlert);
});