define('@busy-web/ember-date-time/helpers/busy-loc', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		/**
   * Helpers compute override mehtod and main entry
   * mehtod of the template helper
   *
   * @public
   * @method compute
   * @param params {array} mixed values passed from the template
   * @return {string} the localized string
   */
		compute: function compute(params) {
			var string = params[0];
			var args = params.slice(1, params.length);

			return Ember.String.loc(string, args);
		}
	});
});