define('@busy-web/ember-date-time/components/ember-date-time-picker', ['exports', '@busy-web/ember-date-time/mixins/key-events', '@busy-web/ember-date-time/utils/state', '@busy-web/ember-date-time/utils/time', '@busy-web/ember-date-time/utils/format', '@busy-web/ember-date-time/templates/components/ember-date-time-picker', '@busy-web/ember-date-time/utils/constant'], function (exports, _keyEvents, _state2, _time2, _format, _emberDateTimePicker, _constant) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_keyEvents.default, {
		/**
   * @private
   * @property classNames
   * @type String
   * @default busy-web-date-time-picker
   */
		classNames: ['busyweb', 'emberdatetime', 'c-date-time-picker'],
		layout: _emberDateTimePicker.default,

		/**
   * timestamp that is passed in as a milliseconds timestamp
   *
   * @private
   * @property timestamp
   * @type Number
   */
		timestamp: null,

		/**
   * timestamp that is passed in as a seconds timestamp
   *
   * @public
   * @property unix
   * @type number
   */
		unix: null,

		/**
   * can be passed in so a date after the maxDate cannot be selected
   *
   * @private
   * @property maxDate
   * @type Number
   * @optional
   */
		maxDate: null,

		/**
   * can be passed in so a date before the minDate cannot be selected
   *
   * @private
   * @property minDate
   * @type Number
   * @optional
   */
		minDate: null,

		/**
   * set to true if the values passed in should not be converted to local time
   *
   * @public
   * @property utc
   * @type boolean
   */
		utc: false,

		format: 'MM/DD/YYYY hh:mm A', //'L LT',

		/**
   * value thats used to only allow one action to be sent each keyup/heydown for calendar
   *
   * @private
   * @property keyHasGoneUp
   * @type Boolean
   */
		keyHasGoneUp: true,

		/**
   * The stateManager object used by busy-web-date-time-picker
   *
   * @private
   * @property stateManager
   * @type object
   */
		stateManager: null,

		/**
   * Round the minutes by this amount of minutes.
   * Must be one of the following 1, 5, 10, 15, 30
   *
   * @property round
   * @type {number}
   */
		round: 1,

		allowTab: true,
		hideTime: false,
		hideDate: false,
		lockOpen: false,

		__timestamp: null,
		__calendar: null,
		__min: null,
		__max: null,
		__defaultFocus: 0,

		/**
   * checks if timestamp is valid calls updateInputValues
   *
   * @private
   * @method initialize
   * @constructor
   */
		init: function init() {
			this._super.apply(this, arguments);
			this.initialize.apply(this, arguments);
		},
		initialize: function initialize() {
			/**
    * TODO:
    * replace stateManager with more advanced state manager
    * capable of passing events around to all listeners
    *
    *
   const __state = stateManager();
   __state.setup({
   	timestamp: get(this, 'timestamp'),
   	unix: get(this, 'unix'),
   	minDate: get(this, 'minDate'),
   	maxDate: get(this, 'maxDate'),
   	utc: get(this, 'utc'),
   	format: get(this, 'format'),
   	round: get(this, 'round'),
   	roundSelect: get(this, 'roundSelect')
   });
   		set(this, '__state', __state);
   __state.update({ active: HOUR_FLAG });
   */

			// get locale converted format str
			var format = Ember.get(this, 'format');
			format = (0, _format.longFormatDate)(format);
			setPrivate(this, 'format', format);

			// set timestamp and min max dates
			this.setupTime();

			// set initial focus state
			var index = findSectionIndex(this, _constant.HOUR_FLAG);
			Ember.set(this, '__defaultFocus', index);

			this.setActiveState();
			this.setupPicker();
			this.setState();
		},
		setupTime: function setupTime() {
			var timestamp = Ember.get(this, 'timestamp');
			var unix = Ember.get(this, 'unix');
			var minDate = Ember.get(this, 'minDate');
			var maxDate = Ember.get(this, 'maxDate');

			if (timestamp !== Ember.get(this, '__lastTimestamp') || unix !== Ember.get(this, '__lastUnix') || minDate !== Ember.get(this, '__lastMinDate') || maxDate !== Ember.get(this, '__lastMaxDate')) {
				Ember.set(this, '__lastTimestamp', timestamp);
				Ember.set(this, '__lastUnix', unix);
				Ember.set(this, '__lastMinDate', minDate);
				Ember.set(this, '__lastMaxDate', maxDate);

				var time = void 0,
				    min = void 0,
				    max = void 0;
				if (!Ember.isNone(unix)) {
					// assume all dates are unix and convert them to milliseconds
					time = _time2.default.unix(unix).timestamp();
					if (!Ember.isNone(minDate)) {
						min = _time2.default.unix(minDate).timestamp();
					}
					if (!Ember.isNone(maxDate)) {
						max = _time2.default.unix(maxDate).timestamp();
					}

					if (Ember.get(this, 'utc')) {
						time = _time2.default.utcToLocal(time).timestamp();
						if (!Ember.isNone(minDate)) {
							min = _time2.default.utcToLocal(min).timestamp();
						}
						if (!Ember.isNone(maxDate)) {
							max = _time2.default.utcToLocal(max).timestamp();
						}
					}
				} else if (!Ember.isNone(timestamp)) {
					if (Ember.get(this, 'utc')) {
						time = _time2.default.utcToLocal(timestamp).timestamp();
						if (!Ember.isNone(minDate)) {
							min = _time2.default.utcToLocal(minDate).timestamp();
						}
						if (!Ember.isNone(maxDate)) {
							max = _time2.default.utcToLocal(maxDate).timestamp();
						}
					}
				}

				setPrivate(this, 'timestamp', time);
				setPrivate(this, 'calendar', time);
				setPrivate(this, 'min', min);
				setPrivate(this, 'max', max);
			}
		},


		changeAttrs: Ember.observer('timestamp', 'unix', 'minDate', 'maxDate', function () {
			this.setupTime();
			this.setState();
		}),

		setState: function setState() {
			var timestamp = getPrivate(this, 'timestamp');
			var calendarDate = getPrivate(this, 'calendar');
			var minDate = getPrivate(this, 'min');
			var maxDate = getPrivate(this, 'max');
			var format = getPrivate(this, 'format');
			var selectRound = parseInt(Ember.getWithDefault(this, 'round', 1), 10);
			timestamp = _time2.default.round(timestamp, selectRound);

			if (Ember.isNone(Ember.get(this, 'stateManager'))) {
				Ember.set(this, 'stateManager', (0, _state2.default)({ timestamp: timestamp, calendarDate: calendarDate, minDate: minDate, maxDate: maxDate, format: format, selectRound: selectRound }));
			} else {
				Ember.set(this, 'stateManager.timestamp', timestamp);
				Ember.set(this, 'stateManager.calendarDate', calendarDate);
				Ember.set(this, 'stateManager.minDate', minDate);
				Ember.set(this, 'stateManager.maxDate', maxDate);
				Ember.set(this, 'stateManager.format', format);
				Ember.set(this, 'stateManager.selectRound', selectRound);
			}
		},


		setupPicker: Ember.observer('hideTime', 'hideDate', function () {
			var showDate = this.get('hideTime') || !this.get('hideDate');
			var showTime = this.get('hideDate') || !this.get('hideTime');
			var section = _constant.DAY_FLAG;
			if (!showDate) {
				section = _constant.HOUR_FLAG;
			}

			this.setActiveState({ section: section, showDate: showDate, showTime: showTime });
		}),

		setActiveState: function setActiveState() {
			var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			if (Ember.isNone(Ember.get(this, 'stateManager'))) {
				this.setState();
			}

			if (!Ember.isEmpty(options.section)) {
				if (Ember.get(this, 'stateManager.section') !== options.section) {
					this.set('stateManager.section', options.section);
				}
				this.focusState(options.section);
			} else {
				if (this.get('hideTime') && !this.get('hideDate') && this.get('lockOpen')) {
					this.set('stateManager.section', _constant.DAY_FLAG);
					this.focusState(_constant.DAY_FLAG);
				} else if (!this.get('hideTime') && this.get('lockOpen')) {
					this.set('stateManager.section', _constant.HOUR_FLAG);
					this.focusState(_constant.HOUR_FLAG);
				}
			}

			if (!Ember.isNone(options.isOpen)) {
				if (!this.get('lockOpen')) {
					this.set('stateManager.isOpen', options.isOpen);
				}
			}

			if (!Ember.isNone(options.isTop)) {
				this.set('stateManager.isTop', options.isTop);
			}

			if (!Ember.isNone(options.showDate)) {
				this.set('stateManager.showDate', options.showDate);
			} else if (this.get('hideTime') && !this.get('hideDate') && this.get('lockOpen')) {
				this.set('stateManager.showDate', true);
			}

			if (!Ember.isNone(options.showTime)) {
				this.set('stateManager.showTime', options.showTime);
			} else if (!this.get('hideTime') && this.get('lockOpen')) {
				this.set('stateManager.showTime', true);
			}

			this.set('stateChangeInProgress', false);
		},
		focusState: function focusState(section) {
			var el = this.$('input');
			if (el && el.length) {
				var index = void 0;
				if (!Ember.isEmpty(section)) {
					index = findSectionIndex(this, section);
				} else {
					index = findSectionIndex(this, _constant.HOUR_FLAG);
				}

				if (el.data('selection') !== index) {
					el.data('selection', index);
					el.data('forceSelection', true);
				}

				el.focus();
			}
		},
		shouldPickerOpenTop: function shouldPickerOpenTop() {
			var documentHeight = Ember.$(document).height();
			var dialogHeight = this.$().find('.dialog-container').height() + 50;
			var elementHeight = this.$().height();
			var distanceTop = this.$().offset().top;
			var distanceBottom = documentHeight - (distanceTop + elementHeight);

			return distanceTop > distanceBottom && distanceBottom < dialogHeight;
		},


		/**
   * triggeres a date change event to send off
   * to listeners of `onChange`
   *
   * @public
   * @method triggerDateChange
   */
		triggerDateChange: function triggerDateChange() {
			var time = getPrivate(this, 'timestamp');
			if (Ember.get(this, 'utc')) {
				time = _time2.default.utcFromLocal(time).timestamp();
			}

			var timestamp = void 0;
			if (!Ember.isNone(Ember.get(this, 'timestamp'))) {
				timestamp = time;
				Ember.set(this, '__lastTimestamp', timestamp);
				Ember.set(this, 'timestamp', timestamp);
			}

			var unix = void 0;
			if (!Ember.isNone(Ember.get(this, 'unix'))) {
				unix = (0, _time2.default)(time).unix();
				Ember.set(this, '__lastUnix', unix);
				Ember.set(this, 'unix', unix);
			}

			this.setState();
			this.sendAction('onChange', { timestamp: timestamp, unix: unix });
		},
		updateTime: function updateTime(type, time, calendar) {
			time = _time2.default.round(time, Ember.get(this, 'stateManager.selectRound'));
			calendar = _time2.default.round(calendar, Ember.get(this, 'stateManager.selectRound'));

			if (type === _constant.MONTH_FLAG) {
				if (!Ember.isNone(calendar)) {
					setPrivate(this, 'calendar', calendar);
					this.setState();
				}
			} else {
				setPrivate(this, 'timestamp', time);
				setPrivate(this, 'calendar', time);
				this.triggerDateChange();
			}
		},


		actions: {
			dateChange: function dateChange(time) {
				this.updateTime(_constant.DAY_FLAG, time);
			},
			applyChange: function applyChange(evt, time) {
				this.updateTime(_constant.DAY_FLAG, time);
				this.send('closeAction');
			},
			update: function update(section, time, calendar) {
				if (!this.get('stateChangeInProgress')) {
					this.set('stateChangeInProgress', true);
					this.updateTime(section, time, calendar);
					this.setActiveState({ section: section });
					this.focusState(section);
				}
			},
			stateChange: function stateChange(section) {
				if (!this.get('stateChangeInProgress')) {
					this.set('stateChangeInProgress', true);
					if (section === 'm-' + _constant.HOUR_FLAG) {
						section = _constant.HOUR_FLAG;
					}
					var isOpen = true;
					var isTop = this.shouldPickerOpenTop();
					this.setActiveState({ section: section, isOpen: isOpen, isTop: isTop });
				}
			},
			closeAction: function closeAction() {
				if (!this.get('lockOpen')) {
					this.setActiveState({ section: '', isOpen: false, isTop: false });
				}
			},
			focusAction: function focusAction(evt, section) {
				if (!this.get('stateChangeInProgress')) {
					this.set('stateChangeInProgress', true);
					if (section === 'm-' + _constant.HOUR_FLAG) {
						section = _constant.HOUR_FLAG;
					}
					var isOpen = true;
					var isTop = this.shouldPickerOpenTop();
					this.setActiveState({ section: section, isOpen: isOpen, isTop: isTop });
				}
			},
			tabAction: function tabAction(evt, handler) {
				this.sendAction('onTabKey', evt);
				if (evt.isDefaultPrevented() || evt.isPropagationStopped()) {
					return handler.preventDefault();
				} else {
					this.send('closeAction');
					return true;
				}
			}
		}
	});


	function getPrivate(target, name) {
		return Ember.get(target, '__' + name);
	}

	function setPrivate(target, name, value) {
		Ember.set(target, '__' + name, value);
	}

	function findSectionIndex(target, type) {
		var format = getPrivate(target, 'format');
		//et value = _time(getPrivate(target, 'timestamp')).format(format);
		//let f = splitFormat(format);
		//let v = splitFormat(value);

		var exp = void 0;
		if (type === _constant.HOUR_FLAG) {
			exp = _time2.default.typeExp('m-' + type);
			if (!exp.test(format)) {
				exp = _time2.default.typeExp(type);
			}
		} else {
			exp = _time2.default.typeExp(type);
		}

		var idx = format.search(exp);
		if (idx < 0) {
			idx = 0;
		}
		return idx;
	}
});