define('@busy-web/ember-date-time/utils/clock/math', ['exports', 'snapsvg'], function (exports, _snapsvg) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.getBoundsCenter = getBoundsCenter;
	exports.getSliceDegree = getSliceDegree;
	exports.getSliceFromDegree = getSliceFromDegree;
	exports.getLineFromDegree = getLineFromDegree;
	exports.angleOfLine = angleOfLine;
	exports.getDirection = getDirection;
	exports.createSVGPath = createSVGPath;
	function getBoundsCenter(width, height) {
		var cx = width / 2;
		var cy = height / 2;
		var r = height < width ? cx : cy;
		return { cx: cx, cy: cy, r: r };
	}

	/**
 	* Returns the degree of a slice in the circle for a va
 	* total number passed in.
 	*
 	* @public
 	* @method getSliceDegree
 	* @param totalSlices {number} the total number of slices this circle is broken into
 	* @param slice{number} the slice number to get the degrees of
 	* @return {number}
 	*/
	/**
  * @module Utils/Clock
  *
  */
	function getSliceDegree(totalSlices, slice) {
		return slice * (360 / totalSlices) % 360;
	}

	/**
 	* Returns the degree of a specified value as it pertains to the
 	* total number passed in.
 	*
 	* @public
 	* @method getValueFromDegree
 	* @param totalSlices {number} the total number of slices this circle is broken into
 	* @param degree {number} the degree to get the number for
 	* @return {number}
 	*/
	function getSliceFromDegree(totalSlices, degree) {
		// find the slice number
		return degree * totalSlices / 360;
	}

	function getLineFromDegree(degree, length, x1, y1) {
		// getSliceDegree will calculate all angles according to the positive x axis
		// so rotate all degrees by 270 to get the proper alignment of time per degree on the clock
		degree = (degree + 270) % 360;

		// convert degrees to radians
		var rads = _snapsvg.Snap.rad(degree);

		// calculate x and y
		var x2 = x1 + length * Math.cos(rads);
		var y2 = y1 + length * Math.sin(rads);

		return { x1: x1, y1: y1, x2: x2, y2: y2 };
	}

	/**
  * calculates the angle of a line
  *
  * @method angleOfLine
  * @param x1 {number}
  * @param y1 {number}
  * @param x2 {number}
  * @param y2 {number}
  * @return {number} angle in degrees
  */
	function angleOfLine(x1, y1, x2, y2) {
		var dy = -(y1 - y2); // invert dy for proper x-axis
		var dx = x1 - x2;

		// returns the angle of a line off the x-axis
		var angle = Math.atan2(dy, dx);
		angle *= 180 / Math.PI;

		// invert the angle to make work on the y-axis
		angle = -angle;

		// convert to 360 degree angles
		if (angle < 0) {
			angle += 360;
		}

		// rotate the angle back up to the y-axis 0 coordinate
		angle = (angle + 90) % 360;
		return angle;
	}

	/**
  * returns the direction of the angle ????
  * was called `calculateDirection`
  *
  * @method getDirection
  * @param startAngle {number}
  * @param endAngle {number}
  * @return {number} angle
  */
	function getDirection(startAngle, endAngle) {
		var angle = endAngle - startAngle;
		if (angle > 180) {
			angle -= 360;
		}
		return angle;
	}

	function createSVGPath(totalSlices, degree, x, y, start, end) {
		var sliceRange = 360 / totalSlices / 2;
		var ld = (degree - sliceRange + 360) % 360; // get the angle for the left bounds
		var rd = (degree + sliceRange) % 360; // get the angle for the right bounds

		// get the bottom left and right points
		var bl = getLineFromDegree(ld, start, x, y); // bottom left
		var br = getLineFromDegree(rd, start, x, y); // bottom right

		// get the top left and right points
		var tl = getLineFromDegree(ld, end, x, y); // top left
		var tr = getLineFromDegree(rd, end, x, y); // top right

		// get the point to curve the top bar to.
		var tc = getLineFromDegree(degree, end, x, y); // top center

		var M = 'M' + tl.x2 + ' ' + tl.y2;
		var Q = 'Q ' + tc.x2 + ' ' + tc.y2 + ' ' + tr.x2 + ' ' + tr.y2;
		var L = 'L ' + br.x2 + ' ' + br.y2 + ' ' + bl.x2 + ' ' + bl.y2 + ' Z';

		var d = M + ' ' + Q + ' ' + L;
		return { d: d };
	}
});