define('@busy-web/ember-date-time/utils/clock/string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.metaName = metaName;
  exports.elementName = elementName;
  exports.numberToString = numberToString;
  exports.stringToNumber = stringToNumber;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
   * generates a meta data reference name
   *
   * @private
   * @method metaName
   * @params key {string} a key to generate the reference name with
   * @return {string}
   */
  /**
   * @module Utils/Clock
   *
   */
  function metaName(key) {
    (false && !(typeof key === 'string' && key.trim().length) && Ember.assert('metaName takes a string as an argument, you passed ' + (typeof key === 'undefined' ? 'undefined' : _typeof(key)) + ' ' + key, typeof key === 'string' && key.trim().length));


    return '__' + Ember.String.underscore(key) + '__meta';
  }

  /**
   * generates a css class reference key to the element in the DOM
   *
   * @private
   * @method elementName
   * @params key {string} a key to generate the reference name with
   * @return {string}
   */
  function elementName(key) {
    (false && !(typeof key === 'string' && key.trim().length) && Ember.assert('elementName takes a string as an argument, you passed ' + (typeof key === 'undefined' ? 'undefined' : _typeof(key)) + ' ' + key, typeof key === 'string' && key.trim().length));


    return '.--svg-clock__' + Ember.String.dasherize(key);
  }

  /**
   * generates a number with a 0 for padding when the number is less than 10
   *
   * @private
   * @method formatNumber
   * @params num {number} the number to format
   * @return {string}
   */
  function numberToString(num) {
    (false && !(typeof num === 'number') && Ember.assert('numberToString takes a number as an argument, you passed ' + (typeof num === 'undefined' ? 'undefined' : _typeof(num)) + ' ' + num, typeof num === 'number'));


    return num < 10 ? '0' + num : '' + num;
  }

  /**
  	* Converts a string to an integer value
  	*
  	* @public
  	* @method stringToInteger
  	* @param value {string}
  	* @return {number}
  	*/
  function stringToNumber(str) {
    (false && !(typeof str === 'string') && Ember.assert('stringToNumber takes a string as an argument, you passed ' + (typeof str === 'undefined' ? 'undefined' : _typeof(str)) + ' ' + str, typeof str === 'string'));


    str = str.replace(/\D/g, '');
    return parseInt(str, 10);
  }
});