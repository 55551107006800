define('ember-google-maps/mixins/register-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * @property events
     * @type {Object}
     * @public
     */

    /**
     * The target DOM node or Google Maps object to which to attach event
     * listeners.
     *
     * @property eventTarget
     * @type {HTMLNode|MVCObject}
     * @private
     */
    _eventTarget: Ember.computed.reads('mapComponent'),

    /**
     * Filter the array of passed attributes for attributes that begin with `on`.
     *
     * @property _eventAttrs
     * @private
     * @return {Array} An array of extracted event names.
     */
    _eventAttrs: Ember.computed('attrs', function () {
      var _this = this;

      var attrNames = Object.keys(this.attrs);
      return attrNames.filter(function (attr) {
        return _this._filterEventsByName(attr);
      });
    }),

    /**
     * A combination of events passed via the `events` hash and extracted from the
     * component's attributes. Events registered via an attribute take precedence.
     *
     * @property _events
     * @private
     * @return {Object}
     */
    _events: Ember.computed('events', '_eventAttrs', function () {
      var events = Ember.get(this, 'events');
      var extractedEvents = Ember.getProperties(this, Ember.get(this, '_eventAttrs'));

      return Ember.assign({}, events, extractedEvents);
    }),

    /**
     * Return true if the passed attribute matches the syntax for an event, i.e.
     * begins with `on` and is not explicitly ignored in `_ignoredAttrs`.
     *
     * @method _filterEventsByName
     * @param {String} attr
     * @private
     * @return {Boolean}
     */
    _filterEventsByName: function _filterEventsByName(attr) {
      return attr.slice(0, 2) === 'on' && Ember.get(this, '_ignoredAttrs').indexOf(attr) === -1;
    },
    willDestroyElement: function willDestroyElement() {
      var eventTarget = Ember.get(this, '_eventTarget');

      if (eventTarget && typeof google !== 'undefined') {
        google.maps.event.clearInstanceListeners(eventTarget);
      }

      this._super.apply(this, arguments);
    },


    /**
     * Register an event listener on the eventTarget for each event provided.
     *
     * @method registerEvents
     * @private
     * @return
     */
    registerEvents: function registerEvents() {
      var _this2 = this;

      var events = Ember.get(this, '_events');

      Object.keys(events).forEach(function (eventName) {
        var action = events[eventName];
        var eventTarget = Ember.get(_this2, '_eventTarget');
        eventName = Ember.String.decamelize(eventName).slice(3);

        google.maps.event.addDomListener(eventTarget, eventName, function (googleEvent) {
          var event = window.event;
          var params = {
            event: event,
            googleEvent: googleEvent,
            eventName: eventName,
            target: eventTarget,
            publicAPI: _this2.publicAPI,
            map: Ember.get(_this2, 'map')
          };

          action(params);
        });
      });
    }
  });
});