define('@busy-web/components/helpers/get-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getValue = getValue;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
   * `BusyComponent\Helper\GetValue`
   *
   */
  /**
   * @module Helpers
   *
   */
  function getValue(params /*, hash*/) {
    var model = params[0];
    var key = params[1];

    (false && !(!Ember.isNone(model) && (typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object') && Ember.assert("You must provide a model of type object for the first param in get-val", !Ember.isNone(model) && (typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object'));
    (false && !(!Ember.isNone(key) && typeof key === 'string') && Ember.assert("You must provide a key of type String for the second param in get-val", !Ember.isNone(key) && typeof key === 'string'));


    return Ember.get(model, key);
  }

  exports.default = Ember.Helper.helper(getValue);
});