define('@busy-web/ember-date-time/utils/constant', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var YEAR_FLAG = 'years';
  var MONTH_FLAG = 'months';
  var DAY_FLAG = 'days';

  var WEEKDAY_FLAG = 'weekdays';
  var HOUR_FLAG = 'hours';
  var MINUTE_FLAG = 'minutes';
  var SECONDS_FLAG = 'seconds';
  var MERIDIAN_FLAG = 'meridian';

  exports.YEAR_FLAG = YEAR_FLAG;
  exports.MONTH_FLAG = MONTH_FLAG;
  exports.WEEKDAY_FLAG = WEEKDAY_FLAG;
  exports.DAY_FLAG = DAY_FLAG;
  exports.HOUR_FLAG = HOUR_FLAG;
  exports.MINUTE_FLAG = MINUTE_FLAG;
  exports.SECONDS_FLAG = SECONDS_FLAG;
  exports.MERIDIAN_FLAG = MERIDIAN_FLAG;
});