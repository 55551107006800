define('@busy-web/ember-date-time/--private/state', ['exports', '@busy-web/ember-date-time/--private/manager'], function (exports, _manager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = stateManager;
  function stateManager(opts) {
    return _manager.default.create(opts);
  } /**
     * @module Core
     *
     */
});