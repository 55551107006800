define('@busy-web/components/utils/event', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bind = bind;
  exports.unbind = unbind;


  /**
   * List of all current event names and there listener
   *
   * @private
   * @property __eventListeners
   * @type {Map}
   */
  var __eventListeners = window.__busywebEventListeners; /**
                                                          * @module Utils
                                                          *
                                                          */

  if (Ember.isNone(__eventListeners)) {
    __eventListeners = new window.Map();
    window.__busywebEventListeners = __eventListeners;
  }

  /**
   * generates the event name from the event type and namespace
   *
   * @private
   * @method eventName
   * @param type {string} case-sensitive string for the event type
   * @param namespace {string} case-sensitive string to distinguish this event from other events
   * @return {string} event name
   */
  function eventName(type, namespace) {
    (false && !(!Ember.isEmpty(type) && typeof type === 'string') && Ember.assert('type {string} is a required param to bind', !Ember.isEmpty(type) && typeof type === 'string'));
    (false && !(!Ember.isEmpty(namespace) && typeof namespace === 'string') && Ember.assert('namespace {string} is a required param to bind', !Ember.isEmpty(namespace) && typeof namespace === 'string'));


    return type + '.' + namespace;
  }

  /**
   * gets a listener from the __eventListeners list
   *
   * @private
   * @method getListener
   * @param type {string} case-sensitive string for the event type
   * @param namespace {string} case-sensitive string to distinguish this event from other events
   * @return {function} the event listener
   */
  function getListener(type, namespace) {
    return __eventListeners.get(eventName(type, namespace));
  }

  /**
   * adds a listener to the __eventListeners list
   *
   * @private
   * @method setListener
   * @param type {string} case-sensitive string for the event type
   * @param namespace {string} case-sensitive string to distinguish this event from other events
   * @param listener {function} the callback method for events
   * @return {void}
   */
  function setListener(type, namespace, listener) {
    var capture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var passive = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    (false && !(!Ember.isEmpty(listener) && typeof listener === 'function') && Ember.assert('listener {function} is a required param to bind', !Ember.isEmpty(listener) && typeof listener === 'function'));
    (false && !(typeof capture === 'boolean') && Ember.assert('capture {boolean} must be a boolean value true | false', typeof capture === 'boolean'));
    (false && !(typeof passive === 'boolean') && Ember.assert('passive {boolean} must be a boolean value true | false', typeof passive === 'boolean'));


    __eventListeners.set(eventName(type, namespace), { listener: listener, capture: capture, passive: passive });
  }

  /**
   * removes a listener from the __eventListeners list
   *
   * @private
   * @method removeListener
   * @param type {string} case-sensitive string for the event type
   * @param namespace {string} case-sensitive string to distinguish this event from other events
   * @return {void}
   */
  function removeListener(type, namespace) {
    __eventListeners.delete(eventName(type, namespace));
  }

  function hasListener(type, namespace) {
    return __eventListeners.has(eventName(type, namespace));
  }

  /**
   * Bind an event listener to the target element
   */
  function bind(target, type, namespace, listener, _ref) {
    var _ref$capture = _ref.capture,
        capture = _ref$capture === undefined ? false : _ref$capture,
        _ref$rebind = _ref.rebind,
        rebind = _ref$rebind === undefined ? false : _ref$rebind,
        _ref$passive = _ref.passive,
        passive = _ref$passive === undefined ? false : _ref$passive,
        _ref$once = _ref.once,
        once = _ref$once === undefined ? false : _ref$once;
    (false && !(!Ember.isNone(target) && target.addEventListener !== undefined) && Ember.assert('target {DOM Element} is a required param to bind', !Ember.isNone(target) && target.addEventListener !== undefined));
    (false && !(typeof capture === 'boolean') && Ember.assert('capture {boolean} must be a boolean value true | false', typeof capture === 'boolean'));
    (false && !(typeof rebind === 'boolean') && Ember.assert('rebind {boolean} must be a boolean value true | false', typeof rebind === 'boolean'));
    (false && !(typeof passive === 'boolean') && Ember.assert('passive {boolean} must be a boolean value true | false', typeof passive === 'boolean'));
    (false && !(typeof once === 'boolean') && Ember.assert('once {boolean} must be a boolean value true | false', typeof once === 'boolean'));
    (false && !(rebind === true) && Ember.assert("An event already exists for this event. You must unbind it first, or pass rebind true to the bind method to auto rebind the event", rebind === true));


    if (hasListener(type, namespace)) {
      // unbind the event and remove the local listener so it can be bound again.
      unbind(target, type, namespace);
    }

    // save the event listener method for ubinding later
    setListener(type, namespace, listener, capture, passive);

    // add the target event listener
    target.addEventListener(type, listener, { capture: capture, once: once, passive: passive });
  }

  function unbind(target, type, namespace) {
    (false && !(!Ember.isNone(target) && target.removeEventListener !== undefined) && Ember.assert('target {DOM Element} is a required param to bind', !Ember.isNone(target) && target.removeEventListener !== undefined));


    // remove event if the event has been added
    if (hasListener(type, namespace)) {
      // get event listener for this namespace
      var eventData = getListener(type, namespace);

      // remove event listener for local namespace
      removeListener(type, namespace);

      // remove targets event listener
      target.removeEventListener(type, eventData.listener, { capture: eventData.capture, passive: eventData.passive });
    }
  }
});